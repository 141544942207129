var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var V$=function(a){return new $CLJS.le(null,function(){return $CLJS.ce(a.o?a.o():a.call(null),V$(a))},null,null)},W$=function(a,b){return new $CLJS.le(null,function(){var c=$CLJS.z(b);if(c){var d=$CLJS.B(c),e=a.h?a.h(d):a.call(null,d),f=$CLJS.ce(d,$CLJS.Mv(function(k){return $CLJS.F.g(e,a.h?a.h(k):a.call(null,k))},$CLJS.C(c)));return $CLJS.ce(f,W$(a,new $CLJS.le(null,function(){return $CLJS.Te($CLJS.E(f),c)},null,null)))}return null},null,null)},kta=function(a,b){switch(a){case "minutes":return $CLJS.vX("Minute",
"Minutes",b);case "hours":return $CLJS.vX("Hour","Hours",b);case "days":return $CLJS.vX("Day","Days",b);case "weeks":return $CLJS.vX("Week","Weeks",b);case "months":return $CLJS.vX("Month","Months",b);case "quarters":return $CLJS.vX("Quarter","Quarters",b);case "years":return $CLJS.vX("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},lta=function(a,b,c){b=parseInt(b);c=kta(c,b);a=new $CLJS.Q(null,2,5,$CLJS.R,[a,$CLJS.F.g(b,1)],null);if($CLJS.F.g(new $CLJS.Q(null,
2,5,$CLJS.R,["past",!0],null),a))return $CLJS.uG("Previous {0}",$CLJS.G([c]));if($CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,["past",!1],null),a))return $CLJS.uG("Previous {0} {1}",$CLJS.G([b,c]));if($CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,["next",!0],null),a))return $CLJS.uG("Next {0}",$CLJS.G([c]));if($CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,["next",!1],null),a))return $CLJS.uG("Next {0} {1}",$CLJS.G([b,c]));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));},nta=function(a){return $CLJS.TC(a,
mta,function(b){return["\\",$CLJS.p.h(b)].join("")})},X$=function(a,b,c,d,e){this.tag=a;this.source=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264},pta=function(a){var b=$CLJS.av(a,ota),c=$CLJS.Ze.g($CLJS.B,$CLJS.LY(Y$,a)),d=function(){var e=$CLJS.E(b),f=$CLJS.E(c);return e>f?e:f}();a=$CLJS.Ye.g(b,$CLJS.Se(d-$CLJS.E(b),V$($CLJS.Le(""))));d=$CLJS.Ye.g(c,$CLJS.Se(d-$CLJS.E(c),V$($CLJS.Le(""))));a=$CLJS.We.g(a,d);return $CLJS.Ze.g(function(e){var f=$CLJS.fh(Y$,e);return $CLJS.n(f)?($CLJS.H(f,
0,null),f=$CLJS.H(f,1,null),new X$(f,e,null,null,null)):e},a)},qta=function(a){return $CLJS.$e(function(b){return"string"===typeof $CLJS.B(b)?new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.tX(b)],null):b},$CLJS.G([W$(function(b){return"string"===typeof b||$CLJS.Sa($CLJS.vj.h(b))},a)]))},rta=function(a,b,c,d){return $CLJS.Ze.g(function(e){if(e instanceof X$){var f=$CLJS.S.j;var k=$CLJS.zj.h(e),l=$CLJS.J.g(a,k);k=$CLJS.vj.h(l);l=$CLJS.ej.h(l);if($CLJS.n(k))try{var m=Z$.j(l,k,b);var t=$CLJS.n(c)?nta(m):m}catch(u){if(u instanceof
Error)t=Z$.j($CLJS.Wh,k,b);else throw u;}else t=null;e=f.call($CLJS.S,e,$CLJS.vj,t)}return e},d)},uta=function(a){a=$CLJS.tX($CLJS.Ze.g(function(b){return b instanceof X$?b:$CLJS.TC(b,sta,"")},a));return $CLJS.TC(a,tta,$CLJS.hd)},vta=function(a){return $CLJS.RC($CLJS.B($CLJS.w_(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.rP],null),new $CLJS.Q(null,1,5,$CLJS.R,[a],null))),$CLJS.rh)};var Z$=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.rh.h(f)},e,a,b,c,d)}();Z$.m(null,$CLJS.UQ,function(a,b,c){return(0,$CLJS.NK)(b).locale(c).format("MMMM D, YYYY")});
Z$.m(null,$CLJS.wP,function(a,b,c){a=(0,$CLJS.NK)(b,"YYYY-MM").locale(c);return $CLJS.n(a.isValid())?a.format("MMMM, YYYY"):""});Z$.m(null,$CLJS.qQ,function(a,b,c){a=(0,$CLJS.NK)(b,"[Q]Q-YYYY").locale(c);return $CLJS.n(a.isValid())?a.format("[Q]Q, YYYY"):""});Z$.m(null,$CLJS.$O,function(a,b,c){b=$CLJS.av(b,/~/);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?[$CLJS.p.h(Z$.j($CLJS.UQ,a,c))," - ",$CLJS.p.h(Z$.j($CLJS.UQ,b,c))].join(""):""});
Z$.m(null,$CLJS.rQ,function(a,b){function c(d,e){return $CLJS.C($CLJS.gh(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.UG("Today");if(c(/^thisweek$/,b))return $CLJS.UG("This Week");if(c(/^thismonth$/,b))return $CLJS.UG("This Month");if(c(/^thisquarter$/,b))return $CLJS.UG("This Quarter");if(c(/^thisyear$/,b))return $CLJS.UG("This Year");if(c(/^past1days$/,b))return $CLJS.UG("Yesterday");if(c(/^next1days$/,b))return $CLJS.UG("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.P.g(lta,
a);throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));});Z$.m(null,$CLJS.YT,function(a,b,c){function d(e,f){return $CLJS.hd($CLJS.gh(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.n(a))return Z$.j($CLJS.rQ,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.n(a))return Z$.j($CLJS.UQ,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.n(a)?Z$.j($CLJS.$O,a,c):Z$.j($CLJS.rQ,b,c)});
Z$.m(null,$CLJS.Wh,function(a,b){$CLJS.vd(b)?(a=$CLJS.E(b),b=$CLJS.n($CLJS.F.g?$CLJS.F.g(1,a):$CLJS.F.call(null,1,a))?$CLJS.p.h($CLJS.B(b)):$CLJS.n($CLJS.F.g?$CLJS.F.g(2,a):$CLJS.F.call(null,2,a))?$CLJS.uG("{0} and {1}",$CLJS.G([$CLJS.B(b),$CLJS.hd(b)])):$CLJS.uG("{0}, {1}, and {2}",$CLJS.G([$CLJS.Eu(", ",$CLJS.Nv(2,b)),$CLJS.bd(b,$CLJS.E(b)-2),$CLJS.id(b)]))):b=$CLJS.p.h(b);return b});var mta=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,Y$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,ota=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.g=X$.prototype;$CLJS.g.toString=function(){var a=$CLJS.vj.h(this);return $CLJS.n(a)?a:this.source};$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,this.tag],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qC,this.source],null)],null),this.G))};$CLJS.g.Ca=function(){return new $CLJS.Cu(this,2,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,$CLJS.qC],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-884441273^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.tag,b.tag)&&$CLJS.F.g(this.source,b.source)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.qC,null,$CLJS.zj,null],null),null),b)?$CLJS.wk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new X$(this.tag,this.source,this.S,$CLJS.De($CLJS.wk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.zj,b):$CLJS.fe.call(null,$CLJS.zj,b))?new X$(c,this.source,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.qC,b):$CLJS.fe.call(null,$CLJS.qC,b))?new X$(this.tag,c,this.S,this.G,null):new X$(this.tag,this.source,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Pf($CLJS.zj,this.tag),new $CLJS.Pf($CLJS.qC,this.source)],null),this.G))};
$CLJS.g.P=function(a,b){return new X$(this.tag,this.source,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};var sta=/\[\[.+\]\]/,tta=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.g(arguments[0],arguments[1]);case 4:return $$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.za("metabase.shared.parameters.parameters.substitute_tags",$$);$$.g=function(a,b){return $$.v(a,b,"en",!0)};$$.v=function(a,b,c,d){return $CLJS.n(a)?(b=$CLJS.eC(b),b=$CLJS.Du(b,vta),uta(qta(rta(b,c,d,pta(a))))):null};$$.A=4;module.exports={tag_names:function(a){a=$CLJS.Yg($CLJS.Ze.g($CLJS.hd,$CLJS.LY(Y$,$CLJS.n(a)?a:"")));return $CLJS.Zk(a)},substitute_tags:$$};