var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var wH,FH,CH,Hca;wH=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=wH[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=wH._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("Cached.-cache",a);}return a};
$CLJS.xH=function(a,b,c){if(null!=a&&$CLJS.r===a.Wa){var d=wH(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Gh.v(d,$CLJS.S,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.zH=function(a,b,c){a=$CLJS.yH.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.AH=function(a,b){return $CLJS.zH(a,b,null)};$CLJS.BH=function(a,b){$CLJS.Gh.v($CLJS.tE,$CLJS.tD,a,b)};$CLJS.DH=function(a,b){a=CH(a,b);return $CLJS.n(a)?a:$CLJS.sj};
FH=function(){return $CLJS.Wf.j(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Hi,$CLJS.B,$CLJS.vu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.vj);return $CLJS.yd(a)?["Invalid ",$CLJS.Eh.l($CLJS.G([$CLJS.B(a)]))," clause: ",$CLJS.Eh.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.No,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"not a known MBQL clause"],null),$CLJS.Le(!1)],null)],null)],null),$CLJS.Ze.h(function(a){return new $CLJS.Q(null,
2,5,$CLJS.R,[a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rh.g("mbql.clause",$CLJS.Zg(a))],null)],null)}),$CLJS.q(EH))};$CLJS.IH=function(a,b){$CLJS.P.v(GH,HH,a,b)};$CLJS.KH=function(a,b){$CLJS.P.v(GH,JH,a,b)};$CLJS.mp.prototype.ia=$CLJS.ua(26,function(){return this.cache});$CLJS.op.prototype.ia=$CLJS.ua(25,function(){return this.cache});$CLJS.qp.prototype.ia=$CLJS.ua(24,function(){return this.cache});$CLJS.sp.prototype.ia=$CLJS.ua(23,function(){return this.cache});
$CLJS.up.prototype.ia=$CLJS.ua(22,function(){return this.cache});$CLJS.wp.prototype.ia=$CLJS.ua(21,function(){return this.cache});$CLJS.zp.prototype.ia=$CLJS.ua(20,function(){return this.cache});$CLJS.Bp.prototype.ia=$CLJS.ua(19,function(){return this.cache});$CLJS.Dp.prototype.ia=$CLJS.ua(18,function(){return this.cache});$CLJS.Fp.prototype.ia=$CLJS.ua(17,function(){return this.cache});$CLJS.Hp.prototype.ia=$CLJS.ua(16,function(){return this.cache});$CLJS.Jp.prototype.ia=$CLJS.ua(15,function(){return this.cache});
$CLJS.Lp.prototype.ia=$CLJS.ua(14,function(){return this.cache});$CLJS.Np.prototype.ia=$CLJS.ua(13,function(){return this.cache});$CLJS.Pp.prototype.ia=$CLJS.ua(12,function(){return this.cache});$CLJS.Rp.prototype.ia=$CLJS.ua(11,function(){return this.cache});$CLJS.Up.prototype.ia=$CLJS.ua(10,function(){return this.cache});$CLJS.Zp.prototype.ia=$CLJS.ua(9,function(){return this.cache});$CLJS.aq.prototype.ia=$CLJS.ua(8,function(){return this.cache});$CLJS.dq.prototype.ia=$CLJS.ua(7,function(){return this.cache});
$CLJS.nq.prototype.ia=$CLJS.ua(6,function(){return this.cache});$CLJS.kG.prototype.ia=$CLJS.ua(5,function(){return this.cache});$CLJS.yH=function yH(a){switch(arguments.length){case 1:return yH.h(arguments[0]);case 2:return yH.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.yH.h=function(a){return $CLJS.yH.g(a,null)};$CLJS.yH.g=function(a,b){return $CLJS.xH($CLJS.lo.g(a,b),$CLJS.mk,$CLJS.jn)};$CLJS.yH.A=2;
CH=function CH(a,b){return $CLJS.F.g(a,$CLJS.sj)?null:$CLJS.F.g(b,$CLJS.sj)?null:$CLJS.FE(a,b)?b:$CLJS.FE(b,a)?a:$CLJS.Je(function(d){return $CLJS.Je(function(e){return $CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,[d,e],null),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null))?null:CH.g?CH.g(d,e):CH.call(null,d,e)},$CLJS.ce(b,$CLJS.Qh(b)))},$CLJS.ce(a,$CLJS.Qh(a)))};$CLJS.LH=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.MH=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.NH=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.OH=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.PH=new $CLJS.M(null,"power","power",-937852079);$CLJS.QH=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.RH=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.SH=new $CLJS.M(null,"abs","abs",-246026477);$CLJS.TH=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.UH=new $CLJS.M(null,"between","between",1131099276);
$CLJS.VH=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.WH=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.XH=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.YH=new $CLJS.M(null,"var","var",-769682797);$CLJS.ZH=new $CLJS.M(null,"next","next",-117701485);$CLJS.$H=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.aI=new $CLJS.M(null,"case","case",1143702196);$CLJS.bI=new $CLJS.M(null,"not-empty","not-empty",388922063);
$CLJS.cI=new $CLJS.M(null,"column-name","column-name",551523580);$CLJS.dI=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.eI=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.fI=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.gI=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.hI=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.iI=new $CLJS.M(null,"desc","desc",2093485764);$CLJS.jI=new $CLJS.M(null,"starts-with","starts-with",366503009);
$CLJS.kI=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.lI=new $CLJS.M(null,"short","short",1928760516);$CLJS.mI=new $CLJS.M(null,"avg","avg",197406200);$CLJS.nI=new $CLJS.M(null,"metric","metric",408798077);$CLJS.oI=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.pI=new $CLJS.M(null,"asc","asc",356854569);$CLJS.qI=new $CLJS.M(null,"floor","floor",1882041021);$CLJS.rI=new $CLJS.M(null,"contains","contains",676899812);
$CLJS.sI=new $CLJS.M(null,"field","field",-1302436500);$CLJS.tI=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.uI=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.vI=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.wI=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.xI=new $CLJS.M(null,"round","round",2009433328);$CLJS.yI=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);
$CLJS.zI=new $CLJS.M(null,"share","share",-589433933);$CLJS.AI=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.BI=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.CI=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.DI=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.EI=new $CLJS.M(null,"time-interval","time-interval",704622015);$CLJS.FI=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.GI=new $CLJS.M(null,"median","median",569566131);Hca=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.HI=new $CLJS.M(null,"include-current","include-current",-1602371981);$CLJS.II=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.JI=new $CLJS.M(null,"trim","trim",774319767);$CLJS.KI=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.LI=new $CLJS.M(null,"ltrim","ltrim",1654269283);
$CLJS.MI=new $CLJS.M(null,"category","category",-593092832);$CLJS.NI=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.OI=new $CLJS.M(null,"/","/",1282502798);$CLJS.PI=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.QI=new $CLJS.M(null,"log","log",-1595516004);$CLJS.RI=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.SI=new $CLJS.M(null,"rtrim","rtrim",-661336449);var EH=$CLJS.Oe($CLJS.Ug),JH;$CLJS.Y($CLJS.YG,FH());$CLJS.uE(EH,Hca,function(){return $CLJS.Y($CLJS.YG,FH())});
$CLJS.TI=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.WG.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.rh.g("mbql.clause",$CLJS.Zg(d));$CLJS.Y(f,e);$CLJS.Gd($CLJS.q(EH),d)||$CLJS.Gh.j(EH,$CLJS.$d,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
JH=function JH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return JH.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
JH.l=function(a,b){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,$CLJS.Wf.g(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ws,new $CLJS.h(null,1,[$CLJS.tu,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,a],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)],null)],null),b)],null)};JH.A=1;
JH.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};var HH=function HH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return HH.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
HH.l=function(a,b){return $CLJS.Wf.g(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,1,[$CLJS.tu,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null),b)};HH.A=1;HH.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var GH=function GH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return GH.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};GH.l=function(a,b,c){c=$CLJS.F.g($CLJS.B(c),$CLJS.ju)?$CLJS.ce($CLJS.hd(c),$CLJS.Te(2,c)):$CLJS.ce(null,c);var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);a=$CLJS.P.j(a,b,d);return $CLJS.n(c)?$CLJS.TI.v(b,$CLJS.ju,c,a):$CLJS.TI.g(b,a)};GH.A=2;
GH.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};