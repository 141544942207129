var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var P7=function(a){return $CLJS.n(O7)?["(",$CLJS.p.h(a),")"].join(""):a},qoa=function(a,b,c){var d=$CLJS.BY(a,b);a=$CLJS.u2.j(a,b,d);var e=$CLJS.nD(c);return $CLJS.Je(function(f){return $CLJS.F.g($CLJS.nD($CLJS.T.h(f)),e)},a)},roa=function(a,b){var c=$CLJS.Hk.v(a,$CLJS.WR,$CLJS.fG($CLJS.$d,$CLJS.tf),b);return $CLJS.yd($CLJS.bH.h(a))?$CLJS.Hk.v(c,$CLJS.bH,$CLJS.$d,$CLJS.lY(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DD,$CLJS.N,$CLJS.uY(b)],null))):c},soa=function(a,b,c){var d=$CLJS.uY(c);return $CLJS.S.l($CLJS.L1.j(a,
b,c),$CLJS.QM,$CLJS.w0,$CLJS.G([$CLJS.T,d,$CLJS.zG,d]))},Q7=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);var toa=function(){function a(d,e,f){var k=$CLJS.BY(d,e);k=$CLJS.DJ($CLJS.Ek.g($CLJS.Vg([f]),$CLJS.uY),$CLJS.WR.h(k));if($CLJS.n(k))return k;throw $CLJS.ai($CLJS.uG("No expression named {0}",$CLJS.G([$CLJS.Eh.l($CLJS.G([f]))])),new $CLJS.h(null,3,[$CLJS.XT,f,$CLJS.EO,d,$CLJS.FY,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.n2.m(null,$CLJS.DD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);c=toa.j(a,b,c);return $CLJS.o2.j(a,b,c)});$CLJS.p2.m(null,$CLJS.DD,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.H(c,2,null);return new $CLJS.h(null,7,[$CLJS.wj,$CLJS.OM,$CLJS.h2,$CLJS.JG.h(d),$CLJS.T,e,$CLJS.jH,e,$CLJS.zG,$CLJS.M1.j(a,b,c),$CLJS.GD,$CLJS.o2.j(a,b,c),$CLJS.QM,$CLJS.w0],null)});$CLJS.j2.m(null,$CLJS.yD,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.j2.m(null,$CLJS.wD,function(a,b,c){return $CLJS.p.h(c)});$CLJS.j2.m(null,$CLJS.xD,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.j2.m(null,$CLJS.DD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});$CLJS.k2.m(null,$CLJS.DD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});
for(var O7=!1,uoa=new $CLJS.h(null,4,[$CLJS.Js,"+",$CLJS.ju,"-",$CLJS.Ks,"×",$CLJS.OI,"÷"],null),R7=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Js,$CLJS.ju,$CLJS.OI,$CLJS.Ks],null)),S7=null,T7=0,U7=0;;)if(U7<T7){var voa=S7.X(null,U7);$CLJS.BH(voa,Q7);U7+=1}else{var V7=$CLJS.z(R7);if(V7){var W7=V7;if($CLJS.zd(W7)){var X7=$CLJS.jc(W7),woa=$CLJS.lc(W7),xoa=X7,yoa=$CLJS.E(X7);R7=woa;S7=xoa;T7=yoa}else{var zoa=$CLJS.B(W7);$CLJS.BH(zoa,Q7);R7=$CLJS.C(W7);S7=null;T7=0}U7=0}else break}
$CLJS.j2.m(null,Q7,function(a,b,c){var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);$CLJS.B(d);d=$CLJS.C(d);c=$CLJS.J.g(uoa,c);a:{var e=O7;O7=!0;try{var f=$CLJS.Eu([" ",$CLJS.Zg(c)," "].join(""),$CLJS.Ze.g($CLJS.pv($CLJS.M1,a,b),d));break a}finally{O7=e}f=void 0}return P7(f)});$CLJS.k2.m(null,Q7,function(){return"expression"});
$CLJS.n2.m(null,$CLJS.JJ,function(a,b,c){c=$CLJS.z(c);$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var d=$CLJS.C(c);return $CLJS.Od($CLJS.DH,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.o2.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce($CLJS.o2.j(a,
b,u),k($CLJS.Kc(l)))}return null}},null,null)}(d)}())});$CLJS.j2.m(null,$CLJS.jL,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.M1.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.A3.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=Math.abs(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=P7(f);return[a," ",d.call(b,f)].join("")});
$CLJS.k2.m(null,$CLJS.jL,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.l2.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.A3.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=Math.abs(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.j2.m(null,$CLJS.vI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.M1.v(a,b,e,d)});$CLJS.k2.m(null,$CLJS.vI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.l2.j(a,b,d)});
$CLJS.Aoa=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(qoa(d,e,f)))throw $CLJS.ai("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.XT,f],null));return $CLJS.IY.l(d,e,roa,$CLJS.G([$CLJS.vY($CLJS.nY.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Boa=function(){function a(d,e){var f=$CLJS.De($CLJS.WR.h($CLJS.BY(d,e)));return null==f?null:$CLJS.Gk.g($CLJS.pv(soa,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y7=function(){function a(d,e){return $CLJS.De($CLJS.WR.h($CLJS.BY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.kY.m(null,$CLJS.DD,function(a){return a});
$CLJS.Coa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.Sl(function(m,t){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uY(t),m],null)},$CLJS.Y7.g(d,e))),l=$CLJS.BY(d,e);d=$CLJS.u2.j(d,e,l);return $CLJS.De($CLJS.u3(function(m){return $CLJS.Sa(f)||$CLJS.Ck.g($CLJS.QM.h(m),$CLJS.w0)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();