var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var qv,rv,tv,vv,yv,zv,Av;
$CLJS.pv=function(a,b,c){return function(){function d(t,u,v){return a.N?a.N(b,c,t,u,v):a.call(null,b,c,t,u,v)}function e(t,u){return a.v?a.v(b,c,t,u):a.call(null,b,c,t,u)}function f(t){return a.j?a.j(b,c,t):a.call(null,b,c,t)}function k(){return a.g?a.g(b,c):a.call(null,b,c)}var l=null,m=function(){function t(v,x,A,D){var I=null;if(3<arguments.length){I=0;for(var K=Array(arguments.length-3);I<K.length;)K[I]=arguments[I+3],++I;I=new $CLJS.y(K,0,null)}return u.call(this,v,x,A,I)}function u(v,x,A,D){return $CLJS.P.l(a,
b,c,v,x,$CLJS.G([A,D]))}t.A=3;t.B=function(v){var x=$CLJS.B(v);v=$CLJS.C(v);var A=$CLJS.B(v);v=$CLJS.C(v);var D=$CLJS.B(v);v=$CLJS.Kc(v);return u(x,A,D,v)};t.l=u;return t}();l=function(t,u,v,x){switch(arguments.length){case 0:return k.call(this);case 1:return f.call(this,t);case 2:return e.call(this,t,u);case 3:return d.call(this,t,u,v);default:var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.l(t,u,v,A)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.B=m.B;l.o=k;l.h=f;l.g=e;l.j=d;l.l=m.l;return l}()};qv=function(a,b){return $CLJS.F.g(a,b)?new $CLJS.Q(null,3,5,$CLJS.R,[null,null,a],null):new $CLJS.Q(null,3,5,$CLJS.R,[a,b,null],null)};rv=function(a){return $CLJS.z(a)?$CLJS.ab(function(b,c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return $CLJS.S.j(b,d,c)},$CLJS.uf($CLJS.Ve($CLJS.P.g($CLJS.Ak,$CLJS.Pg(a)),null)),a):null};
tv=function(a,b,c){var d=$CLJS.J.g(a,c),e=$CLJS.J.g(b,c),f=$CLJS.sv(d,e),k=$CLJS.H(f,0,null),l=$CLJS.H(f,1,null);f=$CLJS.H(f,2,null);a=$CLJS.Gd(a,c);b=$CLJS.Gd(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.Q(null,3,5,$CLJS.R,[!a||null==k&&d?null:$CLJS.Ce([c,k]),!b||null==l&&d?null:$CLJS.Ce([c,l]),d?$CLJS.Ce([c,f]):null],null)};
vv=function(a,b){var c=$CLJS.Ze.g,d=uv.j,e=$CLJS.yd(a)?a:$CLJS.uf(a),f=$CLJS.yd(b)?b:$CLJS.uf(b);a=$CLJS.E(a);b=$CLJS.E(b);return $CLJS.uf(c.call($CLJS.Ze,rv,d.call(uv,e,f,$CLJS.dh(0,a>b?a:b))))};yv=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.De($CLJS.wv.g(a,b)),$CLJS.De($CLJS.wv.g(b,a)),$CLJS.De($CLJS.xv.g(a,b))],null)};
zv=function(a){if(null!=a&&null!=a.ah)a=a.ah(a);else{var b=zv[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=zv._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("EqualityPartition.equality-partition",a);}return a};Av=function(a,b){if(null!=a&&null!=a.$g)a=a.$g(a,b);else{var c=Av[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Av._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("Diff.diff-similar",a);}return a};
$CLJS.sv=function(a,b){return $CLJS.F.g(a,b)?new $CLJS.Q(null,3,5,$CLJS.R,[null,null,a],null):$CLJS.F.g(zv(a),zv(b))?Av(a,b):qv(a,b)};$CLJS.xv=function xv(a){switch(arguments.length){case 1:return xv.h(arguments[0]);case 2:return xv.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xv.l(arguments[0],arguments[1],new $CLJS.y(c.slice(2),0,null))}};$CLJS.xv.h=function(a){return a};
$CLJS.xv.g=function(a,b){for(;;)if($CLJS.E(b)<$CLJS.E(a)){var c=a;a=b;b=c}else return $CLJS.ab(function(d,e){return function(f,k){return $CLJS.Gd(e,k)?f:$CLJS.xk.g(f,k)}}(a,b),a,a)};$CLJS.xv.l=function(a,b,c){a=$CLJS.kv(function(d){return-$CLJS.E(d)},$CLJS.$d.l(c,b,$CLJS.G([a])));return $CLJS.ab($CLJS.xv,$CLJS.B(a),$CLJS.Kc(a))};$CLJS.xv.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};$CLJS.xv.A=2;
$CLJS.wv=function wv(a){switch(arguments.length){case 1:return wv.h(arguments[0]);case 2:return wv.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wv.l(arguments[0],arguments[1],new $CLJS.y(c.slice(2),0,null))}};$CLJS.wv.h=function(a){return a};$CLJS.wv.g=function(a,b){return $CLJS.E(a)<$CLJS.E(b)?$CLJS.ab(function(c,d){return $CLJS.Gd(b,d)?$CLJS.xk.g(c,d):c},a,a):$CLJS.ab($CLJS.xk,a,b)};
$CLJS.wv.l=function(a,b,c){return $CLJS.ab($CLJS.wv,a,$CLJS.$d.g(c,b))};$CLJS.wv.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};$CLJS.wv.A=2;var Bv=new $CLJS.M(null,"atom","atom",-397043653);var uv=function uv(a){switch(arguments.length){case 2:return uv.g(arguments[0],arguments[1]);case 3:return uv.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};uv.g=function(a,b){return uv.j(a,b,$CLJS.ov.g($CLJS.Pg(a),$CLJS.Pg(b)))};uv.j=function(a,b,c){return $CLJS.ab(function(d,e){return $CLJS.eh($CLJS.Ze.j($CLJS.Wk,d,e))},new $CLJS.Q(null,3,5,$CLJS.R,[null,null,null],null),$CLJS.Ze.g($CLJS.pv(tv,a,b),c))};uv.A=3;
zv["null"]=function(){return Bv};zv.string=function(){return Bv};zv.number=function(){return Bv};zv.array=function(){return $CLJS.rj};zv["function"]=function(){return Bv};zv["boolean"]=function(){return Bv};
zv._=function(a){return(null!=a?a.C&1024||$CLJS.r===a.Pg||(a.C?0:$CLJS.Wa($CLJS.wb,a)):$CLJS.Wa($CLJS.wb,a))?$CLJS.Zj:(null!=a?a.C&4096||$CLJS.r===a.Tg||(a.C?0:$CLJS.Wa($CLJS.Bb,a)):$CLJS.Wa($CLJS.Bb,a))?$CLJS.Si:(null!=a?a.C&16777216||$CLJS.r===a.nf||(a.C?0:$CLJS.Wa($CLJS.Ub,a)):$CLJS.Wa($CLJS.Ub,a))?$CLJS.rj:Bv};Av["null"]=function(a,b){return qv(a,b)};Av.string=function(a,b){return qv(a,b)};Av.number=function(a,b){return qv(a,b)};Av.array=function(a,b){return vv(a,b)};
Av["function"]=function(a,b){return qv(a,b)};Av["boolean"]=function(a,b){return qv(a,b)};Av._=function(a,b){var c=function(){var d=zv(a);d=d instanceof $CLJS.M?d.T:null;switch(d){case "atom":return qv;case "set":return yv;case "sequential":return vv;case "map":return uv;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}();return c.g?c.g(a,b):c.call(null,a,b)};