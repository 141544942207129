var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var PK,RK,TK,eL,iL,qL;$CLJS.OK=new $CLJS.M(null,"get-month","get-month",-369374731);PK=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.QK=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);RK=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.SK=new $CLJS.M(null,"iso","iso",-1366207543);TK=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.UK=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.VK=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.WK=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.XK=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.YK=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.ZK=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.$K=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.aL=new $CLJS.M(null,"us","us",746429226);$CLJS.bL=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.cL=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.dL=new $CLJS.M(null,"now","now",-1650525531);eL=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.fL=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.gL=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.hL=new $CLJS.M(null,"get-week","get-week",-888059349);
iL=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.jL=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.kL=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.lL=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.mL=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.nL=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.oL=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.pL=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);qL=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.rL=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.sL=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.tL=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.uL=new $CLJS.M(null,"target","target",253001721);$CLJS.IH($CLJS.$K,$CLJS.G([$CLJS.ju,$CLJS.qF,$CLJS.st,$CLJS.sG]));$CLJS.WG.m(null,$CLJS.XK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.XG(a);return $CLJS.ud(a)?(a=$CLJS.xv.g(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.fF,null,$CLJS.EF,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.BH($CLJS.XK,$CLJS.nH);
for(var vL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jL,$CLJS.pL],null)),wL=null,xL=0,yL=0;;)if(yL<xL){var zL=wL.X(null,yL);$CLJS.IH(zL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null),$CLJS.st,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)]));$CLJS.BH(zL,$CLJS.XK);yL+=1}else{var AL=$CLJS.z(vL);if(AL){var BL=AL;if($CLJS.zd(BL)){var CL=$CLJS.jc(BL),dea=$CLJS.lc(BL),eea=CL,fea=$CLJS.E(CL);vL=dea;wL=eea;xL=fea}else{var DL=$CLJS.B(BL);$CLJS.IH(DL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.eH],null),$CLJS.st,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)]));$CLJS.BH(DL,$CLJS.XK);vL=$CLJS.C(BL);wL=null;xL=0}yL=0}else break}
for(var EL=$CLJS.z(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.VK,$CLJS.OK,$CLJS.gL,$CLJS.oL,$CLJS.kL,$CLJS.UK,$CLJS.rL],null)),FL=null,GL=0,HL=0;;)if(HL<GL){var gea=FL.X(null,HL);$CLJS.IH(gea,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)]));HL+=1}else{var IL=$CLJS.z(EL);if(IL){var JL=IL;if($CLJS.zd(JL)){var KL=$CLJS.jc(JL),hea=$CLJS.lc(JL),iea=KL,jea=$CLJS.E(KL);EL=hea;FL=iea;GL=jea}else{var kea=$CLJS.B(JL);$CLJS.IH(kea,$CLJS.G([$CLJS.ju,
$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)]));EL=$CLJS.C(JL);FL=null;GL=0}HL=0}else break}$CLJS.IH($CLJS.ZK,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jJ],null)]));
for(var LL=$CLJS.z(new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.rL,null,$CLJS.kL,null,$CLJS.oL,null,$CLJS.lL,null,$CLJS.UK,null,$CLJS.OK,null,$CLJS.VK,null,$CLJS.gL,null],null),null)),ML=null,NL=0,OL=0;;)if(OL<NL){var lea=ML.X(null,OL);$CLJS.IH(lea,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)]));OL+=1}else{var PL=$CLJS.z(LL);if(PL){var QL=PL;if($CLJS.zd(QL)){var RL=$CLJS.jc(QL),mea=$CLJS.lc(QL),nea=RL,oea=$CLJS.E(RL);
LL=mea;ML=nea;NL=oea}else{var pea=$CLJS.B(QL);$CLJS.IH(pea,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)]));LL=$CLJS.C(QL);ML=null;NL=0}OL=0}else break}$CLJS.Y(qL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.SK,$CLJS.aL,$CLJS.fL],null));
$CLJS.KH($CLJS.hL,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,qL],null)],null)],null)],null)]));
$CLJS.Y($CLJS.mL,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,$CLJS.DG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,2,[$CLJS.tu,"valid timezone ID",$CLJS.vu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.vj);return["invalid timezone ID: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.dJ($CLJS.Hd,$CLJS.NK.tz.names())),$CLJS.zK],null)],null));
$CLJS.KH($CLJS.QK,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mL],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mL],null)],null)],null)],
null)]));$CLJS.BH($CLJS.QK,$CLJS.XK);$CLJS.IH($CLJS.dL,$CLJS.G([$CLJS.ju,$CLJS.iF]));$CLJS.Y(TK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Je(function(b){return $CLJS.FE(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fF,$CLJS.EF],null))}],null)],null));
$CLJS.Y(PK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GD,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TK],null)],null)],null)],null));
$CLJS.TI.g($CLJS.WK,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.tu,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.WK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Vs,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.tu,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.DK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.FK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.xK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lJ],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.tu,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.tx],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.HK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gJ],null)],null)],null)],null)],null)],null));
$CLJS.WG.m(null,$CLJS.WK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.tx)?$CLJS.F.g(a,$CLJS.Wh)?$CLJS.EF:$CLJS.n($CLJS.AH($CLJS.lJ,a))?$CLJS.fF:$CLJS.EF:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.fh($CLJS.LK,b))?$CLJS.fF:$CLJS.n($CLJS.fh($CLJS.MK,b))?$CLJS.fF:null:null;if($CLJS.n(a))return a;b=$CLJS.XG(b);b=$CLJS.ud(b)?$CLJS.Wf.j($CLJS.Ug,$CLJS.af(function(c){return $CLJS.FE(c,$CLJS.Oj)}),b):b;return $CLJS.ud(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.Y(iL,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.tx],null),$CLJS.st],null));
$CLJS.KH($CLJS.bL,$CLJS.G([$CLJS.ju,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ky,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iL],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)],null)],null)],null)]));
$CLJS.IH($CLJS.XA,$CLJS.G([$CLJS.ju,$CLJS.dG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kJ],null)]));$CLJS.Y(RK,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.ct,$CLJS.iJ,$CLJS.ti,$CLJS.ck,$CLJS.YK,$CLJS.sL,$CLJS.tL,$CLJS.yj,$CLJS.zi,$CLJS.pi,$CLJS.aj,$CLJS.fJ],null));$CLJS.Y(eL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.SK,$CLJS.aL,$CLJS.fL],null));
$CLJS.KH($CLJS.nL,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eL],null)],null)],
null)],null)]));