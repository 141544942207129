var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var vK,wK,Sda,Tda,Uda,AK,BK,CK,EK,Vda,GK;vK=function(a){switch(arguments.length){case 2:return $CLJS.AH(arguments[0],arguments[1]);case 3:return $CLJS.zH(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};wK=function(a){return["(?:",$CLJS.p.h($CLJS.P.g($CLJS.p,a)),")?"].join("")};Sda=new $CLJS.M("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
Tda=new $CLJS.M("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.xK=new $CLJS.M("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Uda=new $CLJS.M("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.yK=new $CLJS.M("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.zK=new $CLJS.M("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);AK=new $CLJS.M("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);BK=new $CLJS.M("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);CK=new $CLJS.M("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.DK=new $CLJS.M("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);EK=new $CLJS.M("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Vda=new $CLJS.M("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.FK=new $CLJS.M("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
GK=new $CLJS.M("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.HK=new $CLJS.M("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.WG.m(null,$CLJS.JD,function(){return $CLJS.sj});$CLJS.Y(GK,$CLJS.wt);$CLJS.WG.m(null,$CLJS.MD,function(){return $CLJS.Dj});$CLJS.Y(GK,$CLJS.wt);$CLJS.Y(Vda,$CLJS.st);$CLJS.WG.m(null,$CLJS.yD,function(){return $CLJS.oj});$CLJS.Y(Tda,$CLJS.rt);$CLJS.WG.m(null,$CLJS.wD,function(){return $CLJS.aG});$CLJS.Y(Uda,$CLJS.Rj);
var Wda=[":\\d{2}",wK($CLJS.G(["\\.\\d{1,6}"]))].join(""),IK=["\\d{2}:\\d{2}",wK($CLJS.G([Wda]))].join(""),JK=["\\d{4}-\\d{2}-\\d{2}T",IK].join(""),KK=["(?:Z|(?:[+-]",IK,"))"].join(""),Xda=$CLJS.hh(["(?:Z|(?:[+-]",IK,"))"].join("")),Yda=$CLJS.hh("^\\d{4}-\\d{2}-\\d{2}$"),Zda=$CLJS.hh(["^",IK,"$"].join("")),$da=$CLJS.hh(["^",IK,KK,"$"].join("")),aea=$CLJS.hh(["^",JK,"$"].join("")),bea=$CLJS.hh(["^",JK,KK,"$"].join(""));
$CLJS.Y(BK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"date string literal"],null),Yda],null));$CLJS.Y($CLJS.zK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"timezone offset string literal"],null),Xda],null));
$CLJS.Y(AK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"local time string literal"],null),Zda],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"offset time string literal"],null),$da],null)],null));
$CLJS.Y(CK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"local date time string literal"],null),aea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"offset date time string literal"],null),bea],null)],null));
$CLJS.WG.m(null,$CLJS.xD,function(a){return $CLJS.n($CLJS.AH?$CLJS.AH(CK,a):vK.call(null,CK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Vj,null,$CLJS.EF,null],null),null):$CLJS.n($CLJS.AH?$CLJS.AH(BK,a):vK.call(null,BK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.fF,null,$CLJS.Vj,null],null),null):$CLJS.n($CLJS.AH?$CLJS.AH(AK,a):vK.call(null,AK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.dG,null,$CLJS.Vj,null],null),null):$CLJS.Vj});$CLJS.Y($CLJS.DK,BK);$CLJS.Y($CLJS.yK,AK);
$CLJS.Y($CLJS.HK,CK);$CLJS.Y(Sda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gt,$CLJS.DK,$CLJS.yK,$CLJS.HK],null));$CLJS.LK=$CLJS.hh("^\\d{4}-\\d{2}$");$CLJS.Y($CLJS.FK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"year-month string literal"],null),$CLJS.LK],null));$CLJS.MK=$CLJS.hh("^\\d{4}$");$CLJS.Y($CLJS.xK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"year string literal"],null),$CLJS.MK],null));
$CLJS.Y(EK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yi,$CLJS.LG],null)],null)],null));$CLJS.TI.g($CLJS.vj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,1,[$CLJS.tu,"Value :value clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.vj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EK],null),$CLJS.rl],null));