var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var ana=function(a,b){return new $CLJS.le(null,function(){a:for(var c=a,d=b;;){d=$CLJS.z(d);var e;if(e=d)e=$CLJS.B(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Kc(d);else break a}return d},null,null)},bna=function(a,b){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mv(a,b),ana(a,b)],null)},S5=function(a){var b=$CLJS.Wk.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.L5);c=$CLJS.J.g(c,$CLJS.M5);if($CLJS.n($CLJS.n(d)?$CLJS.F.g(c,"currency"):d)){c=$CLJS.G5.h;d=$CLJS.rh.h(d);var e=$CLJS.q($CLJS.D5);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.G5,d);c=new $CLJS.h(null,2,[$CLJS.K5,c,$CLJS.N5,c],null)}else c=new $CLJS.h(null,1,[$CLJS.N5,2],null);b=b.call($CLJS.Wk,$CLJS.G([c,a]));return $CLJS.n($CLJS.O5.h(a))?$CLJS.S.l($CLJS.wk.g(b,$CLJS.O5),$CLJS.N5,$CLJS.O5.h(a),$CLJS.G([$CLJS.K5,$CLJS.O5.h(a)])):b},T5=function(a,b){if(null!=a&&null!=a.Ne)a=a.Ne(a,b);else{var c=T5[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=T5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("NumberFormatter.format-number-basic",
a);}return a},cna=function(a,b){var c=$CLJS.dC(a.formatToParts(b),$CLJS.G([new $CLJS.h(null,1,[$CLJS.si,!0],null)]));a=$CLJS.n($CLJS.Je(function(d){return $CLJS.F.g($CLJS.ej.h(d),"exponentMinusSign")},c))?c:function(){var d=bna(function(f){return $CLJS.Ck.g($CLJS.ej.h(f),"exponentInteger")},c),e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.Ye.l(e,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.ej,"exponentPlusSign",$CLJS.vj,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.P.g($CLJS.p,
$CLJS.Ze.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.ej);e=$CLJS.J.g(e,$CLJS.vj);switch(d){case "exponentSeparator":return"e";default:return e}},a))},dna=function(a,b){switch($CLJS.M5.h(b)){case "scientific":return function(c){return cna(a,c)};default:return function(c){return a.format(c)}}},U5=function(a,b,c,d,e){this.options=a;this.vg=b;this.yg=c;this.de=d;this.di=e;this.C=393216;this.I=0},ena=function(a,b){return $CLJS.P.g($CLJS.p,function(){return function e(d){return new $CLJS.le(null,
function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.kd(k,v),A=$CLJS.O(x);x=$CLJS.J.g(A,$CLJS.ej);A=$CLJS.J.g(A,$CLJS.vj);var D=new $CLJS.Tg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);D=D.h?D.h(x):D.call(null,x);$CLJS.n(D)&&(x=$CLJS.F.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.ej);t=$CLJS.J.g(t,$CLJS.vj);if($CLJS.n(function(){var v=new $CLJS.Tg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ce($CLJS.F.g(u,"integer")?b:t,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}($CLJS.dC(a.vg.formatToParts(1),$CLJS.G([$CLJS.si,!0])))}())},V5=function(a){var b=$CLJS.F.g($CLJS.M5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.Zk($CLJS.WC($CLJS.Ta,$CLJS.Ag([$CLJS.Yma,
$CLJS.Qma,$CLJS.Tma,$CLJS.Vma,$CLJS.$ma,$CLJS.L5,$CLJS.Uma,$CLJS.Wma,$CLJS.Rma,$CLJS.Sma],[$CLJS.N5.g(a,b),$CLJS.Q5.h(a),$CLJS.P5.h(a),$CLJS.Xma.h(a),$CLJS.F.g($CLJS.M5.h(a),"scientific")?null:$CLJS.M5.g(a,"decimal"),$CLJS.L5.h(a),!0,$CLJS.K5.g(a,b),$CLJS.F.g($CLJS.M5.h(a),"scientific")?"scientific":null,$CLJS.R5.h(a)]))));var c=$CLJS.Q5.h(a);var d=$CLJS.L5.h(a);c=$CLJS.n(d)?null==c||$CLJS.F.g(c,"symbol"):d;d=dna(b,a);return new U5(a,b,c,d,$CLJS.N)},W5=function(a,b){return V5(S5(b)).Ne(null,a)},Y5=
function(a,b){var c=$CLJS.wk.l(b,$CLJS.ND,$CLJS.G([$CLJS.M5])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.X5.g?$CLJS.X5.g(a,c):$CLJS.X5.call(null,a,c);b=$CLJS.B($CLJS.bf(function(f){return d>=$CLJS.B(f)},fna));var e=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.Wk.l($CLJS.G([c,new $CLJS.h(null,2,[$CLJS.K5,1,$CLJS.N5,1],null)]));return $CLJS.X5.g?$CLJS.X5.g(f,k):$CLJS.X5.call(null,f,k)}()),$CLJS.p.h(b)].join("")},gna=function(a,b){var c=S5(b);if($CLJS.n(Z5.h(c)))b=
Z5.h(c);else if($CLJS.Sa($CLJS.O5.h(c))&&$CLJS.Sa($CLJS.K5.h(c))&&$CLJS.Ck.g($CLJS.M5.h(c),"currency")&&Math.abs(a)<($CLJS.F.g($CLJS.M5.h(c),"percent")?.01:1)){b=$CLJS.S.j;var d=$CLJS.wk.g(c,$CLJS.N5);c=$CLJS.R5.g(c,0);b=V5(b.call($CLJS.S,d,$CLJS.P5,2>c?2:c))}else b=V5(c);return T5(b,a)};U5.prototype.P=function(a,b){return new U5(this.options,this.vg,this.yg,this.de,b)};U5.prototype.O=function(){return this.di};
U5.prototype.Ne=function(a,b){a=this.de.h?this.de.h(b):this.de.call(null,b);var c=$CLJS.Zma.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Ck.g(c,".,"):c)&&(b=$CLJS.B(c),c=$CLJS.hd(c),a=$CLJS.TC(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.yg)&&(b=$CLJS.L5.h(this.options),c=$CLJS.E5(b),a=$CLJS.TC($CLJS.TC(a,[$CLJS.Zg(b)," "].join(""),c),$CLJS.Zg(b),c));return a};
var Z5=new $CLJS.M(null,"number-formatter","number-formatter",929788393),$5=new $CLJS.M(null,"scale","scale",-230427353),a6=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),hna=new $CLJS.M(null,"scientific","scientific",316285837);var b6=new $CLJS.h(null,1,[$CLJS.Q5,"symbol"],null),ina=$CLJS.Zk(b6),fna=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[1E12,"T"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E9,"B"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E6,"M"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E3,"k"],null)],null),c6=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.M5)},e,a,b,c,d)}();c6.m(null,$CLJS.Wh,function(a,b){return Y5(a,b)});c6.m(null,"percent",function(a,b){return[$CLJS.p.h(Y5(100*a,b)),"%"].join("")});c6.m(null,"currency",function(a,b){b=$CLJS.Wk.l($CLJS.G([b,b6]));var c=V5(b);return 1E3>Math.abs(a)?c.Ne(null,a):ena(c,Y5(a,b))});c6.m(null,"scientific",function(a,b){return W5(a,$CLJS.Wk.l($CLJS.G([b,new $CLJS.h(null,2,[$CLJS.N5,1,$CLJS.K5,1],null)])))});
$CLJS.X5=function X5(a,b){b=$CLJS.sD(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.ND);var e=$CLJS.J.g(d,a6),f=$CLJS.J.g(d,$CLJS.M5),k=$CLJS.J.g(d,$5);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.wk.g(d,$5),X5.g?X5.g(b,f):X5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.S.j(d,a6,!1);return X5.g?X5.g(l,m):X5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?c6.g(a,S5($CLJS.wk.g(d,$CLJS.ND))):$CLJS.F.g($CLJS.rh.h(f),hna)?W5(a,d):gna(a,d)};
module.exports={compact_currency_options_js:ina,format_number:$CLJS.X5};