var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Ica,Jca,Kca,Lca,Mca,Nca,Oca,YI,Pca,Qca,Rca,Sca,Tca,Uca,Vca,Wca,$I,Xca;$CLJS.UI=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Ica=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.VI=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Jca=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Kca=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.WI=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Lca=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.XI=new $CLJS.M(null,"display-info","display-info",-816930907);Mca=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);Nca=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
Oca=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);YI=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Pca=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);Qca=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);Rca=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);Sca=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
Tca=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);Uca=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);Vca=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.ZI=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);Wca=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);$I=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.aJ=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);Xca=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.KH($CLJS.iA,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hH],null)],null)],null)],null)]));$CLJS.KH($CLJS.tI,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hH],null)],null)],null)],null)]));
$CLJS.IH($CLJS.mI,$CLJS.G([$CLJS.ju,$CLJS.aG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));$CLJS.IH($CLJS.XH,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hH],null)],null)]));$CLJS.IH($CLJS.BI,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gH],null)],null)]));
$CLJS.IH($CLJS.ak,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null)],null)]));$CLJS.BH($CLJS.ak,$CLJS.nH);$CLJS.IH($CLJS.GI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));$CLJS.BH($CLJS.GI,$CLJS.nH);$CLJS.IH($CLJS.Mn,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null)],null)]));$CLJS.BH($CLJS.Mn,$CLJS.nH);
$CLJS.Y(YI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.tu,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.IH($CLJS.dI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YI],null)]));$CLJS.BH($CLJS.dI,$CLJS.nH);
$CLJS.IH($CLJS.zI,$CLJS.G([$CLJS.ju,$CLJS.aG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gH],null)],null)]));$CLJS.IH($CLJS.II,$CLJS.G([$CLJS.ju,$CLJS.aG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));$CLJS.IH($CLJS.FI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));
$CLJS.IH($CLJS.PI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));$CLJS.BH($CLJS.FI,$CLJS.nH);$CLJS.BH($CLJS.PI,$CLJS.nH);$CLJS.IH($CLJS.NI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gH],null)],null)]));$CLJS.BH($CLJS.NI,$CLJS.nH);
$CLJS.IH($CLJS.YH,$CLJS.G([$CLJS.ju,$CLJS.aG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));$CLJS.Y($I,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.gt,Wca,Uca,Nca,Jca,Ica,Mca,Oca,Pca,Qca,Tca,Rca,Xca,Vca,Kca,Sca,$CLJS.rl],null));$CLJS.Y($CLJS.VI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rj,new $CLJS.h(null,1,[$CLJS.Mn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$I],null)],null));
$CLJS.bJ=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.lI,$CLJS.iA,$CLJS.WI,!1,$CLJS.ZI,$CLJS.NH,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Count of rows"),$CLJS.cI,$CLJS.UG("Count"),$CLJS.Qu,$CLJS.UG("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.lI,$CLJS.FI,$CLJS.UI,$CLJS.Xj,$CLJS.WI,!0,$CLJS.ZI,$CLJS.NH,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Sum of ..."),$CLJS.cI,$CLJS.UG("Sum"),$CLJS.Qu,$CLJS.UG("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.lI,$CLJS.mI,$CLJS.UI,$CLJS.Xj,$CLJS.WI,!0,$CLJS.ZI,$CLJS.NH,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Average of ..."),$CLJS.cI,$CLJS.UG("Average"),$CLJS.Qu,$CLJS.UG("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.lI,$CLJS.GI,$CLJS.UI,$CLJS.Xj,$CLJS.WI,!0,$CLJS.ZI,$CLJS.oI,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Median of ..."),$CLJS.cI,$CLJS.UG("Median"),$CLJS.Qu,$CLJS.UG("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.lI,$CLJS.XH,$CLJS.UI,$CLJS.ei,$CLJS.WI,!0,$CLJS.ZI,$CLJS.NH,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Number of distinct values of ..."),$CLJS.cI,$CLJS.UG("Distinct values"),$CLJS.Qu,$CLJS.UG("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.lI,$CLJS.PI,$CLJS.UI,$CLJS.Xj,$CLJS.WI,!0,$CLJS.ZI,$CLJS.NH,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Cumulative sum of ..."),
$CLJS.cI,$CLJS.UG("Sum"),$CLJS.Qu,$CLJS.UG("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.lI,$CLJS.tI,$CLJS.WI,!1,$CLJS.ZI,$CLJS.NH,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Cumulative count of rows"),$CLJS.cI,$CLJS.UG("Count"),$CLJS.Qu,$CLJS.UG("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.lI,$CLJS.II,$CLJS.UI,$CLJS.Xj,$CLJS.WI,
!0,$CLJS.ZI,$CLJS.CI,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Standard deviation of ..."),$CLJS.cI,$CLJS.UG("SD"),$CLJS.Qu,$CLJS.UG("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.lI,$CLJS.Mn,$CLJS.UI,$CLJS.Pi,$CLJS.WI,!0,$CLJS.ZI,$CLJS.NH,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Minimum of ..."),$CLJS.cI,$CLJS.UG("Min"),$CLJS.Qu,$CLJS.UG("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.lI,$CLJS.ak,$CLJS.UI,$CLJS.Pi,$CLJS.WI,!0,$CLJS.ZI,$CLJS.NH,$CLJS.XI,function(){return new $CLJS.h(null,3,[$CLJS.zG,$CLJS.UG("Maximum of ..."),$CLJS.cI,$CLJS.UG("Max"),$CLJS.Qu,$CLJS.UG("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Lca,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.aJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lI,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ct],null),$CLJS.Ze.h($CLJS.lI),$CLJS.bJ)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UI,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Oi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WI,$CLJS.wt],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.ZI,$CLJS.Oi],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XI,$CLJS.md],null)],null));