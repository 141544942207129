var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var VB=function(){},WB=function(a){return $CLJS.J.g($CLJS.KA,a).value},bba=function(a){var b=WB(a);return $CLJS.n(function(){var c=WB($CLJS.aB);return $CLJS.zk.g?$CLJS.zk.g(c,b):$CLJS.zk.call(null,c,b)}())?$CLJS.GB:$CLJS.n(function(){var c=WB($CLJS.$A);return $CLJS.zk.g?$CLJS.zk.g(c,b):$CLJS.zk.call(null,c,b)}())?$CLJS.QB:$CLJS.n(function(){var c=WB($CLJS.YA);return $CLJS.zk.g?$CLJS.zk.g(c,b):$CLJS.zk.call(null,c,b)}())?$CLJS.JB:$CLJS.n(function(){var c=WB($CLJS.SA);return $CLJS.zk.g?
$CLJS.zk.g(c,b):$CLJS.zk.call(null,c,b)}())?$CLJS.PB:$CLJS.n(function(){var c=WB($CLJS.ZA);return $CLJS.zk.g?$CLJS.zk.g(c,b):$CLJS.zk.call(null,c,b)}())?$CLJS.RB:$CLJS.n(function(){var c=WB($CLJS.VA);return $CLJS.zk.g?$CLJS.zk.g(c,b):$CLJS.zk.call(null,c,b)}())?$CLJS.MB:$CLJS.n(function(){var c=WB($CLJS.PA);return $CLJS.zk.g?$CLJS.zk.g(c,b):$CLJS.zk.call(null,c,b)}())?$CLJS.NB:$CLJS.OB},XB=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},YB=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.UB,c))].join(""),$CLJS.G(["color:black"]))],null)},ZB=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.UB,$CLJS.SB)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.UB,
c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],null)},cba=function(a){function b(c,d){return d>=c}a=WB(a);if(b(WB($CLJS.aB),a))return"error";if(b(WB($CLJS.$A),a))return"warn";if(b(WB($CLJS.YA),a))return"info";b(WB($CLJS.SA),a);return"log"},$B=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.QA),e=$CLJS.J.g(c,$CLJS.Ou);b=$CLJS.J.g(c,$CLJS.bB);e=cba(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.P.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=["[",
$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},aC=function aC(a,b){for(;;){if($CLJS.F.g($CLJS.FB,b))return YB(a,", ",$CLJS.OB);if($CLJS.F.g($CLJS.HB,b))return XB(a);if(b instanceof $CLJS.M)return YB(a,b,$CLJS.JB);if(b instanceof $CLJS.w)return YB(a,b,$CLJS.PB);if("string"===typeof b)return YB(a,$CLJS.Eh.l($CLJS.G([b])),$CLJS.IB);if($CLJS.qf(b)){var d=XB(function(){var f=a,k=$CLJS.yb(b);return aC.g?aC.g(f,k):aC.call(null,f,k)}()),e=$CLJS.zb(b);
return aC.g?aC.g(d,e):aC.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.zg)return d=a,d=YB(d,"{",$CLJS.TB),d=$CLJS.ab(aC,d,$CLJS.Xe($CLJS.FB,b)),YB(d,"}",$CLJS.TB);if($CLJS.wd(b))return d=a,d=YB(d,["#",$CLJS.p.h(function(){var f=$CLJS.Xa(b),k=f.name;return $CLJS.sd(k)?$CLJS.Eh.l($CLJS.G([f])):k}())," "].join(""),$CLJS.KB),d=YB(d,"{",$CLJS.TB),d=$CLJS.ab(aC,d,$CLJS.Xe($CLJS.FB,b)),YB(d,"}",$CLJS.TB);if($CLJS.ud(b))return d=a,d=YB(d,"#{",$CLJS.TB),d=$CLJS.ab(aC,d,$CLJS.Xe($CLJS.HB,b)),YB(d,
"}",$CLJS.TB);if($CLJS.yd(b))return d=a,d=YB(d,"[",$CLJS.TB),d=$CLJS.ab(aC,d,$CLJS.Xe($CLJS.HB,b)),YB(d,"]",$CLJS.TB);if(b instanceof $CLJS.If)d=YB(a,"#queue ",$CLJS.KB),e=$CLJS.Wf.g($CLJS.tf,b),a=d,b=e;else{if($CLJS.Cd(b))return d=a,d=YB(d,"(",$CLJS.KB),d=$CLJS.ab(aC,d,$CLJS.Xe($CLJS.HB,b)),YB(d,")",$CLJS.KB);if(null!=b?b.I&16384||$CLJS.r===b.Ui||(b.I?0:$CLJS.Wa(VB,b)):$CLJS.Wa(VB,b))d=YB(a,"#atom ",$CLJS.KB),e=$CLJS.q(b),a=d,b=e;else if($CLJS.Yh(b))d=YB(a,"#uuid ",$CLJS.KB),e=$CLJS.p.h(b),a=d,b=
e;else if($CLJS.Ua(b))d=YB(a,"#js ",$CLJS.KB),e=$CLJS.ab(function(f,k){return function(l,m){return $CLJS.S.j(l,$CLJS.rh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ra(b))d=YB(a,"#js ",$CLJS.KB),e=$CLJS.Wf.g($CLJS.tf,b),a=d,b=e;else return YB(a,$CLJS.Eh.l($CLJS.G([b])),$CLJS.LB)}}};$CLJS.dba=$B(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Ou);var b=$CLJS.J.g(a,$CLJS.QA),c=$CLJS.J.g(a,$CLJS.Ji);$CLJS.J.g(a,$CLJS.bB);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.eba=$B(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ou);a=$CLJS.J.g(b,$CLJS.QA);var d=$CLJS.J.g(b,$CLJS.Ji);$CLJS.J.g(b,$CLJS.bB);b=bba(c);d=aC(XB(ZB(ZB(ZB(new $CLJS.Q(null,2,5,$CLJS.R,["",$CLJS.tf],null),"[",b),a,b),"]",b)),d);a=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.ce(a,d)});
$CLJS.fba=$B(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Ou);var b=$CLJS.J.g(a,$CLJS.QA),c=$CLJS.J.g(a,$CLJS.Ji);$CLJS.J.g(a,$CLJS.bB);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Eh.l($CLJS.G([c]))],null)});