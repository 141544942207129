var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var MN,NN,ON,Uea,Vea,SN,Wea,VN,WN,XN,YN,Xea,ZN,$N,bO,cO,PN,dO,Yea,eO,Zea,gO,hO,iO,$ea,jO,kO,afa,lO,bfa,mO,nO,cfa,pO,dfa,efa,ffa,rO,sO,tO,uO,vO,wO,xO,yO,gfa,zO,AO,BO,DO,FO,hfa,GO,HO,IO,JO,ifa,KO,LO,MO,NO,jfa,OO,PO,QO,RO,kfa,SO,lfa,TO,UO,VO,mfa,WO,XO,YO,ZO,aP,bP,nfa,cP,dP,ofa,eP,fP,gP,hP,iP,pfa,jP,qfa,kP,lP,nP,rfa,oP,pP,qP,sfa,tP,uP,tfa,ufa,vP,xP,yP,zP,vfa,CP,DP,wfa,xfa,yfa,EP,FP,GP,HP,IP,JP,zfa,KP,Afa,LP,MP,OP,QP,RP,SP,TP,Bfa,VP,Cfa,Dfa,WP,XP,YP,ZP,$P,Efa,aQ,Ffa,Gfa,bQ,cQ,dQ,eQ,fQ,Hfa,
Ifa,Jfa,gQ,Kfa,hQ,Lfa,jQ,kQ,Mfa,mQ,nQ,oQ,pQ,Nfa,sQ,Ofa,Pfa,tQ,uQ,vQ,Qfa,wQ,xQ,yQ,zQ,Rfa,BQ,CQ,DQ,EQ,FQ,Sfa,GQ,HQ,Tfa,IQ,JQ,KQ,LQ,MQ,NQ,PQ,Ufa,QQ,Vfa,RQ,SQ,TQ,Wfa,Xfa,Yfa,VQ,WQ,XQ,Zfa,YQ,ZQ,$Q,aR,$fa,aga,cR,bga,cga,dR,eR,fR,gR,hR,dga,iR,jR,kR,lR,ega,mR,nR,oR,pR,rR,fga,sR,tR,gga,uR,vR,wR,hga,xR,iga,zR,jga,AR,BR,CR,DR,ER,FR,GR,kga,lga,mga,HR,nga,IR,JR,oga,pga,KR,LR,MR,qga,NR,rga,OR,sga,tga,PR,uga,vga,wga,SR,TR,xga,UR,VR,YR,yga,ZR,zga,$R,aS,bS,Aga,Bga,cS,dS,eS,fS,Cga,hS,iS,Dga,jS,kS,lS,mS,nS,oS,pS,qS,
Ega,rS,sS,Fga,tS,Gga,uS,vS,wS,Hga,xS,yS,Iga,Jga,zS,AS,BS,Kga,Lga,CS,Mga,Nga,Oga,ES,Pga,GS,Qga,Rga,HS,IS,Sga,Tga,Uga,JS,KS,LS,MS,NS,OS,PS,Vga,QS,RS,Wga,SS,Xga,Yga,Zga,TS,US,VS,$ga,WS,XS,aha,YS,bha,ZS,cha,dha,aT,bT,cT,dT,eT,eha,fT,gT,fha,hT,iT,jT,kT,lT,mT,nT,oT,gha,pT,qT,sT,tT,uT,vT,wT,xT,yT,zT,AT,BT,hha,CT,DT,ET,FT,GT,iha,HT,IT,JT,KT,jha,MT,kha,lha,OT,mha,PT,nha,QT,oha,pha,RT,qha,ST,UT,VT,rha,ZT,$T,sha,tha,aU,bU,cU,dU,eU,fU,gU,iU,jU,uha;
$CLJS.KN=function(a,b){return function(){function c(l,m,t){return $CLJS.Dd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Dd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Dd(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Dd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Ie(function(D){var I=a.h?a.h(D):a.call(null,D);return $CLJS.n(I)?
b.h?b.h(D):b.call(null,D):I},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};
$CLJS.LN=function(a){var b=$CLJS.lo.g(a,null),c=$CLJS.xH(b,$CLJS.Et,function(d){return $CLJS.kn(d,$CLJS.tf)});return function(){function d(k,l,m){return(l=$CLJS.z(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.el,b,$CLJS.vj,k,$CLJS.Lt,l],null):null}function e(k){return f.j(k,$CLJS.tf,$CLJS.tf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
MN=function(a,b){return $CLJS.wd(a)?$CLJS.J.g(a,b):a};NN=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.nn($CLJS.lo.g($CLJS.el.h(a),null));if($CLJS.n(b)){var e=MN($CLJS.vu.h(b),c);$CLJS.n(e)?(e=$CLJS.uq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:MN($CLJS.tu.h(b),c)}return null};ON=function(a,b){return $CLJS.ud(a)||$CLJS.xl(a)?$CLJS.J.g(a,b):$CLJS.vd(a)?$CLJS.J.g($CLJS.uf(a),b):null};
Uea=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.el),d=$CLJS.J.g(a,$CLJS.ej);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Lt,$CLJS.Qba),f=$CLJS.J.j(b,$CLJS.Lba,!0),k=$CLJS.J.g(b,$CLJS.qE),l=$CLJS.J.j(b,$CLJS.oE,$CLJS.mE),m=NN(a,$CLJS.Gq.h(c),k,b);if($CLJS.n(m))return m;m=NN(a,$CLJS.$t.h(c),k,b);if($CLJS.n(m))return m;m=NN(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=NN(a,function(){var t=$CLJS.Sn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=NN(a,$CLJS.Gq.h(c),
l,b);if($CLJS.n(m))return m;m=NN(a,$CLJS.$t.h(c),l,b);if($CLJS.n(m))return m;d=NN(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=NN(a,function(){var t=$CLJS.Sn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?NN(a,e.h?e.h($CLJS.kE):e.call(null,$CLJS.kE),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?NN(a,e.h?e.h($CLJS.kE):e.call(null,$CLJS.kE),l,b):f};
Vea=function(a,b,c){a=$CLJS.R;var d=$CLJS.Wf.g;var e=$CLJS.bl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.el),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.qE);l=$CLJS.J.j(l,$CLJS.oE,$CLJS.mE);k=$CLJS.Gq.h(k);f=MN($CLJS.nE.h(k),f);f=$CLJS.n(f)?f:MN($CLJS.nE.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.Q(null,2,5,a,[d,Uea(b,c)],null)};
$CLJS.QN=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.vj);a=$CLJS.J.g(b,$CLJS.Lt);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.Mba,$CLJS.Ji),f=$CLJS.J.j(d,$CLJS.Oba,Vea);return $CLJS.n(a)?$CLJS.ab(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);l=$CLJS.S.j(l,$CLJS.Ji,m);l=e.h?e.h(l):e.call(null,l);return PN(k,c,t,l)},null,a):null};
SN=function(a,b,c){var d=$CLJS.QC($CLJS.q($CLJS.oG),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Gh.v($CLJS.oG,$CLJS.RN,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null),c);return c};$CLJS.TN=function(a){return SN($CLJS.mk,a,function(){return $CLJS.yH.h(a)})};Wea=function(){var a=UN;return SN($CLJS.Et,a,function(){var b=$CLJS.yH.h(a),c=$CLJS.LN(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
VN=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,$CLJS.ke(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,a],null):a],null)};WN=function(a){if($CLJS.yd(a)){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,VN(c)],null)],null);case "rest":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,VN(c)],null);default:return VN(a)}}else return VN(a)};
XN=function(a,b){var c=$CLJS.yd(b);return c?(c=$CLJS.B(b)instanceof $CLJS.M)?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c:c};
YN=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Me(XN,a)],null),$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ws,new $CLJS.Q(null,2,5,$CLJS.R,["tag",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,a],null)],null)],null),function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.Q(null,2,5,$CLJS.R,[v,WN(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[m,WN(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}($CLJS.cu(2,2,b))}())],null)};Xea=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.M?$CLJS.B(a):null};
ZN=function(a){return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Hi,Xea,$CLJS.tu,["valid instance of one of these MBQL clauses: ",$CLJS.Eu(", ",$CLJS.Ze.g($CLJS.B,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,
$CLJS.ke(t)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.ke(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,f],null):f],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};
$N=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JN],null)],null)};$CLJS.aO={};bO={};cO={};$CLJS.RN=function RN(a,b,c){var e=$CLJS.z(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.S.j;var k=$CLJS.J.g(a,b);c=RN.j?RN.j(k,f,c):RN.call(null,k,f,c);a=e.call($CLJS.S,a,b,c)}else a=$CLJS.S.j(a,b,c);return a};
PN=function PN(a,b,c,d){var f=$CLJS.z(c);c=$CLJS.B(f);var k=$CLJS.C(f),l=ON(b,c);f=$CLJS.n(a)?a:$CLJS.vd(b)?$CLJS.tf:$CLJS.xd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.lE.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=ON(f,c),d=PN.v?PN.v(b,l,k,d):PN.call(null,b,l,k,d),$CLJS.Cl(c)&&$CLJS.vd(f)&&c>$CLJS.E(f)&&(b=$CLJS.Ve(c-$CLJS.E(f),null),b=$CLJS.Ye.g(f,b),f=null==f||$CLJS.Cd(f)?b:$CLJS.Wf.g($CLJS.jd(f),b)),null==f||$CLJS.xl(f)?$CLJS.S.j(f,c,d):$CLJS.ud(f)?$CLJS.$d.g(f,d):$CLJS.P.g($CLJS.W,$CLJS.S.j($CLJS.uf(f),
c,d))):$CLJS.wd(a)?(c=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Nba],null),PN.v?PN.v(f,b,c,d):PN.call(null,f,b,c,d)):$CLJS.n($CLJS.lE.h($CLJS.pd(f)))?$CLJS.$d.g(f,d):$CLJS.yd($CLJS.De(f))?f:$CLJS.od(new $CLJS.Q(null,1,5,$CLJS.R,[d],null),new $CLJS.h(null,1,[$CLJS.lE,!0],null))};dO=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Yea=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
eO=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.fO=new $CLJS.M(null,"dimension","dimension",543254198);Zea=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);gO=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);hO=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
iO=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);$ea=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);jO=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);kO=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);afa=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
lO=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);bfa=new $CLJS.M(null,"from","from",1815293044);mO=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);nO=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);cfa=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.oO=new $CLJS.M(null,"snippet-name","snippet-name",819240328);
pO=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);dfa=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);efa=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.qO=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);ffa=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);
rO=new $CLJS.M("location","zip_code","location/zip_code",1641155222);sO=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);tO=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);uO=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);vO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);wO=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
xO=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);yO=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);gfa=new $CLJS.M(null,"lon-max","lon-max",1590224717);zO=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);AO=new $CLJS.w(null,"stddev","stddev",775056588,null);BO=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.CO=new $CLJS.M(null,"snippet","snippet",953581994);DO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.EO=new $CLJS.M(null,"query","query",-1288509510);FO=new $CLJS.M(null,"string-expression","string-expression",-1395337766);hfa=new $CLJS.M(null,"lat-field","lat-field",-830652957);GO=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);HO=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
IO=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);JO=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);ifa=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);KO=new $CLJS.M("location","country","location/country",1666636202);LO=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
MO=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);NO=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);jfa=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);OO=new $CLJS.M(null,"unary","unary",-989314568);PO=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
QO=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);RO=new $CLJS.w(null,"ceil","ceil",-184398425,null);kfa=new $CLJS.M(null,"lon-min","lon-min",-787291357);SO=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);lfa=new $CLJS.M(null,"match","match",1220059550);TO=new $CLJS.w(null,"count-where","count-where",2025939247,null);UO=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
VO=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);mfa=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);WO=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);XO=new $CLJS.w(null,"sum","sum",1777518341,null);YO=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);ZO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.$O=new $CLJS.M("date","range","date/range",1647265776);aP=new $CLJS.w(null,"between","between",-1523336493,null);bP=new $CLJS.M(null,"clause-form","clause-form",1820463737);nfa=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);cP=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);dP=new $CLJS.w(null,"field","field",338095027,null);ofa=new $CLJS.M(null,"segment-id","segment-id",1810133590);
eP=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);fP=new $CLJS.w(null,"not-null","not-null",313812992,null);gP=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);hP=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);iP=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);pfa=new $CLJS.M(null,"template-tag","template-tag",310841038);
jP=new $CLJS.M(null,"invalid","invalid",412869516);qfa=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);kP=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);lP=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.mP=new $CLJS.M(null,"context","context",-830191113);nP=new $CLJS.w(null,"get-year","get-year",704520253,null);
rfa=new $CLJS.M(null,"format-rows?","format-rows?",992129486);oP=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);pP=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);qP=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.rP=new $CLJS.M(null,"parameters","parameters",-1229919748);sfa=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.sP=new $CLJS.M(null,"card","card",-1430355152);tP=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);uP=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);tfa=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);ufa=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);vP=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.wP=new $CLJS.M("date","month-year","date/month-year",1948031290);
xP=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);yP=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);zP=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.AP=new $CLJS.M(null,"joins","joins",1033962699);vfa=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.BP=new $CLJS.M(null,"source-field","source-field",933829534);CP=new $CLJS.w(null,"Field","Field",430385967,null);
DP=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);wfa=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);xfa=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);yfa=new $CLJS.M(null,"items","items",1031954938);EP=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);FP=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
GP=new $CLJS.M(null,"more","more",-2058821800);HP=new $CLJS.M(null,"first-clause","first-clause",-20953491);IP=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);JP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);zfa=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);KP=new $CLJS.w(null,"contains","contains",-1977535957,null);
Afa=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);LP=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);MP=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.NP=new $CLJS.M(null,"widget-type","widget-type",1836256899);OP=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.PP=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
QP=new $CLJS.w(null,"is-null","is-null",-356519403,null);RP=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);SP=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);TP=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Bfa=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.UP=new $CLJS.M(null,"required","required",1807647006);VP=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
Cfa=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Dfa=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);WP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);XP=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);YP=new $CLJS.M("string","contains","string/contains",1602423827);ZP=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);$P=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
Efa=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);aQ=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);Ffa=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);Gfa=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);bQ=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
cQ=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);dQ=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);eQ=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);fQ=new $CLJS.w(null,"share","share",1051097594,null);Hfa=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
Ifa=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Jfa=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);gQ=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Kfa=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);hQ=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
Lfa=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.iQ=new $CLJS.M(null,"collection","collection",-683361892);jQ=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);kQ=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.lQ=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
Mfa=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);mQ=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);nQ=new $CLJS.w(null,"metric","metric",2049329604,null);oQ=new $CLJS.w(null,"concat","concat",-467652465,null);pQ=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.qQ=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);Nfa=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.rQ=new $CLJS.M("date","relative","date/relative",25987732);sQ=new $CLJS.M("location","city","location/city",-1746973325);Ofa=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Pfa=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);tQ=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);uQ=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);vQ=new $CLJS.M("number","between","number/between",97700581);
Qfa=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);wQ=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);xQ=new $CLJS.w(null,"sqrt","sqrt",370479598,null);yQ=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);zQ=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.AQ=new $CLJS.M(null,"semantic_type","semantic_type",272485089);
Rfa=new $CLJS.M(null,"metric-id","metric-id",-686486942);BQ=new $CLJS.w(null,"*","*",345799209,null);CQ=new $CLJS.w(null,"+","+",-740910886,null);DQ=new $CLJS.w(null,"-","-",-471816912,null);EQ=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);FQ=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Sfa=new $CLJS.M(null,"question","question",-1411720117);GQ=new $CLJS.w(null,"asc","asc",1997386096,null);
HQ=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Tfa=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);IQ=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);JQ=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);KQ=new $CLJS.w(null,"\x3c","\x3c",993667236,null);LQ=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);MQ=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);
NQ=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.OQ=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);PQ=new $CLJS.M("string","ends-with","string/ends-with",302681156);Ufa=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);QQ=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Vfa=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);
RQ=new $CLJS.w(null,"and","and",668631710,null);SQ=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);TQ=new $CLJS.w(null,"round","round",-645002441,null);Wfa=new $CLJS.M(null,"to","to",192099007);$CLJS.UQ=new $CLJS.M("date","single","date/single",1554682003);Xfa=new $CLJS.M(null,"action-id","action-id",-1727958578);Yfa=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);VQ=new $CLJS.w(null,"exp","exp",1378825265,null);
WQ=new $CLJS.w(null,"Filter","Filter",-424893332,null);XQ=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Zfa=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);YQ=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);ZQ=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);$Q=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
aR=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.bR=new $CLJS.M(null,"source-table","source-table",-225307692);$fa=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);aga=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);cR=new $CLJS.w(null,"floor","floor",-772394748,null);bga=new $CLJS.M(null,"middleware","middleware",1462115504);
cga=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);dR=new $CLJS.M(null,"requires-features","requires-features",-101116256);eR=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);fR=new $CLJS.M(null,"clause-name","clause-name",-996419059);gR=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);hR=new $CLJS.w(null,"now","now",-9994004,null);
dga=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);iR=new $CLJS.w(null,"not","not",1044554643,null);jR=new $CLJS.w(null,"avg","avg",1837937727,null);kR=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);lR=new $CLJS.M(null,"max-results","max-results",-32858165);ega=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);mR=new $CLJS.w(null,"case","case",-1510733573,null);
nR=new $CLJS.w(null,"distinct","distinct",-148347594,null);oR=new $CLJS.w(null,"get-second","get-second",-425414791,null);pR=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.qR=new $CLJS.M(null,"join-alias","join-alias",1454206794);rR=new $CLJS.w(null,"is-empty","is-empty",600228619,null);fga=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
sR=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);tR=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);gga=new $CLJS.M(null,"original","original",-445386197);uR=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);vR=new $CLJS.w(null,"abs","abs",1394505050,null);wR=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);hga=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
xR=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);iga=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.yR=new $CLJS.M(null,"date","date",-1463434462);zR=new $CLJS.M(null,"second-clause","second-clause",-461435645);jga=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);AR=new $CLJS.w(null,"rtrim","rtrim",979195078,null);
BR=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);CR=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);DR=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);ER=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);FR=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);GR=new $CLJS.w(null,"or","or",1876275696,null);
kga=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);lga=new $CLJS.M(null,"constraints","constraints",422775616);mga=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);HR=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);nga=new $CLJS.M(null,"csv-download","csv-download",2141432084);IR=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
JR=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);oga=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);pga=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);KR=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);LR=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
MR=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);qga=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);NR=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);rga=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);OR=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);sga=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
tga=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);PR=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);uga=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);vga=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);wga=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.QR=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.RR=new $CLJS.M(null,"database","database",1849087575);SR=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);TR=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);xga=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);UR=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
VR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.WR=new $CLJS.M(null,"expressions","expressions",255689909);YR=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);yga=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);ZR=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
zga=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$R=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);aS=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);bS=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);Aga=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);Bga=new $CLJS.M(null,"action","action",-811238024);
cS=new $CLJS.w(null,"get-day","get-day",1768100384,null);dS=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);eS=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);fS=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.gS=new $CLJS.M(null,"native","native",-613060878);Cga=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);hS=new $CLJS.M(null,"page","page",849072397);
iS=new $CLJS.w(null,"length","length",-2065447907,null);Dga=new $CLJS.M(null,"dashboard","dashboard",-631747508);jS=new $CLJS.w(null,"get-week","get-week",752472178,null);kS=new $CLJS.w(null,"get-month","get-month",1271156796,null);lS=new $CLJS.w(null,"dimension","dimension",-2111181571,null);mS=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);nS=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
oS=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);pS=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);qS=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Ega=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);rS=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);sS=new $CLJS.w(null,"substring","substring",-1513569493,null);
Fga=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);tS=new $CLJS.M(null,"internal","internal",-854870097);Gga=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);uS=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);vS=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
wS=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);Hga=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);xS=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);yS=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);Iga=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
Jga=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);zS=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);AS=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);BS=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Kga=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Lga=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);CS=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.DS=new $CLJS.M(null,"template-tags","template-tags",1853115685);Mga=new $CLJS.M(null,"public-question","public-question",629369976);Nga=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Oga=new $CLJS.M(null,"binary","binary",-1802232288);ES=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Pga=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.FS=new $CLJS.M(null,"source-query","source-query",198004422);GS=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Qga=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
Rga=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);HS=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);IS=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Sga=new $CLJS.M(null,"executed-by","executed-by",-739811161);Tga=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Uga=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);JS=new $CLJS.M(null,"amount","amount",364489504);KS=new $CLJS.w(null,"percentile","percentile",1039342775,null);LS=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);MS=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);NS=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
OS=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);PS=new $CLJS.w(null,"trim","trim",-1880116002,null);Vga=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);QS=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);RS=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Wga=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
SS=new $CLJS.M("string","\x3d","string/\x3d",983744235);Xga=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Yga=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Zga=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);TS=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);US=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
VS=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);$ga=new $CLJS.M(null,"lat-min","lat-min",1630784161);WS=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);XS=new $CLJS.w(null,"inside","inside",-681932758,null);aha=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);YS=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
bha=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);ZS=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);cha=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);dha=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.$S=new $CLJS.M(null,"card-id","card-id",-1770060179);aT=new $CLJS.M(null,"variadic","variadic",882626057);
bT=new $CLJS.w(null,"upper","upper",1886775433,null);cT=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);dT=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);eT=new $CLJS.w(null,"optional","optional",-600484260,null);eha=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);fT=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
gT=new $CLJS.M(null,"sugar","sugar",-73788488);fha=new $CLJS.M(null,"lat-max","lat-max",841568226);hT=new $CLJS.w(null,"power","power",702679448,null);iT=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);jT=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);kT=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
lT=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);mT=new $CLJS.w(null,"median","median",-2084869638,null);nT=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);oT=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);gha=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);pT=new $CLJS.M(null,"y","y",-1757859776);qT=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);
$CLJS.rT=new $CLJS.M(null,"binning","binning",1709835866);sT=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);tT=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);uT=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);vT=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);wT=new $CLJS.w(null,"Reference","Reference",2024574086,null);xT=new $CLJS.M(null,"b","b",1482224470);
yT=new $CLJS.M(null,"a","a",-2123407586);zT=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);AT=new $CLJS.w(null,"replace","replace",853943757,null);BT=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);hha=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);CT=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
DT=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);ET=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);FT=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);GT=new $CLJS.w(null,"segment","segment",675610331,null);iha=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);HT=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
IT=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);JT=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);KT=new $CLJS.M("string","starts-with","string/starts-with",1266861170);jha=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.LT=new $CLJS.M(null,"order-by","order-by",1527318070);MT=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
kha=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);lha=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.NT=new $CLJS.M(null,"condition","condition",1668437652);OT=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);mha=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);PT=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
nha=new $CLJS.M(null,"card-name","card-name",-2035606807);QT=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);oha=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);pha=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);RT=new $CLJS.w(null,"log","log",45015523,null);qha=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
ST=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.TT=new $CLJS.M(null,"database_type","database_type",-54700895);UT=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);VT=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.WT=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.XT=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.YT=new $CLJS.M("date","all-options","date/all-options",549325958);
rha=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);ZT=new $CLJS.M("location","state","location/state",-114378652);$T=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);sha=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);tha=new $CLJS.M(null,"lon-field","lon-field",517872067);aU=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
bU=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);cU=new $CLJS.M(null,"numeric","numeric",-1495594714);dU=new $CLJS.w(null,"variable","variable",1359185035,null);eU=new $CLJS.w(null,"lower","lower",-1534114948,null);fU=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);gU=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.hU=new $CLJS.M(null,"limit","limit",-1355822363);
iU=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);jU=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);uha=new $CLJS.M(null,"pulse","pulse",-244494476);var kU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.DG],null),lU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null),mU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.AG],null),nU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.wG],null),oU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.HG],null),pU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gN],null),vha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YM],null),wha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TM],null),qU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.DK],null),rU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.HK],null),sU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.yK],null),tU=new $CLJS.Tg(null,new $CLJS.h(null,12,[$CLJS.ii,null,$CLJS.nv,null,$CLJS.gj,null,$CLJS.Wh,null,$CLJS.zi,null,$CLJS.pj,null,$CLJS.ck,null,$CLJS.yj,null,$CLJS.ci,null,$CLJS.xj,null,$CLJS.ji,null,$CLJS.ti,null],null),null),uU=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.mv,null,$CLJS.eJ,null,$CLJS.Wh,null,$CLJS.pi,null,$CLJS.hJ,null,$CLJS.aj,null,$CLJS.lv,null],null),null),
vU=$CLJS.ov.g(tU,uU),xha=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"date bucketing unit"],null)],null),tU),yha=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"time bucketing unit"],null)],null),uU),wU=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"datetime bucketing unit"],null)],null),vU),xU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mL],null),zha=new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,
1,[$CLJS.tu,"temporal extract unit"],null),$CLJS.iJ,$CLJS.ti,$CLJS.ck,$CLJS.YK,$CLJS.sL,$CLJS.tL,$CLJS.yj,$CLJS.zi,$CLJS.pi,$CLJS.aj,$CLJS.fJ],null),Aha=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"datetime-diff unit"],null),$CLJS.eJ,$CLJS.lv,$CLJS.mv,$CLJS.nv,$CLJS.gj,$CLJS.pj,$CLJS.ii,$CLJS.ci],null),yU=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"temporal-extract week extraction mode"],null),$CLJS.SK,$CLJS.aL,$CLJS.fL],null),zU=new $CLJS.Q(null,
10,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"relative-datetime unit"],null),$CLJS.Wh,$CLJS.lv,$CLJS.mv,$CLJS.nv,$CLJS.gj,$CLJS.pj,$CLJS.ii,$CLJS.ci],null),GU,Cha,OU,PU,QU,RU,SU,TU,UU,FW,Eha,GW,Fha,Gha,HW,Hha,Iha,Jha;$CLJS.Y(QO,YN($CLJS.bL,$CLJS.G(["n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.tx],null),$CLJS.st],null),"unit",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,zU],null)])));var AU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QO],null);
$CLJS.Y(uR,YN($CLJS.$K,$CLJS.G(["n",$CLJS.st,"unit",zU])));var Bha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uR],null);
$CLJS.Y(kP,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"valid :absolute-datetime clause",$CLJS.Hi,function(a){if($CLJS.Sa(XN($CLJS.WK,a)))a=jP;else{a=$CLJS.hd(a);var b=$CLJS.TN(qU);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.yR:$CLJS.cL}return a}],null),new $CLJS.Q(null,2,5,$CLJS.R,[jP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"not an :absolute-datetime clause"],null),$CLJS.Le(!1)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yR,YN($CLJS.WK,
$CLJS.G(["date",qU,"unit",xha]))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cL,YN($CLJS.WK,$CLJS.G(["datetime",rU,"unit",wU]))],null)],null));var BU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kP],null);$CLJS.Y(zP,YN($CLJS.XA,$CLJS.G(["time",sU,"unit",yha])));var CU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zP],null),DU=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.tu,"date or datetime literal"],null),BU,rU,qU],null);
$CLJS.Y(jO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.tu,"time literal"],null),CU,sU],null));$CLJS.Y(aU,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.tu,"temporal literal"],null),DU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jO],null)],null));var EU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aU],null);
$CLJS.Y(Wga,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof BU?new $CLJS.Fc(function(){return BU},$CLJS.od(wfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,tS,$CLJS.fj,$CLJS.nk,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bS,"metabase/mbql/schema.cljc",69,$CLJS.WK,1,!0,168,168,$CLJS.Jc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:bS}(),BU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof AU?new $CLJS.Fc(function(){return AU},$CLJS.od(rha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,
$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",29,$CLJS.bL,1,131,131,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bL,$CLJS.Ky,$CLJS.W($CLJS.Ij,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.tx],null),$CLJS.st],null)),$CLJS.li,$CLJS.W($CLJS.Ij,$CLJS.W(eT,QQ))],null),$CLJS.Jc,"Schema for a valid relative-datetime clause.",
$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:sR}(),AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof CU?new $CLJS.Fc(function(){return CU},$CLJS.od(tga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,tS,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ST,"metabase/mbql/schema.cljc",27,$CLJS.XA,1,!0,175,175,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.XA,$CLJS.XA,$CLJS.W($CLJS.Ij,Vga),$CLJS.li,$CLJS.W($CLJS.Ij,Nfa)],null),$CLJS.Jc,"Schema for a valid time clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:ST}(),CU],null)])));
$CLJS.Y(QT,YN($CLJS.vj,$CLJS.G(["value",$CLJS.ei,"type-info",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TT,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,kU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sF,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,lU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AQ,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ft,mU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.li,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,wU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,kU],null)],null)],null)],null)])));var FU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QT],null);$CLJS.Y(zQ,YN($CLJS.DD,$CLJS.G(["expression-name",kU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,$CLJS.Zj],null)])));
GU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zQ],null);
Cha=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.NG);c=$CLJS.J.g(c,$CLJS.xG);return $CLJS.F.g(b,$CLJS.xG)?c:!0}],null)],null)}(function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.NG);c=$CLJS.J.g(c,$CLJS.MG);return $CLJS.F.g(b,$CLJS.MG)?c:!0}],null)],null)}(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zj,new $CLJS.h(null,1,[$CLJS.tu,"binning options"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NG,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"binning strategy"],null),$CLJS.xG,$CLJS.MG,$CLJS.Wh],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),nU],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.MG,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,$CLJS.pl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"bin width must be \x3e\x3d 0."],null),$CLJS.Ke($CLJS.Kl)],null)],null)],null)],null)));$CLJS.HU=function HU(a){switch(arguments.length){case 1:return HU.h(arguments[0]);case 2:return HU.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.HU.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.GD);b=$CLJS.J.g(b,$CLJS.yI);return $CLJS.HU.g(a,b)};$CLJS.HU.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.FE(a,$CLJS.fF)?tU:$CLJS.FE(a,$CLJS.dG)?uU:$CLJS.FE(a,$CLJS.EF)?vU:null:null;return $CLJS.n(a)?$CLJS.Gd(a,b):!0};$CLJS.HU.A=2;
$CLJS.Y(fT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Zj,new $CLJS.h(null,1,[$CLJS.tu,"field options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GD,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,lU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BP,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,pU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yI,new $CLJS.h(null,
1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,wU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qR,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,kU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rT,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,Cha],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.HU],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Ke($CLJS.NG)],null)],null));
$CLJS.Y(HT,function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.GD);return"string"===typeof c?b:!0}],null)],null)}(YN($CLJS.sI,$CLJS.G(["id-or-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,pU,kU],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fT],null)],null)]))));var IU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HT],null);
$CLJS.Y(HR,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof GU?new $CLJS.Fc(function(){return GU},$CLJS.od(LP,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vP,"metabase/mbql/schema.cljc",60,$CLJS.DD,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.DD,$CLJS.XT,$CLJS.W($CLJS.Ij,SO),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,$CLJS.Zj))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:vP}(),GU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof IU?new $CLJS.Fc(function(){return IU},
$CLJS.od(cQ,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Jr,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,$CLJS.ni,$CLJS.V,$CLJS.ek],["0.39.0",$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",51,$CLJS.sI,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:dP}(),IU],null)])));
$CLJS.JU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HR],null);$CLJS.Y(dO,YN($CLJS.hI,$CLJS.G(["aggregation-clause-index",$CLJS.st,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,$CLJS.Zj],null)])));var KU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dO],null);
$CLJS.Y(MO,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof KU?new $CLJS.Fc(function(){return KU},$CLJS.od(Tga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qT,"metabase/mbql/schema.cljc",23,$CLJS.hI,1,418,418,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.hI,sha,$CLJS.W($CLJS.Ij,$CLJS.st),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,$CLJS.Zj))],null),$CLJS.Jc,"Schema for a valid aggregation clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:qT}(),KU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof GU?new $CLJS.Fc(function(){return GU},
$CLJS.od(LP,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",60,$CLJS.DD,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.DD,$CLJS.XT,$CLJS.W($CLJS.Ij,SO),$CLJS.Yi,
$CLJS.W($CLJS.Ij,$CLJS.W(eT,$CLJS.Zj))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:vP}(),GU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof IU?new $CLJS.Fc(function(){return IU},$CLJS.od(cQ,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Jr,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,$CLJS.ni,
$CLJS.V,$CLJS.ek],["0.39.0",$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",51,$CLJS.sI,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:dP}(),IU],null)])));
var LU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MO],null),MU=new $CLJS.Tg(null,new $CLJS.h(null,11,[$CLJS.Hu,null,$CLJS.LI,null,$CLJS.MH,null,$CLJS.RI,null,$CLJS.RH,null,$CLJS.vI,null,$CLJS.aI,null,$CLJS.Pu,null,$CLJS.JI,null,$CLJS.uI,null,$CLJS.SI,null],null),null),NU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JO],null);
$CLJS.Y(lO,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Hi,function(a){return"string"===typeof a?$CLJS.Rj:$CLJS.n(XN(MU,a))?FO:$CLJS.n(XN($CLJS.vj,a))?$CLJS.vj:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rj,$CLJS.Rj],null),new $CLJS.Q(null,2,5,$CLJS.R,[FO,NU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,FU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,$CLJS.JU],null)],null));OU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lO],null);
PU=new $CLJS.Tg(null,new $CLJS.h(null,26,[$CLJS.OH,null,$CLJS.rL,null,$CLJS.Ks,null,$CLJS.nL,null,$CLJS.kL,null,$CLJS.fI,null,$CLJS.ju,null,$CLJS.LH,null,$CLJS.oL,null,$CLJS.hL,null,$CLJS.vI,null,$CLJS.OI,null,$CLJS.lL,null,$CLJS.xI,null,$CLJS.PH,null,$CLJS.UK,null,$CLJS.Js,null,$CLJS.SH,null,$CLJS.aI,null,$CLJS.OK,null,$CLJS.gA,null,$CLJS.VK,null,$CLJS.gL,null,$CLJS.ZK,null,$CLJS.QI,null,$CLJS.qI,null],null),null);
QU=new $CLJS.Tg(null,new $CLJS.h(null,9,[$CLJS.Gs,null,$CLJS.As,null,$CLJS.gt,null,$CLJS.it,null,$CLJS.Cs,null,$CLJS.KI,null,$CLJS.mt,null,$CLJS.hk,null,$CLJS.Es,null],null),null);RU=new $CLJS.Tg(null,new $CLJS.h(null,17,[$CLJS.Mn,null,$CLJS.II,null,$CLJS.BI,null,$CLJS.tI,null,$CLJS.NI,null,$CLJS.PI,null,$CLJS.XH,null,$CLJS.dI,null,$CLJS.qO,null,$CLJS.YH,null,$CLJS.GI,null,$CLJS.zI,null,$CLJS.ak,null,$CLJS.iA,null,$CLJS.mI,null,$CLJS.nI,null,$CLJS.FI,null],null),null);
SU=new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.dL,null,$CLJS.QK,null,$CLJS.pL,null,$CLJS.Js,null,$CLJS.jL,null],null),null);TU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YS],null);UU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xP],null);$CLJS.VU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zS],null);$CLJS.WU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dT],null);
$CLJS.Y(kT,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"numeric expression argument",$CLJS.Hi,function(a){return"number"===typeof a?$CLJS.cv:$CLJS.n(XN(PU,a))?aR:$CLJS.n(XN(RU,a))?$CLJS.hI:$CLJS.n(XN($CLJS.vj,a))?$CLJS.vj:$CLJS.sI}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cv,$CLJS.pl],null),new $CLJS.Q(null,2,5,$CLJS.R,[aR,TU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hI,$CLJS.WU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,FU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sI,
$CLJS.JU],null)],null));var XU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kT],null);
$CLJS.Y(uS,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"datetime expression argument",$CLJS.Hi,function(a){return $CLJS.n(XN(RU,a))?$CLJS.hI:$CLJS.n(XN($CLJS.vj,a))?$CLJS.vj:$CLJS.n(XN(SU,a))?EP:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hI,$CLJS.WU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,FU],null),new $CLJS.Q(null,2,5,$CLJS.R,[EP,$CLJS.VU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,DU,$CLJS.JU],null)],null)],null));
var YU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uS],null);
$CLJS.Y(YR,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"expression argument",$CLJS.Hi,function(a){return"number"===typeof a?$CLJS.cv:$CLJS.Bd(a)?$CLJS.wt:$CLJS.n(XN(QU,a))?mS:$CLJS.n(XN(PU,a))?aR:$CLJS.n(XN(SU,a))?EP:"string"===typeof a?$CLJS.Rj:$CLJS.n(XN(MU,a))?FO:$CLJS.n(XN($CLJS.vj,a))?$CLJS.vj:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cv,$CLJS.pl],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wt,$CLJS.wt],null),new $CLJS.Q(null,2,5,$CLJS.R,[mS,UU],null),new $CLJS.Q(null,
2,5,$CLJS.R,[aR,TU],null),new $CLJS.Q(null,2,5,$CLJS.R,[EP,$CLJS.VU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rj,$CLJS.Rj],null),new $CLJS.Q(null,2,5,$CLJS.R,[FO,NU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,FU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,$CLJS.JU],null)],null));var ZU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YR],null);$CLJS.Y(VR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.tu,"numeric expression arg or interval"],null),Bha,XU],null));
var $U=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VR],null);$CLJS.Y(wO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"int greater than zero or numeric expression",$CLJS.Hi,function(a){return"number"===typeof a?$CLJS.cv:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cv,nU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,TU],null)],null));var Dha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wO],null);
$CLJS.Y(FP,YN($CLJS.vI,$CLJS.G(["a",ZU,"b",ZU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,ZU],null)])));var aV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FP],null);$CLJS.Y(iO,YN($CLJS.MH,$CLJS.G(["s",OU,"start",Dha,"length",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,XU],null)])));var bV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iO],null);$CLJS.Y(nS,YN($CLJS.gA,$CLJS.G(["s",OU])));var cV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nS],null);$CLJS.Y(LO,YN($CLJS.JI,$CLJS.G(["s",OU])));
var dV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LO],null);$CLJS.Y(PO,YN($CLJS.SI,$CLJS.G(["s",OU])));var eV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PO],null);$CLJS.Y(hP,YN($CLJS.LI,$CLJS.G(["s",OU])));var fV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hP],null);$CLJS.Y(TP,YN($CLJS.Hu,$CLJS.G(["s",OU])));var gV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TP],null);$CLJS.Y(BO,YN($CLJS.Pu,$CLJS.G(["s",OU])));var hV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BO],null);
$CLJS.Y(jT,YN($CLJS.RI,$CLJS.G(["s",OU,"match",$CLJS.Rj,"replacement",$CLJS.Rj])));var iV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jT],null);$CLJS.Y(fU,YN($CLJS.RH,$CLJS.G(["a",OU,"b",OU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,OU],null)])));var jV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fU],null);$CLJS.Y(IQ,YN($CLJS.uI,$CLJS.G(["s",OU,"pattern",$CLJS.Rj])));var kV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IQ],null);
$CLJS.Y(FT,YN($CLJS.Js,$CLJS.G(["x",$U,"y",$U,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,$U],null)])));var lV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FT],null);$CLJS.Y(DT,YN($CLJS.ju,$CLJS.G(["x",XU,"y",$U,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,$U],null)])));var mV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DT],null);$CLJS.Y(CT,YN($CLJS.OI,$CLJS.G(["x",XU,"y",XU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,XU],null)])));var nV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CT],null);
$CLJS.Y(ET,YN($CLJS.Ks,$CLJS.G(["x",XU,"y",XU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,XU],null)])));var oV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ET],null);$CLJS.Y(UR,YN($CLJS.qI,$CLJS.G(["x",XU])));var pV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UR],null);$CLJS.Y(NS,YN($CLJS.OH,$CLJS.G(["x",XU])));var qV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NS],null);$CLJS.Y(iU,YN($CLJS.xI,$CLJS.G(["x",XU])));var rV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iU],null);$CLJS.Y(ZQ,YN($CLJS.SH,$CLJS.G(["x",XU])));
var sV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZQ],null);$CLJS.Y(eR,YN($CLJS.PH,$CLJS.G(["x",XU,"y",XU])));var tV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eR],null);$CLJS.Y(BR,YN($CLJS.fI,$CLJS.G(["x",XU])));var uV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BR],null);$CLJS.Y(xO,YN($CLJS.LH,$CLJS.G(["x",XU])));var vV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xO],null);$CLJS.Y(aS,YN($CLJS.QI,$CLJS.G(["x",XU])));var wV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aS],null);
$CLJS.Y(YO,YN($CLJS.ZK,$CLJS.G(["datetime-x",YU,"datetime-y",YU,"unit",Aha])));var xV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YO],null);$CLJS.Y(pP,YN($CLJS.nL,$CLJS.G(["datetime",YU,"unit",zha,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,yU],null)])));var yV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pP],null);$CLJS.Y(gR,YN($CLJS.VK,$CLJS.G(["date",YU])));var zV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gR],null);$CLJS.Y(IR,YN($CLJS.rL,$CLJS.G(["date",YU])));
var AV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IR],null);$CLJS.Y(kO,YN($CLJS.OK,$CLJS.G(["date",YU])));var BV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kO],null);$CLJS.Y(gU,YN($CLJS.hL,$CLJS.G(["date",YU,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,yU],null)])));var CV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gU],null);$CLJS.Y(tQ,YN($CLJS.gL,$CLJS.G(["date",YU])));var DV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tQ],null);$CLJS.Y(CR,YN($CLJS.lL,$CLJS.G(["date",YU])));
var EV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CR],null);$CLJS.Y(UO,YN($CLJS.oL,$CLJS.G(["datetime",YU])));var FV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UO],null);$CLJS.Y(yQ,YN($CLJS.kL,$CLJS.G(["datetime",YU])));var GV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,yQ],null);$CLJS.Y(NO,YN($CLJS.UK,$CLJS.G(["datetime",YU])));var HV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NO],null);$CLJS.Y(dQ,YN($CLJS.QK,$CLJS.G(["datetime",YU,"to",xU,"from",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,xU],null)])));
var IV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dQ],null),JV=new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"datetime arithmetic unit"],null),$CLJS.hJ,$CLJS.eJ,$CLJS.lv,$CLJS.mv,$CLJS.nv,$CLJS.gj,$CLJS.pj,$CLJS.ii,$CLJS.ci],null);$CLJS.Y(hO,YN($CLJS.jL,$CLJS.G(["datetime",YU,"amount",XU,"unit",JV])));var KV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hO],null);
$CLJS.Y(GO,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return YN(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.dL));var LV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GO],null);$CLJS.Y(gO,YN($CLJS.pL,$CLJS.G(["datetime",YU,"amount",XU,"unit",JV])));var MV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gO],null);
$CLJS.Y(zS,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof lV?new $CLJS.Fc(function(){return lV},$CLJS.od(OR,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CQ,"metabase/mbql/schema.cljc",51,$CLJS.Js,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Js,$CLJS.KG,$CLJS.W($CLJS.Ij,SR),pT,$CLJS.W($CLJS.Ij,SR),GP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,SR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(lV)?lV.H:null])):null));return $CLJS.n(a)?a:CQ}(),lV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof KV?new $CLJS.Fc(function(){return KV},
$CLJS.od(dga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[XP,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JR,"metabase/mbql/schema.cljc",67,$CLJS.jL,1,703,703,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.jL,$CLJS.cL,$CLJS.W($CLJS.Ij,bU),JS,$CLJS.W($CLJS.Ij,
OS),$CLJS.li,$CLJS.W($CLJS.Ij,WS)],null),$CLJS.Jc,"Schema for a valid datetime-add clause.",$CLJS.n(KV)?KV.H:null])):null));return $CLJS.n(a)?a:JR}(),KV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof MV?new $CLJS.Fc(function(){return MV},$CLJS.od(Yea,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,
$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[XP,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$T,"metabase/mbql/schema.cljc",72,$CLJS.pL,1,710,710,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.pL,$CLJS.cL,$CLJS.W($CLJS.Ij,bU),JS,$CLJS.W($CLJS.Ij,OS),$CLJS.li,$CLJS.W($CLJS.Ij,WS)],null),$CLJS.Jc,"Schema for a valid datetime-subtract clause.",$CLJS.n(MV)?MV.H:null])):null));
return $CLJS.n(a)?a:$T}(),MV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof IV?new $CLJS.Fc(function(){return IV},$CLJS.od(bha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.QK,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",71,$CLJS.QK,1,695,695,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.QK,$CLJS.cL,$CLJS.W($CLJS.Ij,bU),Wfa,$CLJS.W($CLJS.Ij,sT),bfa,$CLJS.W($CLJS.Ij,$CLJS.W(eT,sT))],null),$CLJS.Jc,"Schema for a valid convert-timezone clause.",$CLJS.n(IV)?IV.H:null])):null));return $CLJS.n(a)?a:DP}(),IV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&
"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof LV?new $CLJS.Fc(function(){return LV},$CLJS.od(Ega,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.dL,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",
45,$CLJS.dL,1,708,708,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.dL],null),$CLJS.Jc,"Schema for a valid now clause.",$CLJS.n(LV)?LV.H:null])):null));return $CLJS.n(a)?a:hR}(),LV],null)])));$CLJS.NV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ER],null);$CLJS.Y(mO,YN($CLJS.mt,$CLJS.G(["first-clause",$CLJS.NV,"second-clause",$CLJS.NV,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,$CLJS.NV],null)])));var OV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mO],null);
$CLJS.Y($P,YN($CLJS.gt,$CLJS.G(["first-clause",$CLJS.NV,"second-clause",$CLJS.NV,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,$CLJS.NV],null)])));var PV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$P],null);$CLJS.Y(IO,YN($CLJS.it,$CLJS.G(["clause",$CLJS.NV])));
var QV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IO],null),RV=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,3,[$CLJS.tu,":field or :expression reference or :relative-datetime",$CLJS.vu,$CLJS.Le(":field or :expression reference or :relative-datetime"),$CLJS.Hi,function(a){return $CLJS.n(XN($CLJS.bL,a))?$CLJS.bL:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bL,AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,$CLJS.JU],null)],null);
$CLJS.Y(wS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.h(null,1,[$CLJS.tu,"equality comparable"],null),new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.gt,$CLJS.wt,$CLJS.pl,$CLJS.Rj,EU,RV,ZU,FU],null)],null));var SV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wS],null);
$CLJS.Y(NQ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"order comparable",$CLJS.Hi,function(a){return $CLJS.n(XN($CLJS.vj,a))?$CLJS.vj:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,FU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.gt,$CLJS.pl,$CLJS.Rj,EU,ZU,RV],null)],null)],null));var TV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NQ],null);
$CLJS.Y(vT,YN($CLJS.hk,$CLJS.G(["field",SV,"value-or-field",SV,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,SV],null)])));var UV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vT],null);$CLJS.Y(zT,YN($CLJS.KI,$CLJS.G(["field",SV,"value-or-field",SV,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ii,SV],null)])));var VV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zT],null);$CLJS.Y(uT,YN($CLJS.Es,$CLJS.G(["field",TV,"value-or-field",TV])));
var WV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uT],null);$CLJS.Y(tT,YN($CLJS.As,$CLJS.G(["field",TV,"value-or-field",TV])));var XV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tT],null);$CLJS.Y(iP,YN($CLJS.Gs,$CLJS.G(["field",TV,"value-or-field",TV])));var YV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iP],null);$CLJS.Y(oP,YN($CLJS.Cs,$CLJS.G(["field",TV,"value-or-field",TV])));var ZV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oP],null);$CLJS.Y(eS,YN($CLJS.UH,$CLJS.G(["field",TV,"min",TV,"max",TV])));
var $V=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eS],null);$CLJS.Y(GS,YN($CLJS.$H,$CLJS.G(["lat-field",TV,"lon-field",TV,"lat-max",TV,"lon-min",TV,"lat-min",TV,"lon-max",TV])));var aW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GS],null);$CLJS.Y(SP,YN($CLJS.TH,$CLJS.G(["field",$CLJS.JU])));var bW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SP],null);$CLJS.Y(HQ,YN($CLJS.eI,$CLJS.G(["field",$CLJS.JU])));var cW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HQ],null);$CLJS.Y(jQ,YN($CLJS.VH,$CLJS.G(["field",$CLJS.JU])));
var dW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jQ],null);$CLJS.Y(pO,YN($CLJS.bI,$CLJS.G(["field",$CLJS.JU])));var eW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pO],null),fW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AI,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null)],null);$CLJS.Y(wQ,YN($CLJS.jI,$CLJS.G(["field",OU,"string-or-field",OU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,fW],null)])));var gW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wQ],null);
$CLJS.Y(pR,YN($CLJS.DI,$CLJS.G(["field",OU,"string-or-field",OU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,fW],null)])));var hW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pR],null);$CLJS.Y(hQ,YN($CLJS.rI,$CLJS.G(["field",OU,"string-or-field",OU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,fW],null)])));var iW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hQ],null);$CLJS.Y(ZR,YN($CLJS.kI,$CLJS.G(["field",OU,"string-or-field",OU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,fW],null)])));
var jW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZR],null);$CLJS.Y(yO,YN($CLJS.EI,$CLJS.G(["field",$CLJS.JU,"n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.st,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.tx,$CLJS.mA,$CLJS.ZH],null)],null),"unit",zU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HI,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null)],null)],null)])));var kW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,yO],null);
$CLJS.Y(pS,YN($CLJS.QH,$CLJS.G(["segment-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.RM],null),kU],null)])));var lW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pS],null);
$CLJS.Y(xP,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof OV?new $CLJS.Fc(function(){return OV},$CLJS.od(iT,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RQ,"metabase/mbql/schema.cljc",15,$CLJS.mt,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.mt,HP,$CLJS.W($CLJS.Ij,WQ),zR,$CLJS.W($CLJS.Ij,WQ),RP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,WQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(OV)?OV.H:null])):null));return $CLJS.n(a)?a:RQ}(),OV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof PV?new $CLJS.Fc(function(){return PV},
$CLJS.od(DR,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GR,"metabase/mbql/schema.cljc",14,$CLJS.gt,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.gt,HP,$CLJS.W($CLJS.Ij,WQ),zR,$CLJS.W($CLJS.Ij,WQ),RP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,WQ))],null),$CLJS.Jc,
"Schema for a valid or clause.",$CLJS.n(PV)?PV.H:null])):null));return $CLJS.n(a)?a:GR}(),PV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof QV?new $CLJS.Fc(function(){return QV},$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iR,"metabase/mbql/schema.cljc",15,$CLJS.it,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,$CLJS.WT,$CLJS.W($CLJS.Ij,WQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(QV)?QV.H:null])):null));return $CLJS.n(a)?a:iR}(),QV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof WV?new $CLJS.Fc(function(){return WV},$CLJS.od(MR,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,
$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",13,$CLJS.Es,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Es,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),US,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",$CLJS.n(WV)?WV.H:null])):null));return $CLJS.n(a)?a:KQ}(),WV],null),
new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof YV?new $CLJS.Fc(function(){return YV},$CLJS.od(PT,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JQ,"metabase/mbql/schema.cljc",14,$CLJS.Gs,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Gs,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),US,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(YV)?YV.H:null])):null));return $CLJS.n(a)?a:JQ}(),YV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof XV?new $CLJS.Fc(function(){return XV},$CLJS.od(KR,new $CLJS.h(null,
1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LQ,"metabase/mbql/schema.cljc",13,$CLJS.As,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.As,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),US,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(XV)?XV.H:null])):null));
return $CLJS.n(a)?a:LQ}(),XV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof ZV?new $CLJS.Fc(function(){return ZV},$CLJS.od(IT,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SQ,"metabase/mbql/schema.cljc",14,$CLJS.Cs,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Cs,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),US,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(ZV)?ZV.H:null])):null));return $CLJS.n(a)?a:SQ}(),ZV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof UV?new $CLJS.Fc(function(){return UV},$CLJS.od(LR,new $CLJS.h(null,
1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",13,$CLJS.hk,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.hk,$CLJS.sI,$CLJS.W($CLJS.Ij,UT),US,$CLJS.W($CLJS.Ij,UT),xS,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,UT))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",
$CLJS.n(UV)?UV.H:null])):null));return $CLJS.n(a)?a:MQ}(),UV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof VV?new $CLJS.Fc(function(){return VV},$CLJS.od(ZO,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uP,"metabase/mbql/schema.cljc",14,$CLJS.KI,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.KI,$CLJS.sI,$CLJS.W($CLJS.Ij,UT),US,$CLJS.W($CLJS.Ij,UT),xS,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,UT))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(VV)?VV.H:null])):null));return $CLJS.n(a)?a:uP}(),VV],null)])));
$CLJS.Y(ER,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"valid filter expression",$CLJS.Hi,function(a){return $CLJS.n(XN(SU,a))?$CLJS.cL:$CLJS.n(XN(PU,a))?cU:$CLJS.n(XN(MU,a))?$CLJS.Rj:$CLJS.n(XN(QU,a))?$CLJS.wt:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cL,$CLJS.VU],null),new $CLJS.Q(null,2,5,$CLJS.R,[cU,TU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rj,NU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wt,UU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,ZN($CLJS.G([new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof OV?new $CLJS.Fc(function(){return OV},$CLJS.od(iT,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",
15,$CLJS.mt,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.mt,HP,$CLJS.W($CLJS.Ij,WQ),zR,$CLJS.W($CLJS.Ij,WQ),RP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,WQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(OV)?OV.H:null])):null));return $CLJS.n(a)?a:RQ}(),OV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof PV?new $CLJS.Fc(function(){return PV},$CLJS.od(DR,new $CLJS.h(null,1,[$CLJS.Tj,
!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GR,"metabase/mbql/schema.cljc",14,$CLJS.gt,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.gt,HP,$CLJS.W($CLJS.Ij,WQ),zR,$CLJS.W($CLJS.Ij,WQ),RP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,WQ))],null),$CLJS.Jc,"Schema for a valid or clause.",$CLJS.n(PV)?
PV.H:null])):null));return $CLJS.n(a)?a:GR}(),PV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof QV?new $CLJS.Fc(function(){return QV},$CLJS.od(qS,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iR,"metabase/mbql/schema.cljc",15,$CLJS.it,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,$CLJS.WT,$CLJS.W($CLJS.Ij,WQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(QV)?QV.H:null])):null));return $CLJS.n(a)?a:iR}(),QV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof UV?new $CLJS.Fc(function(){return UV},$CLJS.od(LR,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,
$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",13,$CLJS.hk,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.hk,$CLJS.sI,$CLJS.W($CLJS.Ij,UT),US,$CLJS.W($CLJS.Ij,UT),xS,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,UT))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",$CLJS.n(UV)?UV.H:null])):null));
return $CLJS.n(a)?a:MQ}(),UV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof VV?new $CLJS.Fc(function(){return VV},$CLJS.od(ZO,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uP,"metabase/mbql/schema.cljc",14,$CLJS.KI,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.KI,$CLJS.sI,$CLJS.W($CLJS.Ij,UT),US,$CLJS.W($CLJS.Ij,UT),xS,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,UT))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(VV)?VV.H:null])):null));return $CLJS.n(a)?a:uP}(),VV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof WV?new $CLJS.Fc(function(){return WV},
$CLJS.od(MR,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",13,$CLJS.Es,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Es,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),US,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",
$CLJS.n(WV)?WV.H:null])):null));return $CLJS.n(a)?a:KQ}(),WV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof XV?new $CLJS.Fc(function(){return XV},$CLJS.od(KR,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LQ,"metabase/mbql/schema.cljc",13,$CLJS.As,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.As,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),US,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(XV)?XV.H:null])):null));return $CLJS.n(a)?a:LQ}(),XV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof YV?new $CLJS.Fc(function(){return YV},$CLJS.od(PT,new $CLJS.h(null,
1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JQ,"metabase/mbql/schema.cljc",14,$CLJS.Gs,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Gs,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),US,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(YV)?YV.H:null])):
null));return $CLJS.n(a)?a:JQ}(),YV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof ZV?new $CLJS.Fc(function(){return ZV},$CLJS.od(IT,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SQ,"metabase/mbql/schema.cljc",14,$CLJS.Cs,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Cs,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),US,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(ZV)?ZV.H:null])):null));return $CLJS.n(a)?a:SQ}(),ZV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof $V?new $CLJS.Fc(function(){return $V},$CLJS.od(vga,new $CLJS.h(null,
1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",19,$CLJS.UH,1,804,804,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.UH,$CLJS.sI,$CLJS.W($CLJS.Ij,JP),$CLJS.Mn,$CLJS.W($CLJS.Ij,JP),$CLJS.ak,$CLJS.W($CLJS.Ij,JP)],null),$CLJS.Jc,"Schema for a valid between clause.",
$CLJS.n($V)?$V.H:null])):null));return $CLJS.n(a)?a:aP}(),$V],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof gW?new $CLJS.Fc(function(){return gW},$CLJS.od(xga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZS,"metabase/mbql/schema.cljc",23,$CLJS.jI,1,829,829,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.jI,$CLJS.sI,$CLJS.W($CLJS.Ij,TS),tP,$CLJS.W($CLJS.Ij,TS),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,oS))],null),$CLJS.Jc,"Schema for a valid starts-with clause.",$CLJS.n(gW)?gW.H:null])):null));return $CLJS.n(a)?a:ZS}(),gW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof hW?new $CLJS.Fc(function(){return hW},
$CLJS.od(zfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",21,$CLJS.DI,1,830,830,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.DI,$CLJS.sI,$CLJS.W($CLJS.Ij,TS),tP,$CLJS.W($CLJS.Ij,TS),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,oS))],null),
$CLJS.Jc,"Schema for a valid ends-with clause.",$CLJS.n(hW)?hW.H:null])):null));return $CLJS.n(a)?a:yP}(),hW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof iW?new $CLJS.Fc(function(){return iW},$CLJS.od(Lfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",20,$CLJS.rI,1,831,831,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.rI,$CLJS.sI,$CLJS.W($CLJS.Ij,TS),tP,$CLJS.W($CLJS.Ij,TS),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,oS))],null),$CLJS.Jc,"Schema for a valid contains clause.",$CLJS.n(iW)?iW.H:null])):null));return $CLJS.n(a)?a:KP}(),iW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==
typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof jW?new $CLJS.Fc(function(){return jW},$CLJS.od(pha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",36,$CLJS.kI,1,834,834,!0,new $CLJS.Q(null,
7,5,$CLJS.R,[$CLJS.kI,$CLJS.sI,$CLJS.W($CLJS.Ij,TS),tP,$CLJS.W($CLJS.Ij,TS),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,oS))],null),$CLJS.Jc,"Schema for a valid does-not-contain clause.",$CLJS.n(jW)?jW.H:null])):null));return $CLJS.n(a)?a:nO}(),jW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof aW?new $CLJS.Fc(function(){return aW},$CLJS.od(Zga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),
$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XS,"metabase/mbql/schema.cljc",26,$CLJS.$H,1,807,807,!0,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.$H,hfa,$CLJS.W($CLJS.Ij,JP),tha,$CLJS.W($CLJS.Ij,JP),fha,$CLJS.W($CLJS.Ij,JP),kfa,$CLJS.W($CLJS.Ij,JP),$ga,$CLJS.W($CLJS.Ij,JP),gfa,$CLJS.W($CLJS.Ij,
JP)],null),$CLJS.Jc,"Schema for a valid inside clause.",$CLJS.n(aW)?aW.H:null])):null));return $CLJS.n(a)?a:XS}(),aW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof dW?new $CLJS.Fc(function(){return dW},$CLJS.od(Mfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",28,$CLJS.VH,1,821,821,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VH,$CLJS.sI,$CLJS.W($CLJS.Ij,CP)],null),$CLJS.Jc,"Schema for a valid is-empty clause.",$CLJS.n(dW)?dW.H:null])):null));return $CLJS.n(a)?a:rR}(),dW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof eW?new $CLJS.Fc(function(){return eW},$CLJS.od(Kga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fS,"metabase/mbql/schema.cljc",29,$CLJS.bI,1,822,822,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bI,$CLJS.sI,$CLJS.W($CLJS.Ij,CP)],null),
$CLJS.Jc,"Schema for a valid not-empty clause.",$CLJS.n(eW)?eW.H:null])):null));return $CLJS.n(a)?a:fS}(),eW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof bW?new $CLJS.Fc(function(){return bW},$CLJS.od(kha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",27,$CLJS.TH,1,816,816,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TH,$CLJS.sI,$CLJS.W($CLJS.Ij,CP)],null),$CLJS.Jc,"Schema for a valid is-null clause.",$CLJS.n(bW)?bW.H:null])):null));return $CLJS.n(a)?a:QP}(),bW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof cW?new $CLJS.Fc(function(){return cW},$CLJS.od(Yfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",28,$CLJS.eI,1,817,817,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eI,$CLJS.sI,$CLJS.W($CLJS.Ij,CP)],null),
$CLJS.Jc,"Schema for a valid not-null clause.",$CLJS.n(cW)?cW.H:null])):null));return $CLJS.n(a)?a:fP}(),cW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof kW?new $CLJS.Fc(function(){return kW},$CLJS.od(Zea,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ES,"metabase/mbql/schema.cljc",33,$CLJS.EI,1,856,856,!0,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.EI,$CLJS.sI,$CLJS.W($CLJS.Ij,CP),$CLJS.Ky,$CLJS.W($CLJS.Ij,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.st,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.tx,$CLJS.mA,$CLJS.ZH],null)],null)),$CLJS.li,$CLJS.W($CLJS.Ij,QQ),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,Aga))],null),$CLJS.Jc,"Schema for a valid time-interval clause.",
$CLJS.n(kW)?kW.H:null])):null));return $CLJS.n(a)?a:ES}(),kW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof lW?new $CLJS.Fc(function(){return lW},$CLJS.od(fga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GT,"metabase/mbql/schema.cljc",27,$CLJS.QH,1,872,872,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QH,ofa,$CLJS.W($CLJS.Ij,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,Qga,SO],null))],null),$CLJS.Jc,"Schema for a valid segment clause.",$CLJS.n(lW)?lW.H:null])):null));return $CLJS.n(a)?a:GT}(),lW],null)]))],null)],null));
$CLJS.Y(rS,YN($CLJS.aI,$CLJS.G(["clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jt,new $CLJS.h(null,1,[$CLJS.tu,":case subclause"],null),$CLJS.NV,ZU],null)],null)],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.h(null,1,[$CLJS.tu,":case options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),ZU],null)],null)],null)])));
var mW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rS],null);
$CLJS.Y(YS,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof lV?new $CLJS.Fc(function(){return lV},$CLJS.od(OR,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CQ,"metabase/mbql/schema.cljc",51,$CLJS.Js,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Js,$CLJS.KG,$CLJS.W($CLJS.Ij,SR),pT,$CLJS.W($CLJS.Ij,SR),GP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,SR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(lV)?lV.H:null])):null));return $CLJS.n(a)?a:CQ}(),lV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof mV?new $CLJS.Fc(function(){return mV},
$CLJS.od(rga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",51,$CLJS.ju,1,615,615,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ju,$CLJS.KG,$CLJS.W($CLJS.Ij,OS),
pT,$CLJS.W($CLJS.Ij,SR),GP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,SR))],null),$CLJS.Jc,"Schema for a valid - clause.",$CLJS.n(mV)?mV.H:null])):null));return $CLJS.n(a)?a:DQ}(),mV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO?new $CLJS.Fc(function(){return nV},$CLJS.od(qga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,fR,$CLJS.Li,$CLJS.fj,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,
new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.uC,"metabase/mbql/schema.cljc",$CLJS.OI,1,618,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.OI,$CLJS.KG,$CLJS.W($CLJS.Ij,OS),pT,$CLJS.W($CLJS.Ij,OS),GP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,OS))],null),$CLJS.Jc,"Schema for a valid / clause.",$CLJS.n(nV)?nV.H:null])):null));return $CLJS.n(a)?a:$CLJS.uC}(),nV],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof oV?new $CLJS.Fc(function(){return oV},$CLJS.od(sga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BQ,"metabase/mbql/schema.cljc",51,$CLJS.Ks,1,620,620,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ks,$CLJS.KG,$CLJS.W($CLJS.Ij,OS),pT,$CLJS.W($CLJS.Ij,OS),GP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,OS))],null),$CLJS.Jc,"Schema for a valid * clause.",$CLJS.n(oV)?oV.H:null])):null));return $CLJS.n(a)?a:BQ}(),oV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof aV?new $CLJS.Fc(function(){return aV},
$CLJS.od(BS,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QS,"metabase/mbql/schema.cljc",58,$CLJS.vI,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.vI,yT,$CLJS.W($CLJS.Ij,sO),xT,$CLJS.W($CLJS.Ij,
sO),GP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,sO))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(aV)?aV.H:null])):null));return $CLJS.n(a)?a:QS}(),aV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof cV?new $CLJS.Fc(function(){return cV},$CLJS.od(Jga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,
$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",56,$CLJS.gA,1,585,585,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gA,$CLJS.DA,$CLJS.W($CLJS.Ij,TS)],null),$CLJS.Jc,"Schema for a valid length clause.",$CLJS.n(cV)?cV.H:null])):null));return $CLJS.n(a)?a:iS}(),cV],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof pV?new $CLJS.Fc(function(){return pV},$CLJS.od(Afa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cR,"metabase/mbql/schema.cljc",55,$CLJS.qI,1,622,622,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,$CLJS.KG,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid floor clause.",$CLJS.n(pV)?pV.H:null])):null));return $CLJS.n(a)?a:cR}(),pV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof qV?new $CLJS.Fc(function(){return qV},$CLJS.od(ufa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),
$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",54,$CLJS.OH,1,625,625,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OH,$CLJS.KG,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid ceil clause.",$CLJS.n(qV)?
qV.H:null])):null));return $CLJS.n(a)?a:RO}(),qV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof rV?new $CLJS.Fc(function(){return rV},$CLJS.od(Ifa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",55,$CLJS.xI,1,628,628,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xI,$CLJS.KG,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid round clause.",$CLJS.n(rV)?rV.H:null])):null));return $CLJS.n(a)?a:TQ}(),rV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof sV?new $CLJS.Fc(function(){return sV},$CLJS.od(qha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",53,$CLJS.SH,1,631,631,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.SH,$CLJS.KG,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid abs clause.",$CLJS.n(sV)?sV.H:null])):null));return $CLJS.n(a)?a:vR}(),sV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof tV?new $CLJS.Fc(function(){return tV},$CLJS.od(dha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,
bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[MT,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hT,"metabase/mbql/schema.cljc",69,$CLJS.PH,1,634,634,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.PH,$CLJS.KG,$CLJS.W($CLJS.Ij,OS),pT,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid power clause.",$CLJS.n(tV)?tV.H:null])):null));return $CLJS.n(a)?a:hT}(),tV],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof uV?new $CLJS.Fc(function(){return uV},$CLJS.od(aga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[MT,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xQ,"metabase/mbql/schema.cljc",68,$CLJS.fI,1,637,637,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,$CLJS.KG,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid sqrt clause.",$CLJS.n(uV)?uV.H:null])):null));return $CLJS.n(a)?a:xQ}(),uV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof vV?new $CLJS.Fc(function(){return vV},$CLJS.od(eha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),
$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[MT,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",67,$CLJS.LH,1,640,640,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LH,$CLJS.KG,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid exp clause.",$CLJS.n(vV)?
vV.H:null])):null));return $CLJS.n(a)?a:VQ}(),vV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof wV?new $CLJS.Fc(function(){return wV},$CLJS.od(nfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[MT,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RT,"metabase/mbql/schema.cljc",67,$CLJS.QI,1,643,643,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QI,$CLJS.KG,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid log clause.",$CLJS.n(wV)?wV.H:null])):null));return $CLJS.n(a)?a:RT}(),wV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof mW?new $CLJS.Fc(function(){return mW},$CLJS.od(DO,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",61,$CLJS.aI,1,909,909,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.aI,$CLJS.Ny,$CLJS.W($CLJS.Ij,WP),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,FR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(mW)?mW.H:null])):null));return $CLJS.n(a)?a:mR}(),mW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof xV?new $CLJS.Fc(function(){return xV},$CLJS.od(qfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,
$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.ZK,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wR,"metabase/mbql/schema.cljc",65,$CLJS.ZK,1,656,656,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ZK,Cfa,$CLJS.W($CLJS.Ij,bU),Dfa,$CLJS.W($CLJS.Ij,bU),$CLJS.li,$CLJS.W($CLJS.Ij,iga)],null),$CLJS.Jc,"Schema for a valid datetime-diff clause.",
$CLJS.n(xV)?xV.H:null])):null));return $CLJS.n(a)?a:wR}(),xV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof yV?new $CLJS.Fc(function(){return yV},$CLJS.od(Nga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),$CLJS.od(MS,
new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PR,"metabase/mbql/schema.cljc",71,$CLJS.nL,1,661,661,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.nL,$CLJS.cL,$CLJS.W($CLJS.Ij,bU),$CLJS.li,$CLJS.W($CLJS.Ij,jha),$CLJS.ri,$CLJS.W($CLJS.Ij,$CLJS.W(eT,jU))],null),$CLJS.Jc,"Schema for a valid temporal-extract clause.",$CLJS.n(yV)?yV.H:null])):null));return $CLJS.n(a)?a:PR}(),yV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof zV?new $CLJS.Fc(function(){return zV},$CLJS.od(Ufa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nP,"metabase/mbql/schema.cljc",71,$CLJS.VK,1,667,667,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VK,$CLJS.yR,$CLJS.W($CLJS.Ij,bU)],null),$CLJS.Jc,"Schema for a valid get-year clause.",$CLJS.n(zV)?zV.H:null])):null));return $CLJS.n(a)?a:nP}(),zV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof AV?new $CLJS.Fc(function(){return AV},$CLJS.od(Jfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],
null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NR,"metabase/mbql/schema.cljc",74,$CLJS.rL,1,670,670,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rL,$CLJS.yR,$CLJS.W($CLJS.Ij,bU)],null),$CLJS.Jc,"Schema for a valid get-quarter clause.",
$CLJS.n(AV)?AV.H:null])):null));return $CLJS.n(a)?a:NR}(),AV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof BV?new $CLJS.Fc(function(){return BV},$CLJS.od(pga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),
$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kS,"metabase/mbql/schema.cljc",72,$CLJS.OK,1,673,673,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OK,$CLJS.yR,$CLJS.W($CLJS.Ij,bU)],null),$CLJS.Jc,"Schema for a valid get-month clause.",$CLJS.n(BV)?BV.H:null])):null));return $CLJS.n(a)?a:kS}(),BV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==
typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof CV?new $CLJS.Fc(function(){return CV},$CLJS.od(iha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jS,"metabase/mbql/schema.cljc",
71,$CLJS.hL,1,676,676,!0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.hL,$CLJS.yR,$CLJS.W($CLJS.Ij,bU),$CLJS.ri,$CLJS.W($CLJS.Ij,$CLJS.W(eT,jU))],null),$CLJS.Jc,"Schema for a valid get-week clause.",$CLJS.n(CV)?CV.H:null])):null));return $CLJS.n(a)?a:jS}(),CV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof DV?new $CLJS.Fc(function(){return DV},$CLJS.od(efa,new $CLJS.h(null,1,[$CLJS.Tj,
!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cS,"metabase/mbql/schema.cljc",70,$CLJS.gL,1,680,680,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gL,$CLJS.yR,$CLJS.W($CLJS.Ij,bU)],null),$CLJS.Jc,"Schema for a valid get-day clause.",
$CLJS.n(DV)?DV.H:null])):null));return $CLJS.n(a)?a:cS}(),DV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof EV?new $CLJS.Fc(function(){return EV},$CLJS.od(jga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),
$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TR,"metabase/mbql/schema.cljc",78,$CLJS.lL,1,683,683,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lL,$CLJS.yR,$CLJS.W($CLJS.Ij,bU)],null),$CLJS.Jc,"Schema for a valid get-day-of-week clause.",$CLJS.n(EV)?EV.H:null])):null));return $CLJS.n(a)?a:TR}(),EV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==
typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof FV?new $CLJS.Fc(function(){return FV},$CLJS.od(hga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",
71,$CLJS.oL,1,686,686,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oL,$CLJS.cL,$CLJS.W($CLJS.Ij,bU)],null),$CLJS.Jc,"Schema for a valid get-hour clause.",$CLJS.n(FV)?FV.H:null])):null));return $CLJS.n(a)?a:tO}(),FV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof GV?new $CLJS.Fc(function(){return GV},$CLJS.od(Rga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,
$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VT,"metabase/mbql/schema.cljc",73,$CLJS.kL,1,689,689,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kL,$CLJS.cL,$CLJS.W($CLJS.Ij,bU)],null),$CLJS.Jc,"Schema for a valid get-minute clause.",$CLJS.n(GV)?GV.H:null])):null));
return $CLJS.n(a)?a:VT}(),GV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof HV?new $CLJS.Fc(function(){return HV},$CLJS.od(ega,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.nL,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oR,"metabase/mbql/schema.cljc",73,$CLJS.UK,1,692,692,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UK,$CLJS.cL,$CLJS.W($CLJS.Ij,bU)],null),$CLJS.Jc,"Schema for a valid get-second clause.",$CLJS.n(HV)?HV.H:null])):null));return $CLJS.n(a)?a:oR}(),HV],null)])));
$CLJS.Y(JO,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof bV?new $CLJS.Fc(function(){return bV},$CLJS.od(Hga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sS,"metabase/mbql/schema.cljc",59,$CLJS.MH,1,582,582,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.MH,$CLJS.DA,$CLJS.W($CLJS.Ij,TS),$CLJS.Zw,$CLJS.W($CLJS.Ij,Ffa),$CLJS.gA,$CLJS.W($CLJS.Ij,$CLJS.W(eT,OS))],null),$CLJS.Jc,"Schema for a valid substring clause.",$CLJS.n(bV)?bV.H:null])):null));return $CLJS.n(a)?a:sS}(),bV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof dV?new $CLJS.Fc(function(){return dV},
$CLJS.od(gha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PS,"metabase/mbql/schema.cljc",54,$CLJS.JI,1,588,588,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JI,$CLJS.DA,$CLJS.W($CLJS.Ij,TS)],
null),$CLJS.Jc,"Schema for a valid trim clause.",$CLJS.n(dV)?dV.H:null])):null));return $CLJS.n(a)?a:PS}(),dV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof fV?new $CLJS.Fc(function(){return fV},$CLJS.od(kga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,
1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yS,"metabase/mbql/schema.cljc",55,$CLJS.LI,1,594,594,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LI,$CLJS.DA,$CLJS.W($CLJS.Ij,TS)],null),$CLJS.Jc,"Schema for a valid ltrim clause.",$CLJS.n(fV)?fV.H:null])):null));return $CLJS.n(a)?a:yS}(),fV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&
"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof eV?new $CLJS.Fc(function(){return eV},$CLJS.od(Iga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AR,"metabase/mbql/schema.cljc",
55,$CLJS.SI,1,591,591,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SI,$CLJS.DA,$CLJS.W($CLJS.Ij,TS)],null),$CLJS.Jc,"Schema for a valid rtrim clause.",$CLJS.n(eV)?eV.H:null])):null));return $CLJS.n(a)?a:AR}(),eV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof iV?new $CLJS.Fc(function(){return iV},$CLJS.od($ea,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,
$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AT,"metabase/mbql/schema.cljc",57,$CLJS.RI,1,603,603,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.RI,$CLJS.DA,$CLJS.W($CLJS.Ij,TS),lfa,$CLJS.W($CLJS.Ij,$CLJS.Rj),$CLJS.BC,$CLJS.W($CLJS.Ij,$CLJS.Rj)],null),$CLJS.Jc,"Schema for a valid replace clause.",
$CLJS.n(iV)?iV.H:null])):null));return $CLJS.n(a)?a:AT}(),iV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof hV?new $CLJS.Fc(function(){return hV},$CLJS.od(Lga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,
new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eU,"metabase/mbql/schema.cljc",55,$CLJS.Pu,1,600,600,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pu,$CLJS.DA,$CLJS.W($CLJS.Ij,TS)],null),$CLJS.Jc,"Schema for a valid lower clause.",$CLJS.n(hV)?hV.H:null])):null));return $CLJS.n(a)?a:eU}(),hV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==
typeof cO&&"undefined"!==typeof gV?new $CLJS.Fc(function(){return gV},$CLJS.od(hha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bT,"metabase/mbql/schema.cljc",55,$CLJS.Hu,1,597,597,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Hu,$CLJS.DA,$CLJS.W($CLJS.Ij,TS)],null),$CLJS.Jc,"Schema for a valid upper clause.",$CLJS.n(gV)?gV.H:null])):null));return $CLJS.n(a)?a:bT}(),gV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof jV?new $CLJS.Fc(function(){return jV},$CLJS.od(zga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,
$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",56,$CLJS.RH,1,606,606,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.RH,yT,$CLJS.W($CLJS.Ij,TS),xT,$CLJS.W($CLJS.Ij,TS),GP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,TS))],null),$CLJS.Jc,"Schema for a valid concat clause.",$CLJS.n(jV)?
jV.H:null])):null));return $CLJS.n(a)?a:oQ}(),jV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof kV?new $CLJS.Fc(function(){return kV},$CLJS.od(Gga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.WR,"null",$CLJS.qba,"null"],null),null),
$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",74,$CLJS.uI,1,609,609,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uI,$CLJS.DA,$CLJS.W($CLJS.Ij,TS),$CLJS.AC,$CLJS.W($CLJS.Ij,$CLJS.Rj)],null),$CLJS.Jc,"Schema for a valid regex-match-first clause.",$CLJS.n(kV)?kV.H:null])):null));return $CLJS.n(a)?a:lP}(),kV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==
typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof aV?new $CLJS.Fc(function(){return aV},$CLJS.od(BS,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.WR,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
QS,"metabase/mbql/schema.cljc",58,$CLJS.vI,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.vI,yT,$CLJS.W($CLJS.Ij,sO),xT,$CLJS.W($CLJS.Ij,sO),GP,$CLJS.W($CLJS.Ij,$CLJS.W($CLJS.Fi,sO))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(aV)?aV.H:null])):null));return $CLJS.n(a)?a:QS}(),aV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof mW?new $CLJS.Fc(function(){return mW},
$CLJS.od(DO,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",61,$CLJS.aI,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.aI,$CLJS.Ny,$CLJS.W($CLJS.Ij,WP),$CLJS.Yi,
$CLJS.W($CLJS.Ij,$CLJS.W(eT,FR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(mW)?mW.H:null])):null));return $CLJS.n(a)?a:mR}(),mW],null)])));
$CLJS.nW=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,":field or :expression reference or expression",$CLJS.Hi,function(a){return $CLJS.n(XN(PU,a))?cU:$CLJS.n(XN(MU,a))?$CLJS.Rj:$CLJS.n(XN(QU,a))?$CLJS.wt:$CLJS.n(XN(SU,a))?$CLJS.cL:$CLJS.n(XN($CLJS.aI,a))?$CLJS.aI:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[cU,TU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rj,NU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wt,UU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cL,$CLJS.VU],null),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aI,mW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,$CLJS.JU],null)],null);$CLJS.Y(JT,YN($CLJS.iA,$CLJS.G(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,$CLJS.JU],null)])));var oW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JT],null);$CLJS.Y(xR,YN($CLJS.tI,$CLJS.G(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ct,$CLJS.JU],null)])));var pW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xR],null);$CLJS.Y(HO,YN($CLJS.mI,$CLJS.G(["field-or-expression",$CLJS.nW])));
var qW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HO],null);$CLJS.Y(BT,YN($CLJS.PI,$CLJS.G(["field-or-expression",$CLJS.nW])));var rW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BT],null);$CLJS.Y(gQ,YN($CLJS.XH,$CLJS.G(["field-or-expression",$CLJS.nW])));var sW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gQ],null);$CLJS.Y(RS,YN($CLJS.FI,$CLJS.G(["field-or-expression",$CLJS.nW])));var tW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RS],null);$CLJS.Y(bQ,YN($CLJS.Mn,$CLJS.G(["field-or-expression",$CLJS.nW])));
var uW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bQ],null);$CLJS.Y(OP,YN($CLJS.ak,$CLJS.G(["field-or-expression",$CLJS.nW])));var vW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OP],null);$CLJS.Y(IP,YN($CLJS.NI,$CLJS.G(["field-or-expression",$CLJS.nW,"pred",$CLJS.NV])));var wW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IP],null);$CLJS.Y(ZP,YN($CLJS.BI,$CLJS.G(["pred",$CLJS.NV])));var xW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZP],null);$CLJS.Y(IS,YN($CLJS.zI,$CLJS.G(["pred",$CLJS.NV])));
var yW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IS],null);$CLJS.Y(eP,YN($CLJS.II,$CLJS.G(["field-or-expression",$CLJS.nW])));var zW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eP],null);$CLJS.Y(eO,YN($CLJS.YH,$CLJS.G(["field-or-expression",$CLJS.nW])));var AW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eO],null);$CLJS.Y(kQ,YN($CLJS.GI,$CLJS.G(["field-or-expression",$CLJS.nW])));var BW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kQ],null);$CLJS.Y(YQ,YN($CLJS.dI,$CLJS.G(["field-or-expression",$CLJS.nW,"percentile",XU])));
var CW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YQ],null);$CLJS.Y(LS,YN($CLJS.nI,$CLJS.G(["metric-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PM],null),kU],null)])));var DW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LS],null);
$CLJS.Y(HS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"unnamed aggregation clause or numeric expression",$CLJS.Hi,function(a){return $CLJS.n(XN(PU,a))?aR:$CLJS.Ju}],null),new $CLJS.Q(null,2,5,$CLJS.R,[aR,TU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ju,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof qW?new $CLJS.Fc(function(){return qW},$CLJS.od(Fga,
new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",60,$CLJS.mI,1,957,957,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mI,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid avg clause.",
$CLJS.n(qW)?qW.H:null])):null));return $CLJS.n(a)?a:jR}(),qW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof rW?new $CLJS.Fc(function(){return rW},$CLJS.od(Qfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,
new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oT,"metabase/mbql/schema.cljc",64,$CLJS.PI,1,958,958,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PI,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid cum-sum clause.",$CLJS.n(rW)?rW.H:null])):null));return $CLJS.n(a)?a:oT}(),rW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==
typeof cO&&"undefined"!==typeof sW?new $CLJS.Fc(function(){return sW},$CLJS.od(Hfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",65,$CLJS.XH,1,959,959,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid distinct clause.",$CLJS.n(sW)?sW.H:null])):null));return $CLJS.n(a)?a:nR}(),sW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof zW?new $CLJS.Fc(function(){return zW},$CLJS.od(aha,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,
$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CI,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AO,"metabase/mbql/schema.cljc",76,$CLJS.II,1,973,973,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.II,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid stddev clause.",$CLJS.n(zW)?zW.H:null])):null));return $CLJS.n(a)?a:AO}(),zW],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof tW?new $CLJS.Fc(function(){return tW},$CLJS.od(uga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XO,"metabase/mbql/schema.cljc",60,$CLJS.FI,1,960,960,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FI,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid sum clause.",$CLJS.n(tW)?tW.H:null])):null));return $CLJS.n(a)?a:XO}(),tW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof uW?new $CLJS.Fc(function(){return uW},$CLJS.od(ifa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,
$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Rt,"metabase/mbql/schema.cljc",60,$CLJS.Mn,1,961,961,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mn,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid min clause.",$CLJS.n(uW)?uW.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Rt}(),uW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof vW?new $CLJS.Fc(function(){return vW},$CLJS.od(dfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Wt,"metabase/mbql/schema.cljc",60,$CLJS.ak,1,962,962,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ak,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid max clause.",$CLJS.n(vW)?vW.H:null])):null));return $CLJS.n(a)?a:$CLJS.Wt}(),vW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof DW?new $CLJS.Fc(function(){return DW},$CLJS.od(Efa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",18,$CLJS.nI,1,994,994,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nI,Rfa,$CLJS.W($CLJS.Ij,new $CLJS.Q(null,3,5,
$CLJS.R,[$CLJS.gt,Vfa,SO],null))],null),$CLJS.Jc,"Schema for a valid metric clause.",$CLJS.n(DW)?DW.H:null])):null));return $CLJS.n(a)?a:nQ}(),DW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof yW?new $CLJS.Fc(function(){return yW},$CLJS.od(cga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,
$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",62,$CLJS.zI,1,970,970,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zI,$CLJS.eo,$CLJS.W($CLJS.Ij,WQ)],null),$CLJS.Jc,"Schema for a valid share clause.",$CLJS.n(yW)?yW.H:null])):null));return $CLJS.n(a)?a:fQ}(),yW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof xW?new $CLJS.Fc(function(){return xW},$CLJS.od(jfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TO,"metabase/mbql/schema.cljc",68,$CLJS.BI,1,967,967,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BI,$CLJS.eo,$CLJS.W($CLJS.Ij,WQ)],null),$CLJS.Jc,"Schema for a valid count-where clause.",$CLJS.n(xW)?xW.H:null])):null));return $CLJS.n(a)?a:TO}(),xW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof wW?new $CLJS.Fc(function(){return wW},$CLJS.od(oha,new $CLJS.h(null,1,[$CLJS.Tj,!0],
null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kR,"metabase/mbql/schema.cljc",66,$CLJS.NI,1,964,964,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.NI,cT,$CLJS.W($CLJS.Ij,VS),$CLJS.eo,$CLJS.W($CLJS.Ij,WQ)],null),$CLJS.Jc,"Schema for a valid sum-where clause.",
$CLJS.n(wW)?wW.H:null])):null));return $CLJS.n(a)?a:kR}(),wW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof mW?new $CLJS.Fc(function(){return mW},$CLJS.od(DO,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,
new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",61,$CLJS.aI,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.aI,$CLJS.Ny,$CLJS.W($CLJS.Ij,WP),$CLJS.Yi,$CLJS.W($CLJS.Ij,$CLJS.W(eT,FR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(mW)?mW.H:null])):null));return $CLJS.n(a)?a:mR}(),mW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&
"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof BW?new $CLJS.Fc(function(){return BW},$CLJS.od(Uga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oI,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mT,"metabase/mbql/schema.cljc",
68,$CLJS.GI,1,979,979,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GI,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid median clause.",$CLJS.n(BW)?BW.H:null])):null));return $CLJS.n(a)?a:mT}(),BW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof CW?new $CLJS.Fc(function(){return CW},$CLJS.od(oga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,
fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.oI,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KS,"metabase/mbql/schema.cljc",72,$CLJS.dI,1,982,982,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.dI,cT,$CLJS.W($CLJS.Ij,VS),$CLJS.dI,$CLJS.W($CLJS.Ij,OS)],null),$CLJS.Jc,"Schema for a valid percentile clause.",$CLJS.n(CW)?CW.H:null])):
null));return $CLJS.n(a)?a:KS}(),CW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof AW?new $CLJS.Fc(function(){return AW},$CLJS.od(tfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vO,"metabase/mbql/schema.cljc",77,$CLJS.YH,1,976,976,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,cT,$CLJS.W($CLJS.Ij,VS)],null),$CLJS.Jc,"Schema for a valid var clause.",$CLJS.n(AW)?AW.H:null])):null));return $CLJS.n(a)?a:vO}(),AW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof pW?new $CLJS.Fc(function(){return pW},$CLJS.od(ffa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,
$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",74,$CLJS.tI,1,947,947,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tI,$CLJS.sI,$CLJS.W($CLJS.Ij,$CLJS.W(eT,CP))],null),$CLJS.Jc,"Schema for a valid cum-count clause.",
$CLJS.n(pW)?pW.H:null])):null));return $CLJS.n(a)?a:XQ}(),pW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof oW?new $CLJS.Fc(function(){return oW},$CLJS.od(Gfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([dR,$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,gT,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.NH,"null"],null),null),
$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.qi,"metabase/mbql/schema.cljc",70,$CLJS.iA,1,946,946,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iA,$CLJS.sI,$CLJS.W($CLJS.Ij,$CLJS.W(eT,CP))],null),$CLJS.Jc,"Schema for a valid count clause.",$CLJS.n(oW)?oW.H:null])):null));return $CLJS.n(a)?a:$CLJS.qi}(),oW],null)]))],null)],null));
$CLJS.Y($R,YN($CLJS.qO,$CLJS.G(["aggregation",HS,"options",new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.h(null,1,[$CLJS.tu,":aggregation-options options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),kU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zG,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),kU],null)],null)])));
$CLJS.Y(dT,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.tu,"aggregation clause or numeric expression",$CLJS.Hi,function(a){return $CLJS.n(XN($CLJS.qO,a))?$CLJS.qO:VP}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$R],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[VP,HS],null)],null));$CLJS.Y(zO,YN($CLJS.pI,$CLJS.G(["field",LU])));var EW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zO],null);$CLJS.Y($Q,YN($CLJS.iI,$CLJS.G(["field",LU])));
FW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$Q],null);
Eha=ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof EW?new $CLJS.Fc(function(){return EW},$CLJS.od(Yga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
GQ,"metabase/mbql/schema.cljc",15,$CLJS.pI,1,1049,1049,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pI,$CLJS.sI,$CLJS.W($CLJS.Ij,wT)],null),$CLJS.Jc,"Schema for a valid asc clause.",$CLJS.n(EW)?EW.H:null])):null));return $CLJS.n(a)?a:GQ}(),EW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof FW?new $CLJS.Fc(function(){return FW},$CLJS.od(Pfa,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,
$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CS,"metabase/mbql/schema.cljc",16,$CLJS.iI,1,1050,1050,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iI,$CLJS.sI,$CLJS.W($CLJS.Ij,wT)],null),$CLJS.Jc,"Schema for a valid desc clause.",$CLJS.n(FW)?FW.H:null])):null));return $CLJS.n(a)?a:CS}(),FW],null)]));
GW=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ct,$CLJS.CO,$CLJS.sP,$CLJS.fO,$CLJS.cv,$CLJS.KD,$CLJS.yR],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zG,kU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),kU],null)],null);
Fha=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,GW,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.CO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oO,kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QR,nU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RR,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),nU],null)],null)],null);
Gha=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,GW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.sP],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$S,nU],null)],null)],null);
HW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,GW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.ei],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UP,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null)],null)],null);Hha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nT],null);
Iha=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,HW,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.fO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fO,IU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,WO],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mi,$CLJS.Oi,$CLJS.ei],null)],null)],null)],null);
$CLJS.IW=new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.yR,null,$CLJS.cv,null,$CLJS.wt,null,$CLJS.KD,null],null),null);Jha=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ct],null),$CLJS.IW);
$CLJS.Y(vS,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Hi,$CLJS.ej],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fO,Iha],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CO,Fha],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sP,Gha],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.No,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,HW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,Jha],null)],null)],null)],null)],null));
var JW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DS,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mi,kU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vS],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Ie(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iQ,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,kU],null)],null)],null),Kha=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,JW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EO,$CLJS.ei],null)],null)],null),KW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dS],null),LW=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Hi,function(a){return $CLJS.n($CLJS.KN($CLJS.wd,
$CLJS.gS)(a))?$CLJS.gS:$CLJS.yG}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,JW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gS,$CLJS.ei],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yG,KW],null)],null),MW=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sF,lU],null),new $CLJS.Q(null,2,5,$CLJS.R,[wga,kU],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.AQ,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,mU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PP,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Zj],null)],null)],null),NW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,wha,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.tu,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Lha=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ct],null),
new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.sH,null,$CLJS.aH,null,$CLJS.lH,null,$CLJS.pH,null],null),null)),OW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lT],null);
$CLJS.Y(eQ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bR,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),NW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FS,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),LW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NT,$CLJS.NV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NG,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),Lha],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bH,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.tu,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,$CLJS.OA,$CLJS.vC],null),OW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fH,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),kU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,pU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OQ,new $CLJS.h(null,
1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,MW],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.KN($CLJS.PC($CLJS.bR,$CLJS.FS),$CLJS.Ke($CLJS.KN($CLJS.bR,$CLJS.FS)))],null)],null));var Mha=$CLJS.R,PW;var QW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eQ],null)],null);
if($CLJS.vd(QW)&&$CLJS.F.g($CLJS.B(QW),$CLJS.rj)){var RW=$CLJS.z(QW);$CLJS.B(RW);var SW=$CLJS.C(RW),Nha=$CLJS.wd($CLJS.B(SW))?SW:$CLJS.ce(null,SW),TW=$CLJS.z(Nha),Oha=$CLJS.B(TW),Pha=$CLJS.C(TW);PW=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,$CLJS.S.j(Oha,$CLJS.Mn,1)],null),Pha)}else PW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,QW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"non-empty"],null),$CLJS.z],null)],null);
$CLJS.Y(gP,new $CLJS.Q(null,3,5,Mha,[$CLJS.mt,PW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"All join aliases must be unique."],null),function(a){return $CLJS.IN($CLJS.bf($CLJS.Ta,$CLJS.Ze.g($CLJS.fH,a)))}],null)],null));var Qha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gP],null);
$CLJS.Y(lT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.el,new $CLJS.h(null,1,[$CLJS.tu,"Distinct, non-empty sequence of Field clauses"],null),$N(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rj,new $CLJS.h(null,1,[$CLJS.Mn,1],null),$CLJS.JU],null))],null));
$CLJS.Y(dS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FS,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),LW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bR,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),NW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hI,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rj,new $CLJS.h(null,1,[$CLJS.Mn,1],null),$CLJS.WU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gI,new $CLJS.h(null,1,
[$CLJS.Ct,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rj,new $CLJS.h(null,1,[$CLJS.Mn,1],null),$CLJS.JU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WR,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mi,kU,$CLJS.nW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bH,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),OW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WH,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.NV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hU,new $CLJS.h(null,
1,[$CLJS.Ct,!0],null),oU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LT,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$N(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rj,new $CLJS.h(null,1,[$CLJS.Mn,1],null),Eha],null))],null),new $CLJS.Q(null,3,5,$CLJS.R,[hS,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[hS,nU],null),new $CLJS.Q(null,2,5,$CLJS.R,[yfa,nU],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),Qha],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OQ,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,MW],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.g(1,$CLJS.E($CLJS.Tl(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FS,$CLJS.bR],null))))}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,
new $CLJS.h(null,1,[$CLJS.tu,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.gI);b=$CLJS.J.g(b,$CLJS.bH);return $CLJS.sd($CLJS.xv.g($CLJS.Yg(a),$CLJS.Yg(b)))}],null)],null));
$CLJS.UW=$CLJS.Ag([$CLJS.MI,$CLJS.yR,aQ,MP,PQ,ZT,vQ,$CLJS.YT,$CLJS.cv,uQ,KO,SS,OT,$CLJS.$O,KT,YP,$CLJS.UQ,sQ,$CLJS.Ri,$CLJS.rQ,rO,$CLJS.wP,$CLJS.qQ,tR,$CLJS.wt,$CLJS.KD,mQ],[new $CLJS.h(null,1,[FQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.MI,null,$CLJS.yR,null,$CLJS.cv,null,$CLJS.wt,null,$CLJS.KD,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.yR,FQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.MI,null,$CLJS.yR,null,$CLJS.YT,null,$CLJS.UQ,null,$CLJS.Ri,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.ej,$CLJS.Rj,$CLJS.GG,aT,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[aQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,cU,$CLJS.GG,aT,FQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.MI,null,MP,null,$CLJS.cv,null,$CLJS.Ri,null,rO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,$CLJS.Rj,$CLJS.GG,OO,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[PQ,null],null),null)],null),new $CLJS.h(null,1,[FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[ZT,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,
cU,$CLJS.GG,Oga,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[vQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.yR,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.YT,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,cU,FQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.MI,null,MP,null,$CLJS.cv,null,$CLJS.Ri,null,rO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,cU,$CLJS.GG,OO,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[uQ,null],null),null)],null),new $CLJS.h(null,1,[FQ,new $CLJS.Tg(null,
new $CLJS.h(null,1,[KO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,$CLJS.Rj,$CLJS.GG,aT,FQ,new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.MI,null,ZT,null,KO,null,SS,null,sQ,null,$CLJS.Ri,null,rO,null,$CLJS.KD,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,$CLJS.Rj,$CLJS.GG,OO,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[OT,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.yR,FQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.YT,null,$CLJS.$O,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.ej,$CLJS.Rj,$CLJS.GG,OO,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[KT,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,$CLJS.Rj,$CLJS.GG,OO,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[YP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.yR,FQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.MI,null,$CLJS.yR,null,$CLJS.YT,null,$CLJS.UQ,null,$CLJS.Ri,null],null),null)],null),new $CLJS.h(null,1,[FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[sQ,null],null),null)],null),new $CLJS.h(null,1,[FQ,
new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.Ri,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.yR,FQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.YT,null,$CLJS.rQ,null],null),null)],null),new $CLJS.h(null,1,[FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[rO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.yR,FQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.YT,null,$CLJS.wP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.yR,FQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.YT,
null,$CLJS.qQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,cU,$CLJS.GG,aT,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[tR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.wt,FQ,new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.MI,null,$CLJS.Ri,null,$CLJS.wt,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.ej,$CLJS.Rj,FQ,new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.MI,null,ZT,null,KO,null,SS,null,sQ,null,$CLJS.Ri,null,rO,null,$CLJS.KD,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.ej,
cU,$CLJS.GG,OO,FQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[mQ,null],null),null)],null)]);$CLJS.Y(nT,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"valid parameter type"],null)],null),$CLJS.Pg($CLJS.UW)));$CLJS.Y(WO,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"valid template tag widget type"],null),$CLJS.vC],null),$CLJS.Pg($CLJS.UW)));
$CLJS.Y(AS,YN(pfa,$CLJS.G(["tag-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,kU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,kU],null)],null)],null)])));var VW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AS],null);$CLJS.Y(uO,YN($CLJS.fO,$CLJS.G(["target",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.JU,VW],null)])));var WW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uO],null);$CLJS.Y(qP,YN(pQ,$CLJS.G(["target",VW])));
var XW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,qP],null),Rha=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.JU,ZN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof WW?new $CLJS.Fc(function(){return WW},$CLJS.od(mga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lS,"metabase/mbql/schema.cljc",21,$CLJS.fO,1,1612,1612,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fO,$CLJS.uL,$CLJS.W($CLJS.Ij,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,CP,EQ],null))],null),$CLJS.Jc,"Schema for a valid dimension clause.",$CLJS.n(WW)?WW.H:null])):null));return $CLJS.n(a)?a:lS}(),WW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=fR.h($CLJS.pd("undefined"!==typeof $CLJS.aO&&
"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof XW?new $CLJS.Fc(function(){return XW},$CLJS.od(Cga,new $CLJS.h(null,1,[$CLJS.Tj,!0],null)),$CLJS.Ag([$CLJS.Ni,$CLJS.T,$CLJS.fk,$CLJS.oi,fR,$CLJS.Li,$CLJS.fj,$CLJS.nk,bP,$CLJS.ni,$CLJS.V,$CLJS.ek],[$CLJS.od(MS,new $CLJS.h(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dU,"metabase/mbql/schema.cljc",20,pQ,1,1615,1615,new $CLJS.Q(null,3,5,$CLJS.R,[pQ,$CLJS.uL,
$CLJS.W($CLJS.Ij,EQ)],null),$CLJS.Jc,"Schema for a valid variable clause.",$CLJS.n(XW)?XW.H:null])):null));return $CLJS.n(a)?a:dU}(),XW],null)]))],null),UN=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cP],null);
$CLJS.Y(cP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.KN($CLJS.PC($CLJS.gS,$CLJS.EO),$CLJS.Ke($CLJS.KN($CLJS.gS,$CLJS.EO)))],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.gS);var d=$CLJS.J.g(c,$CLJS.EO);c=$CLJS.J.g(c,$CLJS.ej);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.tu,"valid Database ID"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dN],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.ZM],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,$CLJS.EO,$CLJS.gS],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gS,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),Kha],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EO,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),KW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rP,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,new $CLJS.Q(null,
8,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,Hha],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),kU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uL,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),Rha],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vj,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.ei],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),kU],null),new $CLJS.Q(null,3,5,$CLJS.R,[vfa,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),
kU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.ei],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bN,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[mha,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),xU],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[lga,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[lR,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),oU],null),new $CLJS.Q(null,3,5,$CLJS.R,[VO,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),oU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,lR);b=$CLJS.J.g(b,VO);return $CLJS.Sa($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[bga,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[Xga,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null),new $CLJS.Q(null,3,5,$CLJS.R,[rfa,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null),new $CLJS.Q(null,3,5,$CLJS.R,[afa,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null),new $CLJS.Q(null,3,5,$CLJS.R,[xfa,new $CLJS.h(null,1,[$CLJS.Ct,
!0],null),$CLJS.wt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Pga,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.wt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[cfa,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.wt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[cha,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.wt],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YA,new $CLJS.h(null,
1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mP,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.ct,Bga,yga,$CLJS.iQ,nga,Dga,$fa,mfa,sfa,Bfa,Ofa,Mga,uha,Sfa,Kfa],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Sga,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,nU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Xfa,
new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,nU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$S,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,vha],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[nha,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,kU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Zfa,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,nU],null)],null),new $CLJS.Q(null,
3,5,$CLJS.R,[lha,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mi,$CLJS.ei,$CLJS.ei],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Tfa,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,nU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lQ,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mi,$CLJS.ei,
$CLJS.ei],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NM,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.ei],null)],null)],null)],null)],null)],null)),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Ke($CLJS.OQ)],null)],null));var YW=$CLJS.TN(UN);
(function(){var a=Wea();return function(b){if($CLJS.n(YW.h?YW.h(b):YW.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.QN(b);throw $CLJS.ai($CLJS.uG("Invalid query: {0}",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))])),new $CLJS.h(null,2,[$CLJS.UA,c,gga,b],null));}})();