var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var t6,u6,x6,y6,A6,zna,C6,D6,F6,H6,J6,K6,Ana,L6,Q6,R6,U6,W6,X6,$6,c7,d7,e7,g7;t6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.common","metabase.lib.schema.drill-thru/drill-thru.common",1573044895);u6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.pk","metabase.lib.schema.drill-thru/drill-thru.pk",339574481);$CLJS.v6=new $CLJS.M("drill-thru","quick-filter","drill-thru/quick-filter",-1465756422);$CLJS.w6=new $CLJS.M(null,"row","row",-570139521);
x6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-details.fk-column","metabase.lib.schema.drill-thru/drill-thru.fk-details.fk-column",-380335831);y6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.distribution","metabase.lib.schema.drill-thru/drill-thru.distribution",937770403);$CLJS.z6=new $CLJS.M(null,"pivots","pivots",90109371);A6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.pivot","metabase.lib.schema.drill-thru/drill-thru.pivot",-1408664484);
zna=new $CLJS.M("metabase.lib.schema.drill-thru","context","metabase.lib.schema.drill-thru/context",122725764);$CLJS.B6=new $CLJS.M(null,"location","location",1815599388);C6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.column-filter","metabase.lib.schema.drill-thru/drill-thru.column-filter",603928893);D6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column-by-time","metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time",1590982743);
$CLJS.E6=new $CLJS.M(null,"initial-op","initial-op",918033121);F6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column","metabase.lib.schema.drill-thru/drill-thru.summarize-column",1770935578);$CLJS.G6=new $CLJS.M("drill-thru","column-filter","drill-thru/column-filter",1535293733);H6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.automatic-insights","metabase.lib.schema.drill-thru/drill-thru.automatic-insights",671448614);
$CLJS.I6=new $CLJS.M("drill-thru","fk-filter","drill-thru/fk-filter",1598101197);J6=new $CLJS.M("drill-thru","automatic-insights","drill-thru/automatic-insights",10252211);K6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries.next-unit","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries.next-unit",-2018118498);Ana=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru","metabase.lib.schema.drill-thru/drill-thru",-124537581);
L6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.object-details","metabase.lib.schema.drill-thru/drill-thru.object-details",-725614444);$CLJS.M6=new $CLJS.M("drill-thru","sort","drill-thru/sort",511059541);$CLJS.N6=new $CLJS.M("drill-thru","summarize-column","drill-thru/summarize-column",-156285576);$CLJS.O6=new $CLJS.M(null,"sort-directions","sort-directions",300459345);$CLJS.P6=new $CLJS.M(null,"aggregations","aggregations",-1081114338);
Q6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-details","metabase.lib.schema.drill-thru/drill-thru.fk-details",233147406);R6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.quick-filter","metabase.lib.schema.drill-thru/drill-thru.quick-filter",-1703184103);$CLJS.S6=new $CLJS.M(null,"row-count","row-count",1060167988);$CLJS.T6=new $CLJS.M("drill-thru","distribution","drill-thru/distribution",1480876450);
U6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.quick-filter.operator","metabase.lib.schema.drill-thru/drill-thru.quick-filter.operator",-1269703311);$CLJS.V6=new $CLJS.M("drill-thru","underlying-records","drill-thru/underlying-records",-1420917644);W6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-filter","metabase.lib.schema.drill-thru/drill-thru.fk-filter",-1219793148);
X6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom","metabase.lib.schema.drill-thru/drill-thru.zoom",-262809778);$CLJS.Y6=new $CLJS.M(null,"next-unit","next-unit",166270362);$CLJS.Z6=new $CLJS.M("drill-thru","summarize-column-by-time","drill-thru/summarize-column-by-time",-765720821);$6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries",-890057316);
$CLJS.a7=new $CLJS.M("drill-thru","pivot","drill-thru/pivot",-1038336574);$CLJS.b7=new $CLJS.M("drill-thru","zoom-in.timeseries","drill-thru/zoom-in.timeseries",-168450975);c7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.underlying-records","metabase.lib.schema.drill-thru/drill-thru.underlying-records",1571614324);d7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.sort","metabase.lib.schema.drill-thru/drill-thru.sort",-920673199);
e7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column.aggregation-type","metabase.lib.schema.drill-thru/drill-thru.summarize-column.aggregation-type",1395206778);$CLJS.f7=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);g7=new $CLJS.M("metabase.lib.schema.drill-thru","pivot-types","metabase.lib.schema.drill-thru/pivot-types",1174694312);$CLJS.Y(g7,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.MI,$CLJS.B6,$CLJS.XA],null));$CLJS.Y(t6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,$CLJS.ee],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.BN],null)],null)],null));
$CLJS.Y(L6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vN,$CLJS.ei],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uN,$CLJS.wt],null)],null)],null));
$CLJS.Y(u6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,L6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.yN],null)],null)],null)],null));$CLJS.Y(x6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.D0,$CLJS.gN],null)],null)],null));
$CLJS.Y(Q6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,L6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.zN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,x6],null)],null)],null)],null));
$CLJS.Y(X6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,L6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.f7],null)],null)],null)],null));$CLJS.Y(U6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.DG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gH],null)],null)],null));
$CLJS.Y(R6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.v6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,U6],null)],null)],null)],null));
$CLJS.Y(W6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.I6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WH,$CLJS.gH],null)],null)],null));
$CLJS.Y(y6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.T6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null)],null)],null));
$CLJS.Y(A6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.a7],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.z6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Mi,g7,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null)],null)],null)],null)],null));
$CLJS.Y(d7,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.M6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.O6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,$CLJS.wN],null)],null)],null)],null));$CLJS.Y(e7,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.mI,$CLJS.XH,$CLJS.FI],null));
$CLJS.Y(F6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.N6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,e7],null)],null)],null)],null)],null));
$CLJS.Y(D6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.Z6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gI,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,$CLJS.nJ],null)],null)],null));
$CLJS.Y(C6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.G6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.E6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.SL],null)],null)],null)],null));
$CLJS.Y(c7,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.V6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.S6,$CLJS.pl],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.Va],null)],null)],null)],null));
$CLJS.Y(H6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,J6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.BN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null)],null)],null));
$CLJS.Y(K6,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ct,$CLJS.ii,$CLJS.pj,$CLJS.gj,$CLJS.nv,$CLJS.mv,$CLJS.lv],null));
$CLJS.Y($6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,t6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.b7],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,$CLJS.Ta],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Y6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,K6],null)],null)],null)],null));
$CLJS.Y(Ana,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,t6,new $CLJS.Q(null,16,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Hi,$CLJS.ej,$CLJS.vu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.vj);return["Invalid drill thru (unknown :type): ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yN,u6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zN,Q6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.f7,X6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.v6,R6],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.I6,W6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T6,y6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.a7,A6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.M6,d7],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.N6,F6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Z6,D6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.G6,C6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.V6,c7],null),new $CLJS.Q(null,2,5,$CLJS.R,[J6,H6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.b7,$6],null)],null)],null));
$CLJS.Y(zna,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.t0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.ei],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.w6,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cI,$CLJS.Va],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vj,
$CLJS.ei],null)],null)],null)],null)],null));