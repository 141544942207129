var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var r7,Nna,Ona,Pna,t7,u7,v7,w7,Qna,Rna,z7,x7,y7,A7,B7,Sna,Tna,Una,C7,Vna,Wna,D7,Xna,Yna;r7=function(a,b){if(b=$CLJS.z(b)){var c=$CLJS.z(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.z(c)?(c=r7($CLJS.J.g(a,b),c),$CLJS.sd(c)?$CLJS.wk.g(a,b):$CLJS.S.j(a,b,c)):$CLJS.wk.g(a,b)}return a};
Nna=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.z(k);k=$CLJS.B(u);u=$CLJS.C(u);var v=$CLJS.Ul(f,k);return $CLJS.n(v)?u?$CLJS.S.j(f,k,t($CLJS.zb(v),u,l,m)):$CLJS.S.j(f,k,$CLJS.P.j(l,$CLJS.zb(v),m)):f}(a,b,c,d)};Ona=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Nna(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.y(b.slice(3),0,null):null)};$CLJS.s7=function(a){return $CLJS.JG.h($CLJS.QG(a))};
Pna=function(a,b,c,d){var e=$CLJS.F.g($CLJS.WR,$CLJS.B(b))?$CLJS.vY(d,$CLJS.uY(c)):d;return Ona(a,b,function(f){return $CLJS.uf(function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A);D=$CLJS.F.g($CLJS.s7(D),$CLJS.s7(c))?e:D;x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.F.g($CLJS.s7(x),
$CLJS.s7(c))?e:x,m($CLJS.Kc(t)))}return null}},null,null)}(f)}())})};
t7=function(a,b,c){var d=$CLJS.QC(a,b);if($CLJS.n(d)){var e=$CLJS.s7(c),f=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(b),$CLJS.id(b)],null);c=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);d=$CLJS.Wf.j($CLJS.tf,$CLJS.Fk.h($CLJS.Ek.g($CLJS.Vg([e]),$CLJS.s7)),d);d=$CLJS.F.g(b,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bH],null))&&$CLJS.Ie(function(k){return $CLJS.u4(k,$CLJS.DD)},d)?null:d;if($CLJS.z(d))return $CLJS.RN(a,b,d);if($CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AP,$CLJS.sN],null),new $CLJS.Q(null,2,5,$CLJS.R,
[c,f],null)))throw $CLJS.ai($CLJS.UG("Cannot remove the final join condition"),new $CLJS.h(null,1,[$CLJS.sN,$CLJS.QC(a,b)],null));return $CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AP,$CLJS.bH],null),new $CLJS.Q(null,2,5,$CLJS.R,[c,f],null))?$CLJS.oZ.v(a,$CLJS.rd(b),$CLJS.wk,f):r7(a,b)}return a};u7=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.pN);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null};
v7=function(a,b){var c=$CLJS.V4.g(a,b),d=$CLJS.dh(0,$CLJS.E(c));return $CLJS.Ye.l(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.LT],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gI],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.eY],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bH],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.hI],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.WR],null)],null),function(){return function k(f){return new $CLJS.le(null,function(){for(var l=f;;)if(l=$CLJS.z(l)){if($CLJS.zd(l)){var m=
$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.bd(c,x);$CLJS.bH.h(A)instanceof $CLJS.M||u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,x,$CLJS.bH],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);m=$CLJS.bd(c,u);if($CLJS.bH.h(m)instanceof $CLJS.M)l=$CLJS.Kc(l);else return $CLJS.ce(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,u,$CLJS.bH],null),k($CLJS.Kc(l)))}else return null},null,null)}(d)}(),$CLJS.G([function(){return function k(f){return new $CLJS.le(null,
function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,x,$CLJS.sN],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,u,$CLJS.sN],null),k($CLJS.Kc(l)))}return null}},null,null)}(d)}()]))};
w7=function(a,b,c){var d=$CLJS.H(c,0,null),e=$CLJS.H(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.yI),k=$CLJS.J.g(e,$CLJS.rT),l=$CLJS.H(c,2,null);return $CLJS.B($CLJS.DJ(function(m){$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);$CLJS.H(m,0,null);$CLJS.H(m,1,null);m=$CLJS.H(m,2,null);return $CLJS.F.g($CLJS.B(m),d)&&$CLJS.F.g($CLJS.yI.h($CLJS.hd(m)),f)&&$CLJS.F.g($CLJS.rT.h($CLJS.hd(m)),k)&&$CLJS.F.g($CLJS.id(m),l)},$CLJS.Sl($CLJS.zt,$CLJS.LT.h($CLJS.BY(a,b)))))};
Qna=function(a,b,c,d){c=w7(a,b,c);return $CLJS.n(c)?$CLJS.IY.l(a,b,$CLJS.oZ,$CLJS.G([new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.LT,c,2,1],null),$CLJS.Ek.g(function(e){return $CLJS.WC($CLJS.Ke($CLJS.ol),e)},$CLJS.Wk),d])):a};Rna=function(a,b,c){c=w7(a,b,c);return $CLJS.n(c)?$CLJS.IY.l(a,b,t7,$CLJS.G([new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.LT],null),$CLJS.QC($CLJS.BY(a,b),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LT,c],null))])):a};
z7=function(a,b,c,d,e,f){var k=$CLJS.IY.l(a,b,f,$CLJS.G([d,e])),l=$CLJS.s7(e);return $CLJS.Ck.g(a,k)?$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function(m,t){try{if($CLJS.yd(t)&&1===$CLJS.E(t))try{var u=$CLJS.bd(t,0);if($CLJS.fe(u,$CLJS.WR))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var K=$CLJS.uY(e);K=x7.W?x7.W(k,b,c,$CLJS.DD,$CLJS.N,K):x7.call(null,k,b,c,$CLJS.DD,$CLJS.N,K);return y7.v?y7.v(K,b,c,l):y7.call(null,K,b,c,l)}()],null);throw $CLJS.oX;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.oX)try{u=
$CLJS.bd(t,0);if($CLJS.fe(u,$CLJS.hI))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var U=x7.W?x7.W(k,b,c,$CLJS.hI,$CLJS.N,l):x7.call(null,k,b,c,$CLJS.hI,$CLJS.N,l);return y7.v?y7.v(U,b,c,l):y7.call(null,U,b,c,l)}()],null);throw $CLJS.oX;}catch(U){if(U instanceof Error){var v=U;if(v===$CLJS.oX)throw $CLJS.oX;throw v;}throw U;}else throw m;else throw K;}else throw $CLJS.oX;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.oX)try{if($CLJS.yd(t)&&1===$CLJS.E(t))try{var x=$CLJS.bd(t,0);if($CLJS.fe(x,
$CLJS.gI))return new $CLJS.Q(null,1,5,$CLJS.R,[y7.v?y7.v(k,b,c,l):y7.call(null,k,b,c,l)],null);throw $CLJS.oX;}catch(U){if(U instanceof Error)if(v=U,v===$CLJS.oX)try{x=$CLJS.bd(t,0);if($CLJS.fe(x,$CLJS.bH))return new $CLJS.Q(null,1,5,$CLJS.R,[y7.v?y7.v(k,b,c,l):y7.call(null,k,b,c,l)],null);throw $CLJS.oX;}catch(Z){if(Z instanceof Error){var A=Z;if(A===$CLJS.oX)throw $CLJS.oX;throw A;}throw Z;}else throw v;else throw U;}else throw $CLJS.oX;}catch(U){if(U instanceof Error)if(v=U,v===$CLJS.oX)try{if($CLJS.yd(t)&&
3===$CLJS.E(t))try{var D=$CLJS.bd(t,0);if($CLJS.fe(D,$CLJS.AP))try{var I=$CLJS.bd(t,2);if($CLJS.fe(I,$CLJS.bH))return new $CLJS.Q(null,1,5,$CLJS.R,[y7.v?y7.v(k,b,c,l):y7.call(null,k,b,c,l)],null);throw $CLJS.oX;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.oX)throw $CLJS.oX;throw A;}throw Z;}else throw $CLJS.oX;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.oX)throw $CLJS.oX;throw A;}throw Z;}else throw $CLJS.oX;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.oX)return new $CLJS.Q(null,1,
5,$CLJS.R,[k],null);throw A;}throw Z;}else throw v;else throw U;}else throw m;else throw K;}}($CLJS.tf,d)))):k};
x7=function(a,b,c,d,e,f){var k=$CLJS.BY(a,b),l=$CLJS.$e(function(m){var t=$CLJS.QC(k,m);return $CLJS.n(t)?$CLJS.Rl(function(u){return $CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function D(x,A){try{if($CLJS.yd(A)&&3===$CLJS.E(A))try{var I=$CLJS.bd(A,0);if($CLJS.F.g(I,d))try{var K=$CLJS.bd(A,1);if($CLJS.sd(e)||$CLJS.z2($CLJS.Yg(e),$CLJS.Yg(K)))try{var U=$CLJS.bd(A,2);if($CLJS.F.g(U,f))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null)],null);throw $CLJS.oX;}catch(da){if(da instanceof
Error){var Z=da;if(Z===$CLJS.oX)throw $CLJS.oX;throw Z;}throw da;}else throw $CLJS.oX;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.oX)throw $CLJS.oX;throw Z;}throw da;}else throw $CLJS.oX;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.oX)throw $CLJS.oX;throw Z;}throw da;}else throw $CLJS.oX;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.oX)return $CLJS.xX(D,x,A);throw Z;}throw da;}}($CLJS.tf,u))))},t):null},$CLJS.G([v7(a,b)]));return $CLJS.ab(function(m,t){var u=$CLJS.H(t,0,null);
t=$CLJS.H(t,1,null);return z7(m,b,c,u,t,t7)},a,l)};y7=function(a,b,c,d){b=u7(c,b);if($CLJS.n(b)){var e=$CLJS.BY(c,b);c=$CLJS.Je(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.QM),l=$CLJS.J.g(f,$CLJS.h2);return $CLJS.F.g($CLJS.p0,k)&&$CLJS.F.g(d,l)?$CLJS.C0.h(f):null},$CLJS.u2.j(c,b,e));return $CLJS.n(c)?x7(a,b,a,$CLJS.sI,$CLJS.N,c):a}return a};
A7=function(a,b,c,d,e){var f=$CLJS.nY.h(c),k=$CLJS.BY(a,b);c=$CLJS.DJ(function(t){var u=$CLJS.QC(k,t);if($CLJS.n(u)){var v=$CLJS.s7(f);return $CLJS.n($CLJS.Je($CLJS.Ek.j($CLJS.Vg([v]),$CLJS.JG,$CLJS.hd),u))?t:null}return null},v7(a,b));var l=(d=$CLJS.F.g($CLJS.RI,d))?$CLJS.nY.h(e):null;e=d?function(t,u,v){return Pna(t,u,v,l)}:t7;var m=$CLJS.F.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gI],null),c);a=d&&m&&$CLJS.F.g($CLJS.B(f),$CLJS.B(l))&&$CLJS.F.g($CLJS.id(f),$CLJS.id(l))?Qna(a,b,f,$CLJS.Tl($CLJS.hd(l),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rT,$CLJS.yI],null))):m?Rna(a,b,f):a;return $CLJS.n(c)?z7(a,b,a,c,f,e):a};B7=function(a,b){return $CLJS.u4(a,$CLJS.sI)&&$CLJS.F.g($CLJS.L2(a),b)};Sna=function(a,b,c){return function k(e,f){try{if(B7(f,b))return $CLJS.z4(f,c);throw $CLJS.oX;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.oX)return $CLJS.RY(k,e,f);throw l;}throw m;}}($CLJS.tf,a)};
Tna=function(a,b,c){var d=$CLJS.AP.h(a),e=-1<b&&b<$CLJS.E(d)?new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.QC(d,new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.fH],null))],null):null;b=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Ck.g(e,c):b)){var f=$CLJS.EY();$CLJS.t4(f,$CLJS.Ze.g($CLJS.fH,d));c=f(c);return Sna($CLJS.RN(a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,b,$CLJS.fH],null),c),e,c)}return a};
Una=function(a,b,c){if($CLJS.Ed(c))return c;var d=function(){var e=$CLJS.Vg([c]);return"string"===typeof c?$CLJS.Ek.g(e,$CLJS.fH):e}();return $CLJS.Je(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Sl($CLJS.zt,$CLJS.AP.h($CLJS.BY(a,b))))};
C7=function(a,b,c,d){b=$CLJS.wv.g($CLJS.Yg($CLJS.u2.j(b,c,$CLJS.BY(b,c))),$CLJS.Yg($CLJS.u2.j(a,c,$CLJS.BY(a,c))));return $CLJS.ab(function(e,f){return $CLJS.P.N(x7,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Vna=function(a,b,c){a=C7(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,new $CLJS.h(null,1,[$CLJS.qR,$CLJS.R0.h(d)],null),$CLJS.Ri.h(d)],null)});c=u7(a,c);return $CLJS.n(c)?C7(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,$CLJS.N,$CLJS.C0.h(d)],null)}):a};
Wna=function(a,b,c){return $CLJS.Ed(c)?$CLJS.QC($CLJS.BY(a,b),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,c,$CLJS.fH],null)):$CLJS.wd(c)?$CLJS.fH.h(c):c};D7=function(a,b,c,d){var e=Wna(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.IY)(a,b,function(k){var l=$CLJS.AP.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.PG(k,$CLJS.AP,l)});return(0,$CLJS.IY)(f,b,function(k){return $CLJS.MY(k,$CLJS.AP,function(l){return $CLJS.Gk.g(function(m){return $CLJS.H4(f,b,m)},l)})})}(),Vna(c,a,b)):a};
Xna=function(a,b){return null!=$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function f(d,e){try{if(B7(e,b))return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.oX;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.oX)return $CLJS.xX(f,d,e);throw k;}throw l;}}($CLJS.tf,a))))};Yna=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.Zna=function(){function a(d,e,f){return $CLJS.wd(f)&&$CLJS.F.g($CLJS.wj.h(f),$CLJS.kN)?$CLJS.E7.j?$CLJS.E7.j(d,e,f):$CLJS.E7.call(null,d,e,f):A7(d,e,f,Yna,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.G7=function(){function a(d,e,f,k){return $CLJS.wd(f)&&$CLJS.F.g($CLJS.wj.h(f),$CLJS.kN)?$CLJS.F7.v?$CLJS.F7.v(d,e,f,k):$CLJS.F7.call(null,d,e,f,k):A7(d,e,f,$CLJS.RI,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.$na=function(){function a(d,e,f,k){f=Una(d,e,f);return $CLJS.n(f)?$CLJS.IY.l(d,e,Tna,$CLJS.G([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.E7=function(){function a(d,e,f){return D7(d,e,f,function(k,l){return $CLJS.De($CLJS.u3(function(m){return!($CLJS.F.g($CLJS.fH.h(m),l)||Xna(m,l))},k))})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.F7=function(){function a(d,e,f,k){return null==k?$CLJS.E7.j(d,e,f):D7(d,e,f,function(l,m){return $CLJS.Gk.g(function(t){return $CLJS.F.g($CLJS.fH.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();