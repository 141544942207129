var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var rX,uX;$CLJS.pX=function(a){return a instanceof Error?a.message:null};
$CLJS.qX=function(a,b,c){if($CLJS.Yd(c)){var d=$CLJS.P.g($CLJS.W,$CLJS.Ze.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.qf(c)?(d=new $CLJS.Pf(function(){var e=$CLJS.yb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.zb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Cd(c)?(d=$CLJS.eh($CLJS.Ze.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.xd(c)?(d=$CLJS.ab(function(e,f){return $CLJS.$d.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.td(c)?
(d=$CLJS.Wf.g($CLJS.jd(c),$CLJS.Ze.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.sX=function(a){function b(c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return"string"===typeof d?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rh.h(d),c],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null)}return rX(function(c){return $CLJS.wd(c)?$CLJS.Wf.g($CLJS.N,$CLJS.Ze.g(b,c)):c},a)};$CLJS.tX=function(a){var b=new $CLJS.Ea;for(a=$CLJS.z(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.B(a))),a=$CLJS.C(a);else return b.toString()};
$CLJS.vX=function(a,b,c){a=$CLJS.TC(a,/''/,"'");var d=$CLJS.av(a,uX);d=$CLJS.F.g($CLJS.E(d),1)?new $CLJS.Q(null,2,5,$CLJS.R,[a,""],null):d;a=$CLJS.gh(/.*\{0\}.*/,a);return $CLJS.yu.ngettext($CLJS.yu.msgid($CLJS.Zk(d),$CLJS.n(a)?c:""),$CLJS.TC($CLJS.TC(b,/''/,"'"),uX,$CLJS.p.h(c)),c)};$CLJS.wX=function(a,b){return $CLJS.ab(function(c,d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.Gd(a,e)?$CLJS.S.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.P.j($CLJS.wk,a,$CLJS.Pg(b)),b)};
$CLJS.xX=function(a,b,c){return $CLJS.wd(c)?$CLJS.$e(function(d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);e=$CLJS.$d.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.G([c])):$CLJS.vd(c)?$CLJS.$e($CLJS.Me(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.$d.g(b,$CLJS.B(c)):b),$CLJS.G([c])):null};rX=function rX(a,b){return $CLJS.qX($CLJS.Me(rX,a),a,b)};uX=/\{0\}/;$CLJS.yX=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);
$CLJS.zX=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.AX=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var Vha=$CLJS.Ag([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rL,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hL,$CLJS.aL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hL,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oL,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gL,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kL,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lL,null],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.hL,$CLJS.SK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hL,$CLJS.fL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UK,null],null)],[$CLJS.ti,$CLJS.iJ,$CLJS.sL,$CLJS.YK,$CLJS.pi,$CLJS.yj,$CLJS.aj,$CLJS.ck,$CLJS.zi,$CLJS.YK,$CLJS.tL,$CLJS.fJ]),CX;$CLJS.Yg($CLJS.Ze.g($CLJS.B,$CLJS.Pg(Vha)));var BX,Wha=$CLJS.Oe($CLJS.N),Xha=$CLJS.Oe($CLJS.N),Yha=$CLJS.Oe($CLJS.N),Zha=$CLJS.Oe($CLJS.N),$ha=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
BX=new $CLJS.Xh($CLJS.xh.g("metabase.mbql.util","negate*"),$CLJS.B,$ha,Wha,Xha,Yha,Zha);BX.m(null,$CLJS.it,function(a){$CLJS.H(a,0,null);return $CLJS.H(a,1,null)});BX.m(null,$CLJS.mt,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gt],null),$CLJS.Ze.g(BX,a))});BX.m(null,$CLJS.gt,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.mt],null),$CLJS.Ze.g(BX,a))});
BX.m(null,$CLJS.hk,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KI,b,a],null)});BX.m(null,$CLJS.KI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hk,b,a],null)});BX.m(null,$CLJS.As,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Gs,b,a],null)});
BX.m(null,$CLJS.Es,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Cs,b,a],null)});BX.m(null,$CLJS.Cs,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Es,b,a],null)});BX.m(null,$CLJS.Gs,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.As,b,a],null)});
BX.m(null,$CLJS.UH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Es,b,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.As,b,a],null)],null)});BX.m(null,$CLJS.rI,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.it,a],null)});BX.m(null,$CLJS.jI,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.it,a],null)});
BX.m(null,$CLJS.DI,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.it,a],null)});
CX=function CX(a){return $CLJS.wd(a)?$CLJS.De($CLJS.Wf.g($CLJS.N,function(){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=CX.h?CX.h(u):CX.call(null,u);null!=u&&m.add(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);
m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=CX.h?CX.h(k):CX.call(null,k);if(null!=k)return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[m,k],null),e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(a)}())):$CLJS.vd(a)?$CLJS.De($CLJS.Wf.g($CLJS.jd(a),$CLJS.bf($CLJS.Ta,$CLJS.Ze.g(CX,a)))):a};
$CLJS.DX=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.H(c,0,null),k=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);d=$CLJS.De(CX($CLJS.P.j(d,c,e)));return $CLJS.n(d)?new $CLJS.Q(null,3,5,$CLJS.R,[f,k,d],null):$CLJS.F.g(f,$CLJS.sI)?new $CLJS.Q(null,3,5,$CLJS.R,[f,k,null],null):new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();