var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var oY,oia,pia,wY,qia,ria,tia,sY,uia,sia;oY=function(a){return a+1};$CLJS.pY=function(a){if("string"===typeof a){var b=$CLJS.fh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.Xl(a));};
oia=function(a){var b=new $CLJS.Ea,c=$CLJS.Pv;$CLJS.Pv=new $CLJS.sc(b);try{var d=$CLJS.Pv,e=$CLJS.Sa($CLJS.fx(d)),f=$CLJS.Pv;$CLJS.Pv=e?$CLJS.Xw(d):d;try{d=$CLJS.hx;$CLJS.hx=!0;try{$CLJS.jx(a)}finally{$CLJS.hx=d}$CLJS.F.g(0,$CLJS.Xv($CLJS.Pv,$CLJS.Zv))||$CLJS.$b($CLJS.Pv,"\n");$CLJS.Wv()}finally{$CLJS.Pv=f}$CLJS.mh($CLJS.p.h(b))}finally{$CLJS.Pv=c}};$CLJS.qY=function(a,b){return $CLJS.P.j($CLJS.ha,a,b)};
$CLJS.rY=function(a){var b=new $CLJS.Ea,c=$CLJS.Oa,d=$CLJS.Pa;$CLJS.Oa=!0;$CLJS.Pa=function(e){return b.append(e)};try{oia(a)}finally{$CLJS.Pa=d,$CLJS.Oa=c}return $CLJS.p.h(b)};
pia=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.AX,$CLJS.Rd),c=$CLJS.J.j(a,$CLJS.yX,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Oe($CLJS.N),e=$CLJS.Oe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),D=$CLJS.q(d);return D.h?D.h(A):D.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Gh.v(e,$CLJS.Hk,u,$CLJS.fG(oY,0)),u);if($CLJS.F.g(x,1))return $CLJS.Gh.v(d,$CLJS.S,
new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),t),t;t=function(){var A=t,D=$CLJS.p.h(x);return c.g?c.g(A,D):c.call(null,A,D)}()}}function k(m){var t=l,u=t.g;null==sY&&(sY=$CLJS.Oe(0));var v=$CLJS.xh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Gh.g(sY,oY))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.tY=function(a){return $CLJS.yd(a)&&1<$CLJS.E(a)&&$CLJS.B(a)instanceof $CLJS.M&&$CLJS.wd($CLJS.hd(a))&&$CLJS.Gd($CLJS.hd(a),$CLJS.JG)};$CLJS.uY=function(a){return $CLJS.tY(a)?$CLJS.QC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,$CLJS.jH],null)):null};$CLJS.vY=function(a,b){return $CLJS.RN($CLJS.tY(a)?a:new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vj,new $CLJS.h(null,2,[$CLJS.JG,$CLJS.p.h($CLJS.TG()),$CLJS.yi,$CLJS.XG(a)],null),a],null),new $CLJS.Q(null,2,5,$CLJS.R,[1,$CLJS.jH],null),b)};
wY=function(a,b,c){var d=$CLJS.Gd(a,b)?$CLJS.Hk.j(a,b,function(e){return $CLJS.yd(e)&&$CLJS.F.g($CLJS.B(e),$CLJS.mt)?$CLJS.uf($CLJS.Te(1,e)):new $CLJS.Q(null,1,5,$CLJS.R,[e],null)}):a;return $CLJS.Gd(a,b)?$CLJS.wX(d,$CLJS.Ce([b,c])):d};qia=function(a){var b=$CLJS.Tl(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bR,$CLJS.FS],null));b=xY.h?xY.h(b):xY.call(null,b);return $CLJS.lY($CLJS.S.l(wY($CLJS.wk.l(a,$CLJS.bR,$CLJS.G([$CLJS.FS])),$CLJS.NT,$CLJS.sN),$CLJS.wj,$CLJS.kN,$CLJS.G([$CLJS.pN,b])))};
ria=function(a){return $CLJS.Gk.g(qia,a)};tia=function(a){return $CLJS.n(a)?$CLJS.S.j($CLJS.Hk.j($CLJS.Al(a)?new $CLJS.h(null,1,[$CLJS.yY,a],null):a,$CLJS.yY,function(b){return $CLJS.Gk.g(function(c){return $CLJS.S.j($CLJS.RC(c,$CLJS.rD),$CLJS.wj,$CLJS.OM)},b)}),$CLJS.wj,sia):null};
$CLJS.zY=function(a){if($CLJS.F.g($CLJS.wj.h(a),$CLJS.dY))return a;var b=$CLJS.ej.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.Wk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.wj,$CLJS.dY,$CLJS.pN,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Wk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.wj,$CLJS.YX],null),$CLJS.wX($CLJS.gS.h(a),new $CLJS.h(null,1,[$CLJS.EO,$CLJS.gS],null))]))],null)],null),$CLJS.wk.l(a,$CLJS.ej,$CLJS.G([$CLJS.gS]))]));case "query":return $CLJS.Wk.l($CLJS.G([new $CLJS.h(null,
2,[$CLJS.wj,$CLJS.dY,$CLJS.pN,xY($CLJS.EO.h(a))],null),$CLJS.wk.l(a,$CLJS.ej,$CLJS.G([$CLJS.EO]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.AY=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.pN);var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.ai($CLJS.uG("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.h(null,1,[uia,$CLJS.E(a)],null));return c};
$CLJS.BY=function(a,b){a=$CLJS.zY(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.pN);return $CLJS.J.g($CLJS.uf(c),$CLJS.AY(a,b))};$CLJS.CY=function(a){return"string"===typeof a&&(a=$CLJS.gh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.H(a,0,null),a=$CLJS.H(a,1,null),$CLJS.pY(a)):null};$CLJS.EY=function(){return $CLJS.Ek.g(DY,pia($CLJS.G([$CLJS.AX,$CLJS.nD,$CLJS.yX,function(a,b){return DY.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};sY=null;$CLJS.FY=new $CLJS.M(null,"stage-number","stage-number",-1752729638);
$CLJS.GY=new $CLJS.M(null,"dimensions","dimensions",-254818097);uia=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.yY=new $CLJS.M(null,"columns","columns",1998437288);$CLJS.HY=new $CLJS.M(null,"table","table",-564943036);sia=new $CLJS.M("metadata","results","metadata/results",330329298);var xY,DY;
xY=function xY(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.FS),d=$CLJS.J.g(a,$CLJS.OQ);c=$CLJS.n(c)?xY.h?xY.h(c):xY.call(null,c):$CLJS.tf;d=tia(d);var e=$CLJS.E(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.RN(c,new $CLJS.Q(null,2,5,$CLJS.R,[e,$CLJS.jX],null),d):c;d=$CLJS.n($CLJS.gS.h(a))?$CLJS.YX:$CLJS.RX;a=$CLJS.Wk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.wj,d],null),$CLJS.wk.l(a,$CLJS.FS,$CLJS.G([$CLJS.OQ]))]));a=$CLJS.z($CLJS.AP.h(a))?$CLJS.Hk.j(a,$CLJS.AP,ria):a;a=wY(a,$CLJS.WH,$CLJS.eY);return $CLJS.$d.g(c,a)};
$CLJS.IY=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.y(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.zY(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.pN);d=$CLJS.AY(c,d);e=$CLJS.P.N($CLJS.Hk,$CLJS.uf(k),d,e,f);return $CLJS.S.j(c,$CLJS.pN,e)}a.A=3;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.C(c);var f=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,f,c)};a.l=b;
return a}();
DY=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.jY.str(d,0)>>>0).toString(16);;)if(8>$CLJS.E(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.JY=RegExp(" id$","i");