var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Yca,Zca,$ca,ada,bda,cda,dda,eda,fda;Yca=function(a,b){a.sort(b||$CLJS.Da)};Zca=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;Yca(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.cJ=function(a){return $CLJS.F.g(a,$CLJS.Hd)?$CLJS.Hd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.dJ=function(a,b){if($CLJS.z(b)){var c=$CLJS.ue.h?$CLJS.ue.h(b):$CLJS.ue.call(null,b);Zca(c,$CLJS.cJ(a));return $CLJS.od($CLJS.z(c),$CLJS.pd(b))}return $CLJS.Jc};$CLJS.eJ=new $CLJS.M(null,"second","second",-444702010);$CLJS.fJ=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.gJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.hJ=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.iJ=new $CLJS.M(null,"year-of-era","year-of-era",682445876);$ca=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
ada=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.jJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.kJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.lJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);bda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);cda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
dda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);eda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.mJ=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.nJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);
$CLJS.oJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);fda=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var pJ=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.zi,$CLJS.yj,$CLJS.xj,$CLJS.ji,$CLJS.ck,$CLJS.ti,$CLJS.ci,$CLJS.iJ],null),qJ=$CLJS.Yg(pJ),gda,hda,ida;$CLJS.Y(dda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid date extraction unit"],null)],null),qJ));var rJ=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.nv,$CLJS.gj,$CLJS.pj,$CLJS.ii,$CLJS.ci],null),sJ=$CLJS.Yg(rJ);
$CLJS.Y(cda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid date truncation unit"],null)],null),sJ));$CLJS.tJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Yk.o(),$CLJS.Ye.g(rJ,pJ));gda=$CLJS.Yg($CLJS.tJ);$CLJS.Y($CLJS.lJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid date bucketing unit"],null)],null),gda));var uJ=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fJ,$CLJS.aj,$CLJS.pi],null),vJ=$CLJS.Yg(uJ);
$CLJS.Y(bda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid time extraction unit"],null)],null),vJ));var wJ=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.hJ,$CLJS.eJ,$CLJS.lv,$CLJS.mv],null),xJ=$CLJS.Yg(wJ);$CLJS.Y(fda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid time truncation unit"],null)],null),xJ));$CLJS.yJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Yk.o(),$CLJS.Ye.g(wJ,uJ));hda=$CLJS.Yg($CLJS.yJ);
$CLJS.Y(ada,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid time bucketing unit"],null)],null),hda));$CLJS.zJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Yk.o(),$CLJS.Ye.l(wJ,rJ,$CLJS.G([uJ,pJ])));ida=$CLJS.Yg($CLJS.zJ);$CLJS.Y($CLJS.gJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid datetime bucketing unit"],null)],null),$CLJS.zJ));var jda=$CLJS.$d.g(ida,$CLJS.Wh);
$CLJS.Y($CLJS.nJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid temporal bucketing unit"],null)],null),jda));var kda=$CLJS.ov.g(sJ,xJ);$CLJS.Y($CLJS.jJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid datetime truncation unit"],null)],null),kda));$CLJS.AJ=$CLJS.ov.g(qJ,vJ);$CLJS.Y($ca,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid datetime extraction unit"],null)],null),$CLJS.AJ));
var BJ=$CLJS.$d.g(sJ,$CLJS.ci);$CLJS.Y($CLJS.oJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid date interval unit"],null)],null),BJ));$CLJS.Y($CLJS.kJ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid time interval unit"],null)],null),xJ));var lda=$CLJS.ov.g(BJ,xJ);$CLJS.Y($CLJS.sG,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.tu,"Valid datetime interval unit"],null)],null),lda));
$CLJS.Y(eda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.mJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,$CLJS.nJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null)],null));