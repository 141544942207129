var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var j9,l9,k9,fsa,r9,s9,gsa,hsa,isa,m9,jsa,x9,ksa,lsa,msa;j9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ca(a,b):null};l9=function(a,b){return k9(a,b,$CLJS.N)};
k9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,m9,!0);if($CLJS.wd(b))return $CLJS.Wf.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=j9(b,"_plainObject");d=null==d?null:$CLJS.eC(d);return null==d?null:$CLJS.De(d)}():null;return $CLJS.n(c)?$CLJS.Wf.j($CLJS.N,a,c):$CLJS.Wf.j($CLJS.N,$CLJS.Ek.j($CLJS.Ze.h(function(d){return new $CLJS.Q(null,2,5,$CLJS.R,[d,j9(b,d)],null)}),$CLJS.Fk.h(function(d){$CLJS.H(d,0,null);return"function"===typeof $CLJS.H(d,1,null)}),a),$CLJS.Ba(b))};
fsa=function(a){var b=n9.h(a),c=o9.h(a),d=p9.h(a);return $CLJS.Ek.j($CLJS.Ze.h(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);var k=$CLJS.R;f=$CLJS.rh.h((0,$CLJS.rD)(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.Q(null,2,5,k,[f,e],null)}),$CLJS.sd(b)?$CLJS.Rd:$CLJS.Fk.h(function(e){var f=$CLJS.H(e,0,null);$CLJS.H(e,1,null);return $CLJS.Gd(b,f)}),$CLJS.Sa(c)?$CLJS.Rd:$CLJS.Ze.h(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return new $CLJS.Q(null,
2,5,$CLJS.R,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
r9=function(a,b){var c=fsa(a),d=q9.h(a);return function(e){try{var f=$CLJS.S.j(k9(c,e,b),$CLJS.wj,d),k=$CLJS.ZC($CLJS.TA);$CLJS.n($CLJS.YC("metabase.lib.js.metadata",k))&&$CLJS.XC("metabase.lib.js.metadata",k,$CLJS.qY("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Ri.h(f),$CLJS.rY(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.ZC($CLJS.UA),$CLJS.n($CLJS.YC("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.XC("metabase.lib.js.metadata",k,$CLJS.qY("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.Eh.l($CLJS.G([e])),$CLJS.pX(f)])),f):$CLJS.XC("metabase.lib.js.metadata",k,$CLJS.qY(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.Eh.l($CLJS.G([e])),$CLJS.pX(f)])),null)),null;throw l;}}};s9=function(a){return $CLJS.Gk.g(r9($CLJS.sI,$CLJS.N),a)};gsa=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.t9=new $CLJS.M(null,"cards","cards",169174038);hsa=new $CLJS.M(null,"db","db",993250759);$CLJS.u9=new $CLJS.M(null,"metrics","metrics",394093469);
isa=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.v9=new $CLJS.M(null,"segments","segments",1937535949);m9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);jsa=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.w9=new $CLJS.M(null,"tables","tables",1334623052);x9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);ksa=new $CLJS.M(null,"schema-name","schema-name",1666725119);lsa=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);
msa=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.y9=new $CLJS.M(null,"databases","databases",2040134125);var n9,nsa=$CLJS.Oe($CLJS.N),osa=$CLJS.Oe($CLJS.N),psa=$CLJS.Oe($CLJS.N),qsa=$CLJS.Oe($CLJS.N),rsa=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null)),A9,Hsa,Isa,Jsa,Ksa,Lsa;n9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.rh,rsa,nsa,osa,psa,qsa);n9.m(null,$CLJS.Wh,function(){return null});var o9,ssa=$CLJS.Oe($CLJS.N),tsa=$CLJS.Oe($CLJS.N),usa=$CLJS.Oe($CLJS.N),vsa=$CLJS.Oe($CLJS.N),wsa=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
o9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.rh,wsa,ssa,tsa,usa,vsa);o9.m(null,$CLJS.Wh,function(){return null});var q9,xsa=$CLJS.Oe($CLJS.N),ysa=$CLJS.Oe($CLJS.N),zsa=$CLJS.Oe($CLJS.N),Asa=$CLJS.Oe($CLJS.N),Bsa=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));q9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","lib-type"),$CLJS.rh,Bsa,xsa,ysa,zsa,Asa);
var p9,Csa=$CLJS.Oe($CLJS.N),Dsa=$CLJS.Oe($CLJS.N),Esa=$CLJS.Oe($CLJS.N),Fsa=$CLJS.Oe($CLJS.N),Gsa=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));p9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Rd,Gsa,Csa,Dsa,Esa,Fsa);p9.m(null,$CLJS.Wh,function(){return null});
$CLJS.z9=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.rh.h(f)},e,a,b,c,d)}();Hsa=$CLJS.Oe($CLJS.N);Isa=$CLJS.Oe($CLJS.N);Jsa=$CLJS.Oe($CLJS.N);Ksa=$CLJS.Oe($CLJS.N);Lsa=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
A9=new $CLJS.Xh($CLJS.xh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.rh,Lsa,Hsa,Isa,Jsa,Ksa);$CLJS.z9.m(null,$CLJS.Wh,function(a,b){var c=r9(a,$CLJS.N);return l9($CLJS.Ze.h(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pY(e),new $CLJS.Bh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),j9(b,A9.h(a)))});q9.m(null,$CLJS.RR,function(){return $CLJS.eN});
n9.m(null,$CLJS.RR,function(){return new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.bH,null,$CLJS.w9,null],null),null)});o9.m(null,$CLJS.RR,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.dC(b,$CLJS.G([$CLJS.si,!0]));case "features":return $CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h($CLJS.rh),b);case "native-permissions":return $CLJS.rh.h(b);default:return b}}});A9.m(null,$CLJS.RR,function(){return"databases"});q9.m(null,$CLJS.HY,function(){return $CLJS.VM});
n9.m(null,$CLJS.HY,function(){return new $CLJS.Tg(null,new $CLJS.h(null,5,[x9,null,$CLJS.bH,null,$CLJS.v9,null,$CLJS.RR,null,$CLJS.u9,null],null),null)});o9.m(null,$CLJS.HY,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.rh.h(b);case "field-order":return $CLJS.rh.h(b);case "initial-sync-status":return $CLJS.rh.h(b);case "visibility-type":return $CLJS.rh.h(b);default:return b}}});
$CLJS.z9.m(null,$CLJS.HY,function(a,b){var c=r9(a,$CLJS.N);return l9($CLJS.Ek.g($CLJS.Fk.h(function(d){var e=$CLJS.H(d,0,null);$CLJS.H(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.Ze.h(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pY(e),new $CLJS.Bh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),j9(b,"tables"))});q9.m(null,$CLJS.sI,function(){return $CLJS.OM});
n9.m(null,$CLJS.sI,function(){return new $CLJS.Tg(null,new $CLJS.h(null,7,[x9,null,$CLJS.HY,null,$CLJS.GY,null,msa,null,$CLJS.RR,null,lsa,null,$CLJS.u9,null],null),null)});p9.m(null,$CLJS.sI,function(){return new $CLJS.h(null,1,[$CLJS.qC,$CLJS.QM],null)});
o9.m(null,$CLJS.sI,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.rh.h(b);case "coercion-strategy":return $CLJS.rh.h(b);case "effective-type":return $CLJS.rh.h(b);case "fingerprint":return $CLJS.wd(b)?$CLJS.sX(b):$CLJS.dC(b,$CLJS.G([$CLJS.si,!0]));case "has-field-values":return $CLJS.rh.h(b);case "lib/source":return $CLJS.F.g(b,"aggregation")?$CLJS.iN:$CLJS.rh.g("source",b);case "semantic-type":return $CLJS.rh.h(b);case "visibility-type":return $CLJS.rh.h(b);
case "id":return $CLJS.yd(b)?$CLJS.hd(b):b;default:return b}}});
$CLJS.z9.m(null,$CLJS.sI,function(a,b){var c=r9(a,$CLJS.N),d=j9(b,"fields");return l9($CLJS.Ll(function(e){var f=$CLJS.H(e,0,null),k=$CLJS.H(e,1,null);e=function(){var l=$CLJS.pY(f);if($CLJS.n(l))return l;l=$CLJS.fh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.H(l,0,null);l=$CLJS.H(l,1,null);var m=null==j9(d,l);return m?$CLJS.pY(l):m}return null}();return $CLJS.n(e)?new $CLJS.Q(null,2,5,$CLJS.R,[e,new $CLJS.Bh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});q9.m(null,$CLJS.sP,function(){return $CLJS.hN});
n9.m(null,$CLJS.sP,function(){return new $CLJS.Tg(null,new $CLJS.h(null,11,[x9,null,$CLJS.el,null,isa,null,$CLJS.HY,null,hsa,null,gsa,null,$CLJS.v9,null,$CLJS.RR,null,jsa,null,$CLJS.u9,null,ksa,null],null),null)});
o9.m(null,$CLJS.sP,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.PC($CLJS.vd,$CLJS.Ra)(b))?s9(b):$CLJS.dC(b,$CLJS.G([$CLJS.si,!0]));case "fields":return s9(b);case "visibility-type":return $CLJS.rh.h(b);case "dataset-query":return $CLJS.dC(b,$CLJS.G([$CLJS.si,!0]));case "dataset":return b;default:return b}}});
$CLJS.z9.m(null,$CLJS.sP,function(a,b){return $CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(c){return new $CLJS.Q(null,2,5,$CLJS.R,[c,new $CLJS.Bh(function(){var d=r9($CLJS.sP,new $CLJS.h(null,1,[m9,!1],null)),e=r9($CLJS.sP,$CLJS.N),f=$CLJS.Wk.l;d=$CLJS.S.j(d(j9(j9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.Ri,c);var k=j9(j9(b,"questions"),$CLJS.p.h(c));var l=j9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.Wk,$CLJS.G([d,e]))})],null)}),$CLJS.Wf.j($CLJS.Wf.j($CLJS.Ug,
$CLJS.Ll($CLJS.CY),$CLJS.Ba(j9(b,"tables"))),$CLJS.Ze.h($CLJS.pY),$CLJS.Ba(j9(b,"questions"))))});q9.m(null,$CLJS.nI,function(){return $CLJS.UM});n9.m(null,$CLJS.nI,function(){return new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.HY,null,$CLJS.RR,null],null),null)});o9.m(null,$CLJS.nI,function(){return function(a,b){return b}});A9.m(null,$CLJS.nI,function(){return"metrics"});q9.m(null,$CLJS.QH,function(){return $CLJS.SM});
n9.m(null,$CLJS.QH,function(){return new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.HY,null,$CLJS.RR,null],null),null)});o9.m(null,$CLJS.QH,function(){return function(a,b){return b}});A9.m(null,$CLJS.QH,function(){return"segments"});$CLJS.B9=r9($CLJS.sI,$CLJS.N);