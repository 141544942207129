var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var gG,hG,iG,yca,jG,lG,mG;
$CLJS.fG=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.P.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};gG=function(a,b){this.db=a;this.vh=b;this.C=393216;this.I=0};hG=function(a){for(var b=null;;){var c;a=$CLJS.lo.g(a,b);a=c=$CLJS.En(a)?$CLJS.yn(a):a;if(!$CLJS.En(c))return a}};iG=function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return!($CLJS.wd(a)&&!0===$CLJS.Ct.h(a))};
yca=function(a,b,c){var d=hG($CLJS.lo.g(a,c));return $CLJS.rq($CLJS.pn(d),$CLJS.mn(d),function(){var e=$CLJS.on(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.nn($CLJS.lo.g(d,null));return $CLJS.n(e)?e:c}())};jG=function(a){return function(b,c,d){var e=$CLJS.z(c);b=$CLJS.B(e);e=$CLJS.C(e);c=$CLJS.Gk.g(function(f){return $CLJS.lo.g(f,d)},c);return new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.Gk.g($CLJS.au,c),$CLJS.ab(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.kG=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,D,I,K,U,Z){this.form=a;this.options=b;this.Me=c;this.forms=d;this.U=e;this.Xd=f;this.ya=k;this.children=l;this.min=m;this.Eb=t;this.Rb=u;this.parent=v;this.type=x;this.Ue=A;this.Li=D;this.qe=I;this.cache=K;this.max=U;this.Uh=Z;this.C=393216;this.I=0};lG=function(a,b,c,d,e,f,k,l,m){this.Ue=a;this.qe=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Me=m;this.C=393216;this.I=0};
mG=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.ej),d=$CLJS.J.g(b,$CLJS.Mn),e=$CLJS.J.g(b,$CLJS.ak),f=$CLJS.J.g(b,$CLJS.wu),k=$CLJS.J.g(b,$CLJS.Tp),l=$CLJS.J.g(b,$CLJS.dt);return new lG(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.ej,$CLJS.yp],null))};$CLJS.Y=function(a,b){$CLJS.Gh.v(nG,$CLJS.S,a,b);$CLJS.Pe($CLJS.oG,$CLJS.N);return null};gG.prototype.P=function(a,b){return new gG(this.db,b)};gG.prototype.O=function(){return this.vh};gG.prototype.rd=$CLJS.r;
gG.prototype.bd=function(a,b){return $CLJS.$m($CLJS.bn($CLJS.q(this.db)),b)};var pG=function pG(a){switch(arguments.length){case 2:return pG.g(arguments[0],arguments[1]);case 3:return pG.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};pG.g=function(a,b){return pG.j(a,b,null)};
pG.j=function(a,b,c){function d(v,x){return $CLJS.F.g($CLJS.Zj,v)?new $CLJS.Q(null,2,5,$CLJS.R,[null,x],null):$CLJS.Ye.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Gq.h(x)],null),$CLJS.Iq.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.Wk.l($CLJS.G([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?hG($CLJS.lo.g(a,c)):null;b=$CLJS.n(b)?hG($CLJS.lo.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.Sn.h(a):null,k=$CLJS.n(b)?$CLJS.Sn.h(b):null,l=$CLJS.O(c),m=$CLJS.J.j(l,$CLJS.ru,function(v,x){return x}),t=$CLJS.J.j(l,$CLJS.uu,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Sa(function(){var v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.mt,null,$CLJS.Zj,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.mt,null,$CLJS.Zj,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.F.g(f,$CLJS.Zj)&&$CLJS.F.g(k,$CLJS.Zj)){l=e($CLJS.mn(a),$CLJS.mn(b));var u=$CLJS.Oe($CLJS.Ug);a=$CLJS.ab(function(v,x){var A=
$CLJS.H(x,0,null);if($CLJS.n(function(){var D=$CLJS.q(u);return D.h?D.h(A):D.call(null,A)}()))return $CLJS.ab(function(D,I){var K=$CLJS.H(I,0,null),U=$CLJS.$d.g;if($CLJS.F.g(K,A)){K=$CLJS.H(I,0,null);var Z=$CLJS.H(I,1,null),da=$CLJS.H(I,2,null);$CLJS.H(x,0,null);var xa=$CLJS.H(x,1,null),Ha=$CLJS.H(x,2,null);I=iG(I);var mb=iG(x);I=t.g?t.g(I,mb):t.call(null,I,mb);Z=$CLJS.Wk.l($CLJS.G([Z,xa]));Ha=new $CLJS.Q(null,3,5,$CLJS.R,[K,$CLJS.S.j(Z,$CLJS.Ct,$CLJS.Sa(I)),pG.j?pG.j(da,Ha,c):pG.call(null,da,Ha,
c)],null);K=$CLJS.H(Ha,0,null);da=$CLJS.H(Ha,1,null);Ha=$CLJS.H(Ha,2,null);K=$CLJS.Sa(Ha)?new $CLJS.Q(null,2,5,$CLJS.R,[K,da],null):$CLJS.n($CLJS.n(da)?!1===$CLJS.Ct.h(da)&&$CLJS.F.g(1,$CLJS.E(da)):da)?new $CLJS.Q(null,2,5,$CLJS.R,[K,Ha],null):$CLJS.Sa($CLJS.z(da))?new $CLJS.Q(null,2,5,$CLJS.R,[K,Ha],null):!1===$CLJS.Ct.h(da)?new $CLJS.Q(null,3,5,$CLJS.R,[K,$CLJS.wk.g(da,$CLJS.Ct),Ha],null):new $CLJS.Q(null,3,5,$CLJS.R,[K,da,Ha],null)}else K=I;return U.call($CLJS.$d,D,K)},$CLJS.tf,v);$CLJS.Gh.j(u,
$CLJS.$d,A);return $CLJS.$d.g(v,x)},$CLJS.tf,$CLJS.Wf.g($CLJS.on(a),$CLJS.on(b)));return $CLJS.rq($CLJS.Zj,l,a,c)}return function(v,x){var A=$CLJS.z(v);v=$CLJS.B(A);var D=$CLJS.C(A);A=$CLJS.B(D);D=$CLJS.C(D);var I=$CLJS.z(x);x=$CLJS.B(I);var K=$CLJS.C(I);I=$CLJS.B(K);K=$CLJS.C(K);return $CLJS.rq($CLJS.mt,e(v,x),$CLJS.Ye.l(new $CLJS.Q(null,1,5,$CLJS.R,[pG.j(A,I,c)],null),D,$CLJS.G([K])),c)}(d(f,a),d(k,b))};pG.A=3;
var qG=function qG(a){switch(arguments.length){case 2:return qG.g(arguments[0],arguments[1]);case 3:return qG.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};qG.g=function(a,b){return qG.j(a,b,null)};
qG.j=function(a,b,c){return pG.j(a,b,$CLJS.Hk.j($CLJS.Hk.j(c,$CLJS.ru,$CLJS.fG($CLJS.Rd,function(d,e,f){var k=$CLJS.F.g($CLJS.au.g(d,null),$CLJS.au.g(e,null));return $CLJS.n(k)?d:$CLJS.lo.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,d,e],null),f)})),$CLJS.uu,$CLJS.fG($CLJS.Rd,function(d,e){return $CLJS.n(d)?e:d})))};qG.A=3;
var rG=function rG(a){switch(arguments.length){case 2:return rG.g(arguments[0],arguments[1]);case 3:return rG.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};rG.g=function(a,b){return rG.j(a,b,null)};rG.j=function(a,b,c){var d=$CLJS.Yg(b);return yca(a,function(e){return $CLJS.bf(function(f){f=$CLJS.H(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};rG.A=3;$CLJS.g=$CLJS.kG.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.kG(this.form,this.options,this.Me,this.forms,this.U,this.Xd,this.ya,this.children,this.min,this.Eb,this.Rb,this.parent,this.type,this.Ue,this.Li,this.qe,this.cache,this.max,b)};$CLJS.g.O=function(){return this.Uh};$CLJS.g.Ya=$CLJS.r;$CLJS.g.xa=function(){return $CLJS.jn(this.ya)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.Uo(this,new $CLJS.Q(null,1,5,$CLJS.R,[this.ya],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.ta=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.kn(this.ya,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.r;$CLJS.g.ia=$CLJS.ta(5);$CLJS.g.ie=$CLJS.r;$CLJS.g.Kd=function(){return this.ya};$CLJS.g=lG.prototype;$CLJS.g.P=function(a,b){return new lG(this.Ue,this.qe,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Me};$CLJS.g.Xa=$CLJS.r;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.Nn(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.uf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.H(a,0,null),k=$CLJS.H(a,1,null),l=$CLJS.H(a,2,null);return new $CLJS.kG(new $CLJS.Bh(function(){return $CLJS.ro(e.type,b,k,d)}),d,e.Me,k,b,e.Xd,l,f,e.min,e.Eb,e.Rb,this,e.type,e.Ue,a,e.qe,$CLJS.qo(),e.max,new $CLJS.h(null,1,[$CLJS.ej,$CLJS.Xp],null))};
$CLJS.sG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);var nG;$CLJS.oG=$CLJS.Oe($CLJS.N);
nG=$CLJS.Oe($CLJS.Wk.l($CLJS.G([$CLJS.yt(),new $CLJS.h(null,3,[$CLJS.su,mG(new $CLJS.h(null,2,[$CLJS.ej,$CLJS.su,$CLJS.dt,jG(pG)],null)),$CLJS.qu,mG(new $CLJS.h(null,2,[$CLJS.ej,$CLJS.qu,$CLJS.dt,jG(qG)],null)),$CLJS.xu,mG(new $CLJS.h(null,5,[$CLJS.ej,$CLJS.xu,$CLJS.wu,1,$CLJS.Mn,2,$CLJS.ak,2,$CLJS.dt,function(a,b,c){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Hk.j(b,0,function(d){return $CLJS.lo.g(d,c)}),$CLJS.Hk.j(b,0,function(d){return $CLJS.au.g(d,c)}),$CLJS.P.g(rG,$CLJS.$d.g(b,c))],null)}],null))],
null)])));$CLJS.Pe($CLJS.kl,$CLJS.bn(new gG(nG,$CLJS.N)));