var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var OY,via,QY,SY,TY,wia,VY,WY,NY,XY,xia,yia,zia,Aia,$Y,aZ,cZ,dZ,eZ,fZ,gZ,hZ,PY,Cia,Dia,kZ,Eia,lZ,Fia,nZ,Gia,Hia,Iia,Jia,Kia,KY;$CLJS.LY=function(a,b){if("string"===typeof b)return KY(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.MY=function(a,b,c){var d=$CLJS.Ul(a,b);if($CLJS.n(d)){var e=$CLJS.S.j;d=$CLJS.zb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.S,a,b,c)}return a};
OY=function(a){var b=NY;return $CLJS.VC(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};via=function(a){var b=PY;return $CLJS.VC(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};QY=function(a){if($CLJS.md(a))return a;throw $CLJS.ai("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Ai,a],null));};
$CLJS.RY=function(a,b,c){return $CLJS.wd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.kd(l,A),I=$CLJS.H(D,0,null),K=$CLJS.H(D,1,null);D=t;var U=$CLJS.R,Z=I;I=$CLJS.$d.g(b,I);I=a.g?a.g(I,K):a.call(null,I,K);D.add(new $CLJS.Q(null,2,5,U,[Z,I],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[v,function(){var A=$CLJS.$d.g(b,v),D=x;return a.g?a.g(A,D):a.call(null,A,D)}()],null),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):$CLJS.vd(c)?$CLJS.Gk.g($CLJS.Me(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.$d.g(b,$CLJS.B(c)):b),c):c};SY=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ge(a):b;return $CLJS.n(b)?[$CLJS.ge(a),"/",$CLJS.Zg(a)].join(""):$CLJS.Zg(a)};
TY=function(a,b){var c=$CLJS.vd(b)&&!$CLJS.qf(b)&&$CLJS.B(b)instanceof $CLJS.M;return c?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c};
wia=function(a,b){return $CLJS.$e(function(c){var d=$CLJS.bf;a:try{if($CLJS.yd(c)&&1<=$CLJS.E(c))try{var e=$CLJS.Lk.j(c,0,1);if($CLJS.yd(e)&&1===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.n($CLJS.Me($CLJS.F,a)(f))){var k=$CLJS.Lk.g(c,1);var l=new $CLJS.Q(null,1,5,$CLJS.R,[k],null)}else throw $CLJS.oX;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.oX)throw $CLJS.oX;throw m;}throw t;}else throw $CLJS.oX;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.oX)throw $CLJS.oX;throw m;}throw t;}else throw $CLJS.oX;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.oX){l=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[c],null)],null);break a}throw m;}throw t;}return $CLJS.B($CLJS.z(d($CLJS.Ta,l)))},$CLJS.G([b]))};
VY=function(a,b){for(;;)switch(b=$CLJS.Yk.h($CLJS.bf($CLJS.Ta,b)),$CLJS.E(b)){case 0:return null;case 1:return a=$CLJS.B(b),UY.h?UY.h(a):UY.call(null,a);default:if($CLJS.n($CLJS.Je($CLJS.Me(TY,a),b))){var c=a;b=wia(a,b);a=c}else{c=$CLJS.Ze.g(UY,b);if($CLJS.F.g(c,b))return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),b);b=c}}};WY=function(a){var b=$CLJS.vd(a);return b?(b=!$CLJS.qf(a))?$CLJS.PC($CLJS.ee,$CLJS.Va)($CLJS.B(a)):b:b};
NY=function(a){return $CLJS.n($CLJS.PC($CLJS.ee,$CLJS.Va)(a))?$CLJS.rh.h($CLJS.TC(SY(a).toLowerCase(),/_/,"-")):a};XY=function(a,b){var c=WY(b);return $CLJS.n(c)?(b=NY($CLJS.B(b)),$CLJS.td(a)?(a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,b)):c};xia=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Wh,$CLJS.Rd,$CLJS.ej,NY,$CLJS.NP,NY],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ce([a,b]);b=YY.g?YY.g(b,ZY):YY.call(null,b,ZY);return d.call(c,b,a)})};
yia=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=NY(c);var d=xia(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.F.g($CLJS.ej.h(a),$CLJS.fO)&&$CLJS.Sa($CLJS.NP.h(a))?$CLJS.S.j(a,$CLJS.NP,$CLJS.MI):a};zia=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=SY(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.S.j(yia(b),$CLJS.T,c)],null)}),a)};
Aia=function(a){a=YY.h?YY.h(a):YY.call(null,a);return PY.h?PY.h(a):PY.call(null,a)};$Y=function(a){return $CLJS.MY($CLJS.MY($CLJS.ab(function(b,c){return $CLJS.MY(b,c,$CLJS.rh)},a,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.sF,$CLJS.hca,$CLJS.AQ,Bia,$CLJS.qC,$CLJS.li],null)),$CLJS.sE,Aia),$CLJS.PP,$CLJS.sX)};aZ=function(a){return $CLJS.Ed(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,a,null],null):a};cZ=function(a){return bZ.h(aZ(a))};
dZ=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return UY($CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.Ze.g(bZ,b)))};eZ=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[a,cZ(b)],null),$CLJS.Ze.g(bZ,c))};fZ=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(a)?new $CLJS.Q(null,2,5,$CLJS.R,[b,cZ(a)],null):new $CLJS.Q(null,1,5,$CLJS.R,[b],null)};
gZ=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,cZ(a)],null)};hZ=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,bZ.h(a)],null)};
PY=function(a){return iZ(function(b){if($CLJS.wd(b))return via(b);if($CLJS.Sa(WY(b)))return b;try{return bZ.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.ZC($CLJS.UA);if($CLJS.n($CLJS.YC("metabase.mbql.normalize",d))){var e=$CLJS.UG("Invalid clause:");e instanceof Error?$CLJS.XC("metabase.mbql.normalize",d,$CLJS.dy.l($CLJS.G([b])),e):$CLJS.XC("metabase.mbql.normalize",d,$CLJS.dy.l($CLJS.G([e,b])),null)}throw $CLJS.$h($CLJS.uG("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.pX(c)])),new $CLJS.h(null,
1,[$CLJS.WT,b],null),c);}throw f;}},a)};Cia=function(a){return $CLJS.uf($CLJS.Rl(PY,jZ(a)))};Dia=function(a){for(;;)if($CLJS.n(WY(a)))a=new $CLJS.Q(null,1,5,$CLJS.R,[a],null);else return $CLJS.De($CLJS.Gk.g(aZ,a))};kZ=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.fO);return $CLJS.n(b)?$CLJS.Hk.j(a,$CLJS.fO,bZ):a};
Eia=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,kZ(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,
2,5,$CLJS.R,[l,kZ(f)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};lZ=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DS);return $CLJS.n(b)?$CLJS.Hk.j(a,$CLJS.DS,Eia):a};Fia=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.gS);b=$CLJS.Sa(a)?mZ.h?mZ.h(b):mZ.call(null,b):b;return $CLJS.n(a)?lZ(b):b};nZ=function(a){return $CLJS.td(a)?$CLJS.z(a):null!=a};
Gia=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.EO);var c=$CLJS.J.g(b,$CLJS.rP),d=$CLJS.J.g(b,$CLJS.OQ),e=$CLJS.J.g(b,$CLJS.gS);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.OQ);var l=$CLJS.RN($CLJS.wk.g(f,$CLJS.OQ),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EO,$CLJS.OQ],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Hk.j(l,$CLJS.EO,mZ):l,t=$CLJS.n(c)?$CLJS.Hk.j(m,$CLJS.rP,$CLJS.Me($CLJS.Gk,PY)):m,u=$CLJS.n(e)?$CLJS.Hk.j(t,$CLJS.gS,lZ):t;return PY(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.$h($CLJS.uG("Error canonicalizing query: {0}",
$CLJS.G([$CLJS.pX(m)])),new $CLJS.h(null,1,[$CLJS.EO,a],null),m);throw v;}};
Hia=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EO),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.gI);c=$CLJS.J.g(c,$CLJS.bH);return $CLJS.z(b)&&$CLJS.z(c)?(b=$CLJS.Wf.j($CLJS.Ug,$CLJS.EE,$CLJS.z($CLJS.bf($CLJS.Ta,function k(e,f){try{if($CLJS.yd(f)&&3===$CLJS.E(f))try{var l=$CLJS.bd(f,0);if($CLJS.fe(l,$CLJS.sI)){var m=$CLJS.bd(f,1),t=$CLJS.bd(f,2);return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,m,$CLJS.wk.g(t,$CLJS.yI)],null)],null)],null)}throw $CLJS.oX;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.oX)throw $CLJS.oX;throw l;}throw u;}else throw $CLJS.oX;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.oX)return $CLJS.xX(k,e,f);throw l;}throw u;}}($CLJS.tf,b)))),$CLJS.oZ.j(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EO,$CLJS.bH],null),$CLJS.Ek.g($CLJS.uf,$CLJS.Me($CLJS.Fk,b)))):a};
Iia=function(a){try{return Hia(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.$h($CLJS.UG("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.EO,a],null),b);}throw c;}};
Jia=function(a,b){var c=$CLJS.Wf.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.le(null,function(){for(var k=e;;)if(k=$CLJS.z(k)){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.kd(l,A),I=$CLJS.H(D,0,null);D=$CLJS.H(D,1,null);var K=$CLJS.$d.g(b,I);D=pZ.g?pZ.g(D,K):pZ.call(null,D,K);null!=D&&t.add(new $CLJS.Q(null,2,5,$CLJS.R,[I,D],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);u=function(){var A=x,D=$CLJS.$d.g(b,v);return pZ.g?pZ.g(A,D):pZ.call(null,A,D)}();if(null!=u)return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null),f($CLJS.Kc(k)));k=$CLJS.Kc(k)}else return null},null,null)}(a)}());return $CLJS.z(c)?c:null};Kia=function(a,b){a=$CLJS.Gk.g(function(c){var d=$CLJS.$d.g(b,qZ);return pZ.g?pZ.g(c,d):pZ.call(null,c,d)},a);return $CLJS.n($CLJS.Je($CLJS.Ta,a))?a:null};
KY=function KY(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.uf(d);return $CLJS.ce(f,new $CLJS.le(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),KY.g?KY.g(a,k):KY.call(null,a,k)):null},null,null))};
$CLJS.oZ=function oZ(a){switch(arguments.length){case 3:return oZ.j(arguments[0],arguments[1],arguments[2]);case 4:return oZ.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return oZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return oZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return oZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.oZ.j=function(a,b,c){var d=$CLJS.z(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.S.j(a,b,$CLJS.oZ.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.S.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.S,a,b,c)}return a};
$CLJS.oZ.v=function(a,b,c,d){var e=$CLJS.z(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.S.j(a,b,$CLJS.oZ.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.S.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.S,a,b,c)}return a};$CLJS.oZ.N=function(a,b,c,d,e){var f=$CLJS.z(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.S.j(a,b,$CLJS.oZ.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.S.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.S,a,b,c)}return a};
$CLJS.oZ.W=function(a,b,c,d,e,f){var k=$CLJS.z(b);b=$CLJS.B(k);if(k=$CLJS.C(k))a=$CLJS.S.j(a,b,$CLJS.oZ.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.S.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.S,a,b,c)}return a};$CLJS.oZ.l=function(a,b,c,d,e,f,k){var l=$CLJS.z(b);b=$CLJS.B(l);return(l=$CLJS.C(l))?$CLJS.S.j(a,b,$CLJS.P.l($CLJS.oZ,$CLJS.J.g(a,b),l,c,d,$CLJS.G([e,f,k]))):$CLJS.S.j(a,b,$CLJS.P.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.G([k])))};
$CLJS.oZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var k=$CLJS.C(f);f=$CLJS.B(k);k=$CLJS.C(k);return this.l(b,a,c,d,e,f,k)};$CLJS.oZ.A=6;
var iZ=function iZ(a,b){return $CLJS.qX($CLJS.Me(iZ,a),$CLJS.Rd,a.h?a.h(b):a.call(null,b))},UY=function UY(a){for(;;){if($CLJS.wd(a))return $CLJS.Du(a,UY);if($CLJS.Cd(a))a=$CLJS.uf(a);else if($CLJS.yd(a)){if($CLJS.Sa($CLJS.Je($CLJS.Ta,a)))return null;var c=a,d=$CLJS.z(c),e=$CLJS.B(d),f=$CLJS.C(d),k=e,l=f,m=$CLJS.H(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Al(m)){var x=$CLJS.B(m),A=x instanceof $CLJS.M?x.T:null;switch(A){case "not":a=$CLJS.hd(m);continue;case "and":return VY($CLJS.gt,
$CLJS.Ze.g(function(){return function(D){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.it,D],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Kc(m)));case "or":return VY($CLJS.mt,$CLJS.Ze.g(function(){return function(D){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.it,D],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Kc(m)));default:return a}}else return a;case "and":return VY($CLJS.mt,t);case "or":return VY($CLJS.gt,t);default:return $CLJS.Gk.g(UY,a)}}else return a}},Lia=new $CLJS.M(null,"value_field","value_field",
-980977878),rZ=new $CLJS.M(null,"ascending","ascending",-988350486),sZ=new $CLJS.M(null,"named","named",-422393479),tZ=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),uZ=new $CLJS.M(null,"descending","descending",-24766135),vZ=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158),wZ=new $CLJS.M(null,"datetime-field","datetime-field",21731696),Mia=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),xZ=new $CLJS.M(null,"field-literal","field-literal",
-1295883554),yZ=new $CLJS.M(null,"viz-settings","viz-settings",256055379),Nia=new $CLJS.M(null,"rows","rows",850049680),Oia=new $CLJS.M(null,"special-fn","special-fn",1290649344),qZ=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),ZY=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Pia=new $CLJS.M(null,"label_field","label_field",-1573182765),Qia=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Ria=new $CLJS.M(null,"joined-field","joined-field",
-2048778268),Bia=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var zZ,Sia=$CLJS.Oe($CLJS.N),Tia=$CLJS.Oe($CLJS.N),Uia=$CLJS.Oe($CLJS.N),Via=$CLJS.Oe($CLJS.N),Wia=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));zZ=new $CLJS.Xh($CLJS.xh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Ek.g(NY,$CLJS.B),Wia,Sia,Tia,Uia,Via);zZ.m(null,$CLJS.hI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hI,b],null);return null!=a?$CLJS.$d.g(b,a):b});
zZ.m(null,$CLJS.DD,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DD,a instanceof $CLJS.M?SY(a):a],null)});zZ.m(null,vZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?$CLJS.$d.g(zZ.h(new $CLJS.Q(null,3,5,$CLJS.R,[vZ,b,c],null)),a):new $CLJS.Q(null,3,5,$CLJS.R,[vZ,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),NY(c)],null)});
zZ.m(null,$CLJS.sI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=YY.g?YY.g(a,ZY):YY.call(null,a,ZY);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,b,function(){var d=$CLJS.n($CLJS.GD.h(c))?$CLJS.Hk.j(c,$CLJS.GD,$CLJS.rh):c;d=$CLJS.n($CLJS.yI.h(c))?$CLJS.Hk.j(d,$CLJS.yI,$CLJS.rh):d;return $CLJS.n($CLJS.rT.h(c))?$CLJS.Hk.j(d,$CLJS.rT,function(e){return $CLJS.n($CLJS.NG.h(e))?$CLJS.Hk.j(e,$CLJS.NG,$CLJS.rh):e}):d}()],null)});
zZ.m(null,xZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[xZ,b instanceof $CLJS.M?SY(b):b,$CLJS.rh.h(a)],null)});zZ.m(null,wZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[wZ,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),$CLJS.hu,NY(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[wZ,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),NY(c)],null)});
zZ.m(null,$CLJS.EI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);return $CLJS.n(a)?$CLJS.$d.g(zZ.h(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.EI,b,c,d],null)),YY.g?YY.g(a,ZY):YY.call(null,a,ZY)):new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.EI,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),$CLJS.Ed(c)?c:NY(c),NY(d)],null)});
zZ.m(null,$CLJS.bL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bL,b,NY(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bL,$CLJS.tx],null)});zZ.m(null,$CLJS.$K,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$K,b,NY(a)],null)});
zZ.m(null,$CLJS.jL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jL,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),c,NY(a)],null)});zZ.m(null,$CLJS.pL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.pL,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),c,NY(a)],null)});
zZ.m(null,$CLJS.hL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hL,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),NY(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hL,YY.g?YY.g(b,ZY):YY.call(null,b,ZY)],null)});
zZ.m(null,$CLJS.nL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.nL,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),NY(c),NY(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nL,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),NY(c)],null)});
zZ.m(null,$CLJS.ZK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ZK,YY.g?YY.g(b,ZY):YY.call(null,b,ZY),YY.g?YY.g(c,ZY):YY.call(null,c,ZY),NY(a)],null)});zZ.m(null,$CLJS.vj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vj,b,a],null)});
zZ.m(null,$CLJS.Wh,function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[NY(a)],null),$CLJS.Ze.h(function(c){return YY.g?YY.g(c,ZY):YY.call(null,c,ZY)}),b)});
var AZ=function AZ(a){if($CLJS.n($CLJS.PC($CLJS.ee,$CLJS.Va)(a))){var c=NY(a);var d=new $CLJS.Tg(null,new $CLJS.h(null,18,[$CLJS.Mn,null,$CLJS.Ks,null,$CLJS.II,null,$CLJS.BI,null,$CLJS.ju,null,$CLJS.tI,null,$CLJS.NI,null,$CLJS.OI,null,$CLJS.XH,null,$CLJS.dI,null,$CLJS.Js,null,$CLJS.YH,null,$CLJS.GI,null,$CLJS.zI,null,$CLJS.ak,null,$CLJS.iA,null,$CLJS.mI,null,$CLJS.FI,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(WY(a))?(a=$CLJS.B(a),AZ.h?AZ.h(a):AZ.call(null,
a)):null},Xia=new $CLJS.h(null,8,[$CLJS.ej,NY,$CLJS.gS,function(a){a=OY(a);return $CLJS.z($CLJS.DS.h(a))?$CLJS.Hk.j(a,$CLJS.DS,zia):a},$CLJS.EO,new $CLJS.h(null,6,[$CLJS.hI,function BZ(a){if($CLJS.n($CLJS.PC($CLJS.ee,$CLJS.Va)(a)))return NY(a);if($CLJS.n(XY(sZ,a))){a=$CLJS.z(a);$CLJS.B(a);var c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[sZ,BZ.h?BZ.h(a):BZ.call(null,a)],null),c)}return $CLJS.n($CLJS.n(WY(a))?AZ($CLJS.hd(a)):null)?$CLJS.Gk.g(BZ,a):YY.g?YY.g(a,
ZY):YY.call(null,a,ZY)},$CLJS.WR,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[SY(u),YY.g?YY.g(t,ZY):YY.call(null,t,ZY)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[SY(l),YY.g?YY.g(f,ZY):YY.call(null,f,ZY)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.LT,function(a){return $CLJS.uf(function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(WY(t))?zZ.h(t):$CLJS.ae(zZ.h($CLJS.ae(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}l=$CLJS.B(e);return $CLJS.ce($CLJS.n(WY(l))?zZ.h(l):$CLJS.ae(zZ.h($CLJS.ae(l))),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.FS,function(a){a=OY(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.gS);if($CLJS.n(b))return a=$CLJS.wX(a,new $CLJS.h(null,1,[$CLJS.gS,$CLJS.EO],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gS],null),a=YY.g?YY.g(a,b):YY.call(null,a,b),$CLJS.wX(a,new $CLJS.h(null,1,[$CLJS.EO,$CLJS.gS],
null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.EO],null);return YY.g?YY.g(a,b):YY.call(null,a,b)},$CLJS.OQ,new $CLJS.h(null,1,[qZ,$Y],null),$CLJS.AP,new $CLJS.h(null,1,[qZ,function(a){a=YY.g?YY.g(a,$CLJS.EO):YY.call(null,a,$CLJS.EO);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.NG),d=$CLJS.J.g(b,$CLJS.bH);a=$CLJS.J.g(b,$CLJS.fH);b=$CLJS.n(c)?$CLJS.Hk.j(b,$CLJS.NG,NY):b;d=$CLJS.n($CLJS.PC($CLJS.ee,$CLJS.Va)(d))?$CLJS.Hk.j(b,$CLJS.bH,NY):b;return $CLJS.n(a)?$CLJS.Hk.j(d,$CLJS.fH,SY):d}],null)],null),$CLJS.YA,
new $CLJS.h(null,1,[$CLJS.lQ,$CLJS.Rd],null),$CLJS.rP,new $CLJS.h(null,1,[qZ,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.ej),d=$CLJS.J.g(b,$CLJS.uL),e=$CLJS.J.g(b,$CLJS.Ri);a=$CLJS.J.g(b,tZ);b=$CLJS.n(e)?$CLJS.Hk.j(b,$CLJS.Ri,SY):b;c=$CLJS.n(c)?$CLJS.Hk.j(b,$CLJS.ej,NY):b;d=$CLJS.n(d)?$CLJS.Hk.j(c,$CLJS.uL,function(f){return YY.g?YY.g(f,ZY):YY.call(null,f,ZY)}):c;d=$CLJS.n(a)?$CLJS.oZ.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[tZ,Pia],null),function(f){return YY.g?YY.g(f,ZY):YY.call(null,f,ZY)}):d;return $CLJS.n(a)?
$CLJS.oZ.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[tZ,Lia],null),function(f){return YY.g?YY.g(f,ZY):YY.call(null,f,ZY)}):d}],null),$CLJS.mP,function(a){return null==a?null:NY(a)},$CLJS.OQ,new $CLJS.h(null,1,[qZ,$Y],null),yZ,NY],null),YY=function YY(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return YY.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
YY.l=function(a,b){b=$CLJS.H(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.Q(null,1,5,$CLJS.R,[b],null):$CLJS.uf(b);b=$CLJS.z(c)?$CLJS.QC(Xia,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.xd(a)?a:$CLJS.wd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);A=NY(A);A=new $CLJS.Q(null,
2,5,$CLJS.R,[A,YY.l(x,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);u=NY(u);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[u,YY.l(m,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),u)]))],null),k($CLJS.Kc(l)))}return null}},null,null)}(a)}()):$CLJS.n(WY(a))?zZ.h(a):$CLJS.vd(a)?$CLJS.Gk.g(function(e){return YY.l(e,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),qZ)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.$h($CLJS.uG("Error normalizing form: {0}",$CLJS.G([$CLJS.pX(d)])),new $CLJS.h(null,3,[$CLJS.pC,a,$CLJS.dl,c,Oia,b],null),d);}throw e;}};YY.A=1;YY.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var bZ=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(WY(f))?$CLJS.B(f):null},e,a,b,c,d)}();bZ.m(null,$CLJS.Wh,function(a){return a});
bZ.m(null,$CLJS.sI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(XY($CLJS.sI,b))){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);return bZ.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,c,$CLJS.De($CLJS.Wk.l($CLJS.G([b,a])))],null))}return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,b,$CLJS.De(a)],null)});
bZ.m(null,$CLJS.hI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.sd(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hI,b],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hI,b,a],null)});bZ.m(null,$CLJS.XM,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(WY(a))?bZ.h(a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,a,null],null)});
bZ.m(null,xZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,b,new $CLJS.h(null,1,[$CLJS.GD,a],null)],null)});bZ.m(null,Qia,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=cZ(b);$CLJS.H(c,0,null);b=$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=cZ(a);$CLJS.H(c,0,null);a=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,a,$CLJS.S.j(c,$CLJS.BP,b)],null)});
bZ.m(null,Ria,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=cZ(a);return $CLJS.P.v($CLJS.DX,a,$CLJS.S,$CLJS.G([$CLJS.qR,b]))});
bZ.m(null,wZ,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.H(a,0,null);b=$CLJS.H(a,1,null);var c=$CLJS.H(a,2,null);a=cZ(b);b=c;$CLJS.H(a,0,null);$CLJS.H(a,1,null);c=$CLJS.H(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.GD);$CLJS.Sa(d)||$CLJS.HU.g(d,b)?a=$CLJS.P.v($CLJS.DX,a,$CLJS.S,$CLJS.G([$CLJS.yI,b])):(c=$CLJS.ZC($CLJS.WA),$CLJS.n($CLJS.YC("metabase.mbql.util",c))&&(b=$CLJS.uG("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.G([b,d,$CLJS.Eh.l($CLJS.G([a]))])),b instanceof
Error?$CLJS.XC("metabase.mbql.util",c,$CLJS.dy(),b):$CLJS.XC("metabase.mbql.util",c,$CLJS.dy.l($CLJS.G([b])),null)));return a;case 4:return $CLJS.H(a,0,null),b=$CLJS.H(a,1,null),$CLJS.H(a,2,null),c=$CLJS.H(a,3,null),bZ.h(new $CLJS.Q(null,3,5,$CLJS.R,[wZ,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
bZ.m(null,vZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);var e=cZ(b);$CLJS.H(e,0,null);b=$CLJS.H(e,1,null);e=$CLJS.H(e,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,b,$CLJS.S.j(e,$CLJS.rT,$CLJS.Wk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.NG,c],null),$CLJS.n(d)?$CLJS.Ce([c,d]):null,a])))],null)});
for(var CZ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,$CLJS.gt,$CLJS.it],null)),DZ=null,EZ=0,FZ=0;;)if(FZ<EZ){var GZ=DZ.X(null,FZ);bZ.m(null,GZ,function(){return function(a){return dZ(a)}}(CZ,DZ,EZ,FZ,GZ));FZ+=1}else{var HZ=$CLJS.z(CZ);if(HZ){var IZ=HZ;if($CLJS.zd(IZ)){var JZ=$CLJS.jc(IZ),Yia=$CLJS.lc(IZ),Zia=JZ,$ia=$CLJS.E(JZ);CZ=Yia;DZ=Zia;EZ=$ia}else{var KZ=$CLJS.B(IZ);bZ.m(null,KZ,function(){return function(a){return dZ(a)}}(CZ,DZ,EZ,FZ,KZ,IZ,HZ));CZ=$CLJS.C(IZ);DZ=null;EZ=0}FZ=0}else break}
bZ.m(null,$CLJS.$H,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$H,cZ(a),cZ(b)],null),c)});bZ.m(null,$CLJS.EI,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=cZ(a);a=$CLJS.n(TY($CLJS.sI,a))?$CLJS.DX.l(c,$CLJS.wk,$CLJS.G([$CLJS.yI])):c;return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EI,a],null),b)});
for(var LZ=$CLJS.z(new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.jI,$CLJS.DI,$CLJS.rI,$CLJS.kI,$CLJS.hk,$CLJS.KI,$CLJS.Es,$CLJS.Gs,$CLJS.As,$CLJS.Cs,$CLJS.VH,$CLJS.bI,$CLJS.TH,$CLJS.eI,$CLJS.UH],null)),MZ=null,NZ=0,OZ=0;;)if(OZ<NZ){var PZ=MZ.X(null,OZ);bZ.m(null,PZ,function(){return function(a){return eZ(a)}}(LZ,MZ,NZ,OZ,PZ));OZ+=1}else{var QZ=$CLJS.z(LZ);if(QZ){var RZ=QZ;if($CLJS.zd(RZ)){var SZ=$CLJS.jc(RZ),aja=$CLJS.lc(RZ),bja=SZ,cja=$CLJS.E(SZ);LZ=aja;MZ=bja;NZ=cja}else{var TZ=$CLJS.B(RZ);bZ.m(null,TZ,
function(){return function(a){return eZ(a)}}(LZ,MZ,NZ,OZ,TZ,RZ,QZ));LZ=$CLJS.C(RZ);MZ=null;NZ=0}OZ=0}else break}bZ.m(null,Nia,function(){return null});bZ.m(null,$CLJS.qO,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qO,bZ.h(b),a],null)});
bZ.m(null,sZ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=bZ.h;var e=$CLJS.R;b=bZ.h(b);d=$CLJS.H(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,Mia)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.zG,a],null);return c.call(bZ,new $CLJS.Q(null,3,5,e,[$CLJS.qO,b,a],null))});
for(var UZ=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iA,$CLJS.tI],null)),VZ=null,WZ=0,XZ=0;;)if(XZ<WZ){var YZ=VZ.X(null,XZ);bZ.m(null,YZ,function(){return function(a){return fZ(a)}}(UZ,VZ,WZ,XZ,YZ));XZ+=1}else{var ZZ=$CLJS.z(UZ);if(ZZ){var $Z=ZZ;if($CLJS.zd($Z)){var a_=$CLJS.jc($Z),dja=$CLJS.lc($Z),eja=a_,fja=$CLJS.E(a_);UZ=dja;VZ=eja;WZ=fja}else{var b_=$CLJS.B($Z);bZ.m(null,b_,function(){return function(a){return fZ(a)}}(UZ,VZ,WZ,XZ,b_,$Z,ZZ));UZ=$CLJS.C($Z);VZ=null;WZ=0}XZ=0}else break}
for(var c_=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.mI,$CLJS.PI,$CLJS.XH,$CLJS.II,$CLJS.FI,$CLJS.Mn,$CLJS.ak,$CLJS.GI,$CLJS.YH],null)),d_=null,e_=0,f_=0;;)if(f_<e_){var g_=d_.X(null,f_);bZ.m(null,g_,function(){return function(a){return gZ(a)}}(c_,d_,e_,f_,g_));f_+=1}else{var h_=$CLJS.z(c_);if(h_){var i_=h_;if($CLJS.zd(i_)){var j_=$CLJS.jc(i_),gja=$CLJS.lc(i_),hja=j_,ija=$CLJS.E(j_);c_=gja;d_=hja;e_=ija}else{var k_=$CLJS.B(i_);bZ.m(null,k_,function(){return function(a){return gZ(a)}}(c_,d_,e_,f_,
k_,i_,h_));c_=$CLJS.C(i_);d_=null;e_=0}f_=0}else break}bZ.m(null,$CLJS.dI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dI,cZ(b),a],null)});
for(var l_=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zI,$CLJS.BI],null)),m_=null,n_=0,o_=0;;)if(o_<n_){var p_=m_.X(null,o_);bZ.m(null,p_,function(){return function(a){return hZ(a)}}(l_,m_,n_,o_,p_));o_+=1}else{var q_=$CLJS.z(l_);if(q_){var r_=q_;if($CLJS.zd(r_)){var s_=$CLJS.jc(r_),jja=$CLJS.lc(r_),kja=s_,lja=$CLJS.E(s_);l_=jja;m_=kja;n_=lja}else{var t_=$CLJS.B(r_);bZ.m(null,t_,function(){return function(a){return hZ(a)}}(l_,m_,n_,o_,t_,r_,q_));l_=$CLJS.C(r_);m_=null;n_=0}o_=0}else break}
bZ.m(null,$CLJS.NI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NI,bZ.h(b),bZ.h(a)],null)});
bZ.m(null,$CLJS.aI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?$CLJS.$d.g(bZ.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aI,b],null)),YY.l(a,$CLJS.G([ZY]))):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aI,$CLJS.uf(function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.Q(null,
2,5,$CLJS.R,[bZ.h(v),bZ.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[bZ.h(m),bZ.h(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}(b)}())],null)});
bZ.m(null,$CLJS.MH,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,bZ.h(a),$CLJS.F.g(0,b)?1:bZ.h(b)],null),$CLJS.Ze.g(bZ,c))});
var jZ=function jZ(a){return function f(d,e){try{if($CLJS.n(function(){var x=QY($CLJS.Cd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.uf(e));throw $CLJS.oX;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.oX)try{if($CLJS.n(function(){var A=QY($CLJS.ee);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[e],null)],null);throw $CLJS.oX;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.oX)try{if($CLJS.yd(e)&&2<=$CLJS.E(e))try{var k=$CLJS.Lk.j(e,
0,2);if($CLJS.yd(k)&&2===$CLJS.E(k))try{var l=$CLJS.bd(k,0);if($CLJS.n($CLJS.KN($CLJS.ee,$CLJS.Ke(new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.Ks,null,$CLJS.ju,null,$CLJS.OI,null,$CLJS.Js,null,sZ,null],null),null)))(l)))try{var m=$CLJS.bd(k,1);if($CLJS.n(AZ(m)))return $CLJS.Wf.j($CLJS.tf,$CLJS.tG(jZ),a);throw $CLJS.oX;}catch(D){if(D instanceof Error){var t=D;if(t===$CLJS.oX)throw $CLJS.oX;throw t;}throw D;}else throw $CLJS.oX;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.oX)throw $CLJS.oX;throw t;
}throw D;}else throw $CLJS.oX;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.oX)throw $CLJS.oX;throw t;}throw D;}else throw $CLJS.oX;}catch(D){if(D instanceof Error)if(t=D,t===$CLJS.oX)try{if($CLJS.yd(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Lk.j(e,0,1);if($CLJS.yd(u)&&1===$CLJS.E(u))try{if($CLJS.bd(u,0)instanceof $CLJS.M)return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.oX;}catch(I){if(I instanceof Error){var v=I;if(v===$CLJS.oX)throw $CLJS.oX;throw v;}throw I;}else throw $CLJS.oX;}catch(I){if(I instanceof
Error){v=I;if(v===$CLJS.oX)throw $CLJS.oX;throw v;}throw I;}else throw $CLJS.oX;}catch(I){if(I instanceof Error){v=I;if(v===$CLJS.oX)return e;throw v;}throw I;}else throw t;else throw D;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.tf,a)},u_=function u_(a){return function f(d,e){try{var k=QY($CLJS.Cd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.uf(e));throw $CLJS.oX;}catch(D){if(D instanceof Error)if(l=D,l===$CLJS.oX)try{if($CLJS.yd(e)&&2===$CLJS.E(e))try{var m=
$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.pI)){var t=$CLJS.bd(e,0);return f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pI,t],null))}throw $CLJS.oX;}catch(I){if(I instanceof Error)if(l=I,l===$CLJS.oX)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.iI))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iI,t],null));throw $CLJS.oX;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.oX)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,rZ))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pI,t],null));throw $CLJS.oX;
}catch(U){if(U instanceof Error)if(l=U,l===$CLJS.oX)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,uZ))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iI,t],null));throw $CLJS.oX;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.oX)try{var v=$CLJS.bd(e,0);if($CLJS.fe(v,rZ))return t=$CLJS.bd(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pI,t],null));throw $CLJS.oX;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.oX)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,uZ))return t=$CLJS.bd(e,1),f(d,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.iI,t],null));throw $CLJS.oX;}catch(xa){if(xa instanceof Error)if(m=xa,m===$CLJS.oX)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.pI))return t=$CLJS.bd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pI,cZ(t)],null);throw $CLJS.oX;}catch(Ha){if(Ha instanceof Error)if(m=Ha,m===$CLJS.oX)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.iI))return t=$CLJS.bd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iI,cZ(t)],null);throw $CLJS.oX;}catch(mb){if(mb instanceof Error){t=mb;if(t===$CLJS.oX)throw $CLJS.oX;throw t;
}throw mb;}else throw m;else throw Ha;}else throw m;else throw xa;}else throw m;else throw da;}else throw m;else throw Z;}else throw l;else throw U;}else throw u;}else throw K;}else throw l;else throw I;}else throw $CLJS.oX;}catch(I){if(I instanceof Error)if(l=I,l===$CLJS.oX)try{if($CLJS.yd(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Lk.j(e,0,0);if($CLJS.yd(x)&&0===$CLJS.E(x))try{var A=$CLJS.Lk.g(e,0);if($CLJS.F.g(A,a))return $CLJS.uf($CLJS.Yk.h($CLJS.Ze.g(u_,a)));throw $CLJS.oX;}catch(K){if(K instanceof Error){u=
K;if(u===$CLJS.oX)throw $CLJS.oX;throw u;}throw K;}else throw $CLJS.oX;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.oX)throw $CLJS.oX;throw u;}throw K;}else throw $CLJS.oX;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.oX)return $CLJS.RY(f,d,e);throw u;}throw K;}else throw l;else throw I;}else throw l;else throw D;}}($CLJS.tf,a)},mZ=$CLJS.Ek.g(PY,function(a){var b=nZ($CLJS.hI.h(a))?$CLJS.Hk.j(a,$CLJS.hI,Cia):a;b=nZ($CLJS.gI.h(a))?$CLJS.Hk.j(b,$CLJS.gI,Dia):b;b=nZ($CLJS.bH.h(a))?$CLJS.Hk.j(b,
$CLJS.bH,$CLJS.Me($CLJS.Gk,aZ)):b;b=nZ($CLJS.LT.h(a))?$CLJS.Hk.j(b,$CLJS.LT,u_):b;return nZ($CLJS.FS.h(a))?$CLJS.Hk.j(b,$CLJS.FS,Fia):b}),mja=new $CLJS.h(null,3,[$CLJS.gS,$CLJS.Rd,$CLJS.EO,new $CLJS.h(null,2,[$CLJS.FS,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.gS);if($CLJS.n(b))return a=$CLJS.wX(a,new $CLJS.h(null,1,[$CLJS.gS,$CLJS.EO],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gS],null),a=pZ.g?pZ.g(a,b):pZ.call(null,a,b),$CLJS.wX(a,new $CLJS.h(null,1,[$CLJS.EO,$CLJS.gS],null));b=new $CLJS.Q(null,
1,5,$CLJS.R,[$CLJS.EO],null);return pZ.g?pZ.g(a,b):pZ.call(null,a,b)},$CLJS.AP,new $CLJS.h(null,1,[qZ,function(a){var b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.EO],null);return pZ.g?pZ.g(a,b):pZ.call(null,a,b)}],null)],null),yZ,$CLJS.Rd],null),pZ=function pZ(a){switch(arguments.length){case 1:return pZ.h(arguments[0]);case 2:return pZ.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};pZ.h=function(a){return pZ.g(a,$CLJS.tf)};
pZ.g=function(a,b){try{var c=$CLJS.z(b)?$CLJS.QC(mja,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.xd(a)?a:$CLJS.wd(a)?Jia(a,b):$CLJS.vd(a)?Kia(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.$h("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.pC,a,$CLJS.dl,b],null),d);throw d;}};pZ.A=2;
$CLJS.v_=function(){var a=$CLJS.Ek.l(pZ,Iia,Gia,$CLJS.G([YY]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.$h($CLJS.uG("Error normalizing query: {0}",$CLJS.G([$CLJS.pX(c)])),new $CLJS.h(null,1,[$CLJS.EO,b],null),c);}throw d;}}}();$CLJS.w_=function w_(a,b){if($CLJS.Sa($CLJS.z(a)))a=(0,$CLJS.v_)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Bu(a);b=$CLJS.Ce([$CLJS.id(a),b]);f=w_.g?w_.g(f,b):w_.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};