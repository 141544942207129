var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var zca,BG,EG,OG;$CLJS.tG=function(a){return $CLJS.Ek.g($CLJS.Ze.h(a),$CLJS.EE)};$CLJS.uG=function(a,b){a=$CLJS.av($CLJS.TC(a,/''/,"'"),/\{\d+\}/);return $CLJS.P.j($CLJS.yu.t,$CLJS.Zk(a),$CLJS.Zk(b))};$CLJS.vG=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.wG=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.xG=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.yG=new $CLJS.M(null,"mbql","mbql",69346710);
$CLJS.zG=new $CLJS.M(null,"display-name","display-name",694513143);zca=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.AG=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);BG=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.CG=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);
$CLJS.DG=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);EG=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.FG=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.GG=new $CLJS.M(null,"operator","operator",-1860875338);
$CLJS.HG=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.IG=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.JG=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.KG=new $CLJS.M(null,"x","x",2099068185);$CLJS.LG=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);
$CLJS.MG=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.NG=new $CLJS.M(null,"strategy","strategy",-1471631918);OG=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.DG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rj,new $CLJS.h(null,1,[$CLJS.Mn,1],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"non-blank string"],null),$CLJS.Ke($CLJS.UC)],null)],null));$CLJS.Y($CLJS.HG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.st,new $CLJS.h(null,1,[$CLJS.Mn,0],null)],null));$CLJS.Y($CLJS.wG,$CLJS.Dl);$CLJS.Y(OG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rj,new $CLJS.h(null,2,[$CLJS.Mn,36,$CLJS.ak,36],null)],null));
$CLJS.Y(EG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,2,[$CLJS.tu,"valid semantic type",$CLJS.vu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.vj);return["Not a valid semantic type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.FE(a,$CLJS.kj)}],null));
$CLJS.Y(BG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,2,[$CLJS.tu,"valid relation type",$CLJS.vu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.vj);return["Not a valid relation type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.FE(a,$CLJS.gi)}],null));$CLJS.Y($CLJS.AG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BG],null)],null));
$CLJS.Y($CLJS.LG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,2,[$CLJS.tu,"valid base type",$CLJS.vu,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.vj);return["Not a valid base type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.FE(a,$CLJS.sj)}],null));
$CLJS.Y($CLJS.IG,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JG,OG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GD,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.LG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.LG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Nj,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,
$CLJS.AG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FG,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.DG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.DG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zG,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$CLJS.DG],null)],null)],null));
$CLJS.Y(zca,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.CG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.Rj,$CLJS.Oi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rj,$CLJS.ei],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.IG],null)],null));