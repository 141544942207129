var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.SL=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.TL=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.UL=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var VL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mt,$CLJS.gt],null)),WL=null,XL=0,YL=0;;)if(YL<XL){var qea=WL.X(null,YL);$CLJS.KH(qea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Mn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gH],null)],null)],null)],null)]));YL+=1}else{var ZL=$CLJS.z(VL);if(ZL){var $L=ZL;if($CLJS.zd($L)){var aM=$CLJS.jc($L),rea=$CLJS.lc($L),
sea=aM,tea=$CLJS.E(aM);VL=rea;WL=sea;XL=tea}else{var uea=$CLJS.B($L);$CLJS.KH(uea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Mn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gH],null)],null)],null)],null)]));VL=$CLJS.C($L);WL=null;XL=0}YL=0}else break}$CLJS.IH($CLJS.it,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gH],null)]));
for(var bM=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.KI],null)),cM=null,dM=0,eM=0;;)if(eM<dM){var vea=cM.X(null,eM);$CLJS.KH(vea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Mn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null)],null)],null)],null)]));eM+=1}else{var fM=$CLJS.z(bM);if(fM){var gM=fM;if($CLJS.zd(gM)){var hM=$CLJS.jc(gM),wea=$CLJS.lc(gM),
xea=hM,yea=$CLJS.E(hM);bM=wea;cM=xea;dM=yea}else{var zea=$CLJS.B(gM);$CLJS.KH(zea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Mn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mH],null)],null)],null)],null)]));bM=$CLJS.C(gM);cM=null;dM=0}eM=0}else break}
for(var iM=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Es,$CLJS.Gs,$CLJS.As,$CLJS.Cs],null)),jM=null,kM=0,lM=0;;)if(lM<kM){var Aea=jM.X(null,lM);$CLJS.IH(Aea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null)]));lM+=1}else{var mM=$CLJS.z(iM);if(mM){var nM=mM;if($CLJS.zd(nM)){var oM=$CLJS.jc(nM),Bea=$CLJS.lc(nM),Cea=oM,Dea=$CLJS.E(oM);iM=Bea;jM=Cea;kM=Dea}else{var Eea=$CLJS.B(nM);$CLJS.IH(Eea,$CLJS.G([$CLJS.ju,$CLJS.Dj,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null)]));iM=$CLJS.C(nM);jM=null;kM=0}lM=0}else break}$CLJS.IH($CLJS.UH,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null)]));
$CLJS.IH($CLJS.$H,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oH],null)]));
for(var pM=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TH,$CLJS.eI],null)),qM=null,rM=0,sM=0;;)if(sM<rM){var Fea=qM.X(null,sM);$CLJS.IH(Fea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hH],null)]));sM+=1}else{var tM=$CLJS.z(pM);if(tM){var uM=tM;if($CLJS.zd(uM)){var vM=$CLJS.jc(uM),Gea=$CLJS.lc(uM),Hea=vM,Iea=$CLJS.E(vM);pM=Gea;qM=Hea;rM=Iea}else{var Jea=$CLJS.B(uM);$CLJS.IH(Jea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hH],null)]));pM=$CLJS.C(uM);
qM=null;rM=0}sM=0}else break}
for(var wM=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VH,$CLJS.bI],null)),xM=null,yM=0,zM=0;;)if(zM<yM){var Kea=xM.X(null,zM);$CLJS.IH(Kea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.qH],null)]));zM+=1}else{var AM=$CLJS.z(wM);if(AM){var BM=AM;if($CLJS.zd(BM)){var CM=$CLJS.jc(BM),Lea=$CLJS.lc(BM),Mea=CM,Nea=$CLJS.E(CM);wM=Lea;xM=Mea;yM=Nea}else{var Oea=$CLJS.B(BM);$CLJS.IH(Oea,$CLJS.G([$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.qH],null)]));wM=$CLJS.C(BM);
xM=null;yM=0}zM=0}else break}
for(var DM=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AI,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null)],null),EM=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jI,$CLJS.DI,$CLJS.rI,$CLJS.kI],null)),FM=null,GM=0,HM=0;;)if(HM<GM){var IM=FM.X(null,HM);$CLJS.TI.v(IM,$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,IM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,$CLJS.IG,DM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.rH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null)],null));HM+=1}else{var JM=$CLJS.z(EM);if(JM){var KM=JM;if($CLJS.zd(KM)){var LM=$CLJS.jc(KM),Pea=$CLJS.lc(KM),Qea=LM,Rea=$CLJS.E(LM);EM=Pea;FM=Qea;GM=Rea}else{var MM=$CLJS.B(KM);$CLJS.TI.v(MM,$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,MM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,$CLJS.IG,DM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.rH],null)],null));EM=$CLJS.C(KM);FM=null;GM=0}HM=0}else break}
$CLJS.TI.v($CLJS.EI,$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.EI],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.su,$CLJS.IG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HI,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.wt],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ct,$CLJS.tx,$CLJS.mA,$CLJS.ZH],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.uH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)],null));$CLJS.TI.v($CLJS.QH,$CLJS.ju,$CLJS.Dj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.QH],null),$CLJS.IG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,$CLJS.wG,$CLJS.DG],null)],null));
$CLJS.Y($CLJS.SL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.TL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lI,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.ct,$CLJS.hk,$CLJS.KI,$CLJS.$H,$CLJS.UH,$CLJS.Es,$CLJS.As,$CLJS.Gs,$CLJS.Cs,$CLJS.TH,$CLJS.eI,$CLJS.VH,$CLJS.bI,$CLJS.rI,$CLJS.kI,$CLJS.jI,$CLJS.DI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UL,$CLJS.Oi],null)],null));