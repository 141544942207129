var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var opa,ppa,rpa,spa,tpa,upa;opa=function(a,b){return $CLJS.VC(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};ppa=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){return $CLJS.Zf.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.cc($CLJS.N),b))};rpa=function(a){return $CLJS.Wf.j($CLJS.Ug,$CLJS.Ek.g($CLJS.tG(function(b){return $CLJS.LY(b,a)}),$CLJS.Ze.h($CLJS.hd)),qpa)};
spa=function(a){return new $CLJS.h(null,3,[$CLJS.ej,$CLJS.KD,$CLJS.T,a,$CLJS.Ri,$CLJS.p.h($CLJS.TG())],null)};
tpa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.Wk.l($CLJS.G([a,function(){var c=$CLJS.fh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.H(c,0,null),c=$CLJS.H(c,1,null),c=$CLJS.pY(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.ej,$CLJS.sP,$CLJS.$S,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.ej,$CLJS.CO,$CLJS.oO,c],null):null}(),$CLJS.n($CLJS.zG.h(a))?null:new $CLJS.h(null,
1,[$CLJS.zG,$CLJS.J2.g($CLJS.I2,b)],null)]))};$CLJS.E8=function(a){a=$CLJS.B2($CLJS.H1(a));return $CLJS.n($CLJS.QC(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wC,upa],null)))?$CLJS.$d.g($CLJS.Ug,$CLJS.iQ):$CLJS.Ug};$CLJS.F8=function(a,b){var c=$CLJS.E8(a);return(0,$CLJS.IY)(a,0,function(d){var e=$CLJS.wv.g(vpa,c);d=$CLJS.P.j($CLJS.wk,d,e);d=$CLJS.Wk.l($CLJS.G([d,$CLJS.Tl(b,c)]));$CLJS.wv.g(c,$CLJS.Yg($CLJS.Pg(b)));$CLJS.BY(a,0);return d})};$CLJS.G8=function(a){return $CLJS.De($CLJS.Tl($CLJS.BY(a,0),$CLJS.E8(a)))};
$CLJS.H8=function(a){return $CLJS.gS.h($CLJS.BY(a,0))};upa=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.wpa=new $CLJS.M(null,"write","write",-1857649168);$CLJS.xpa=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var qpa,vpa;qpa=new $CLJS.Q(null,3,5,$CLJS.R,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.I8=function(){function a(d,e){d=$CLJS.De(rpa(d));var f=$CLJS.De($CLJS.Yg($CLJS.Pg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.wv.g(d,f);d=$CLJS.wv.g(f,d);if($CLJS.F.l(1,$CLJS.E(k),$CLJS.G([$CLJS.E(d)]))){d=$CLJS.B(d);k=$CLJS.B(k);f=$CLJS.J.g(e,d);var l=$CLJS.F.g($CLJS.zG.h(f),$CLJS.J2.g($CLJS.I2,d))?$CLJS.J2.g($CLJS.I2,k):$CLJS.zG.h(f);f=$CLJS.S.l($CLJS.wk.l(f,$CLJS.oO,$CLJS.G([$CLJS.$S,$CLJS.QR])),$CLJS.zG,l,$CLJS.G([$CLJS.T,k]));e=$CLJS.S.j($CLJS.wk.g(e,d),k,f)}else e=$CLJS.Wk.l($CLJS.G([opa($CLJS.Ke(d),
e),ppa($CLJS.T,$CLJS.Ze.g(spa,k))]));e=$CLJS.Du(e,tpa)}else e=$CLJS.N;return e}function b(d){return $CLJS.I8.g?$CLJS.I8.g(d,null):$CLJS.I8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();vpa=new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.iQ,null],null),null);
$CLJS.Y(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iQ,new $CLJS.h(null,1,[$CLJS.Ct,!0],null),$CLJS.DG],null)],null));
$CLJS.J8=function(){function a(d,e,f,k){var l=$CLJS.I8.h(e);return $CLJS.F8($CLJS.F2.g(d,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.lY(new $CLJS.h(null,4,[$CLJS.wj,$CLJS.YX,$CLJS.jX,f,$CLJS.DS,l,$CLJS.gS,e],null))],null)),k)}function b(d,e){return $CLJS.J8.v?$CLJS.J8.v(d,e,null,null):$CLJS.J8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.K8=function(){function a(d,e,f){$CLJS.BY(d,0);return $CLJS.F8($CLJS.F2.g(e,$CLJS.pN.h(d)),f)}function b(d,e){return $CLJS.K8.j?$CLJS.K8.j(d,e,null):$CLJS.K8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.E2.m(null,$CLJS.YX,function(a){return $CLJS.z2($CLJS.E8(a),$CLJS.Yg($CLJS.Pg($CLJS.G8(a))))&&!$CLJS.UC($CLJS.H8(a))});