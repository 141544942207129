var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var e5,g5,h5,i5,Ama;e5=function(a,b,c){var d=$CLJS.BY(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.hI);d=$CLJS.DJ($CLJS.Ek.j($CLJS.Vg([c]),$CLJS.JG,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ai($CLJS.uG("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.h(null,3,[$CLJS.vt,c,$CLJS.EO,a,$CLJS.FY,b],null));return d};$CLJS.f5=function(a){return $CLJS.w3($CLJS.FI,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};
g5=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);h5=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);i5=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Ama=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.m2.m(null,$CLJS.hI,function(a,b){var c=$CLJS.De($CLJS.hI.h($CLJS.BY(a,b)));return $CLJS.n(c)?$CLJS.x3($CLJS.UG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.M1.v(a,b,v,$CLJS.N1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);return $CLJS.ce($CLJS.M1.v(a,
b,t,$CLJS.N1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):null});$CLJS.p2.m(null,$CLJS.hI,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.GD);e=$CLJS.J.g(e,$CLJS.yi);c=$CLJS.H(c,2,null);c=e5(a,b,c);return $CLJS.Wk.l($CLJS.G([$CLJS.L1.j(a,b,c),new $CLJS.h(null,2,[$CLJS.QM,$CLJS.iN,$CLJS.h2,$CLJS.JG.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.GD,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.yi,e],null):null]))});
$CLJS.j2.m(null,$CLJS.hI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.M1.v(a,b,e5(a,b,c),d)});$CLJS.BH(h5,i5);
for(var j5=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iA,$CLJS.tI],null)),k5=null,l5=0,m5=0;;)if(m5<l5){var Bma=k5.X(null,m5);$CLJS.BH(Bma,h5);m5+=1}else{var n5=$CLJS.z(j5);if(n5){var o5=n5;if($CLJS.zd(o5)){var p5=$CLJS.jc(o5),Cma=$CLJS.lc(o5),Dma=p5,Ema=$CLJS.E(p5);j5=Cma;k5=Dma;l5=Ema}else{var Fma=$CLJS.B(o5);$CLJS.BH(Fma,h5);j5=$CLJS.C(o5);k5=null;l5=0}m5=0}else break}
$CLJS.j2.m(null,h5,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.n(c))switch(a=$CLJS.M1.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.uG("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.uG("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.UG("Count");case "cum-count":return $CLJS.UG("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.k2.m(null,h5,function(a,b,c){a=$CLJS.H(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.p2.m(null,h5,function(a,b,c){var d=$CLJS.S.j,e=$CLJS.Uh($CLJS.p2,i5);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.S,a,$CLJS.Nj,$CLJS.gF)});$CLJS.j2.m(null,$CLJS.aI,function(){return $CLJS.UG("Case")});
$CLJS.k2.m(null,$CLJS.aI,function(){return"case"});$CLJS.BH(g5,i5);for(var q5=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.mI,$CLJS.PI,$CLJS.XH,$CLJS.ak,$CLJS.GI,$CLJS.Mn,$CLJS.II,$CLJS.FI,$CLJS.YH],null)),r5=null,s5=0,t5=0;;)if(t5<s5){var Gma=r5.X(null,t5);$CLJS.BH(Gma,g5);t5+=1}else{var u5=$CLJS.z(q5);if(u5){var v5=u5;if($CLJS.zd(v5)){var w5=$CLJS.jc(v5),Hma=$CLJS.lc(v5),Ima=w5,Jma=$CLJS.E(w5);q5=Hma;r5=Ima;s5=Jma}else{var Kma=$CLJS.B(v5);$CLJS.BH(Kma,g5);q5=$CLJS.C(v5);r5=null;s5=0}t5=0}else break}
$CLJS.k2.m(null,g5,function(a,b,c){a=$CLJS.H(c,0,null);$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.j2.m(null,g5,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.M1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.uG("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.uG("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.uG("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.uG("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.uG("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.uG("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.uG("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.uG("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.uG("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.j2.m(null,$CLJS.dI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);return $CLJS.uG("{0}th percentile of {1}",$CLJS.G([c,$CLJS.M1.v(a,b,e,d)]))});$CLJS.k2.m(null,$CLJS.dI,function(){return"percentile"});
$CLJS.BH($CLJS.dI,i5);$CLJS.j2.m(null,$CLJS.NI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.uG("Sum of {0} matching condition",$CLJS.G([$CLJS.M1.v(a,b,e,d)]))});$CLJS.k2.m(null,$CLJS.NI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.l2.j(a,b,d))].join("")});$CLJS.BH($CLJS.NI,i5);$CLJS.j2.m(null,$CLJS.zI,function(){return $CLJS.UG("Share of rows matching condition")});
$CLJS.k2.m(null,$CLJS.zI,function(){return"share"});$CLJS.BH($CLJS.zI,i5);$CLJS.j2.m(null,$CLJS.BI,function(){return $CLJS.UG("Count of rows matching condition")});$CLJS.k2.m(null,$CLJS.BI,function(){return"count-where"});$CLJS.BH($CLJS.BI,i5);
$CLJS.p2.m(null,i5,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.Wk.l;d=$CLJS.n(d)?$CLJS.Tl($CLJS.L1.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bN],null)):null;var f=$CLJS.Uh($CLJS.p2,$CLJS.Wh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Wk,$CLJS.G([d,a]))});
$CLJS.Lma=function(){function a(d){return $CLJS.w3($CLJS.iA,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.w3($CLJS.iA,$CLJS.tf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.kY.m(null,$CLJS.hI,function(a){return a});
$CLJS.x5=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.rE(f),$CLJS.UM))f=$CLJS.mY(f);else return $CLJS.g3(d,e,$CLJS.hI,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.y5=function(){function a(d,e){return $CLJS.De($CLJS.hI.h($CLJS.BY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.z5=function(){function a(d,e){var f=$CLJS.De($CLJS.hI.h($CLJS.BY(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.tf,$CLJS.Ze.h(function(k){var l=$CLJS.L1.j(d,e,k),m=$CLJS.S.l,t=$CLJS.GD.h(l);return m.call($CLJS.S,$CLJS.v3(l,$CLJS.yi,$CLJS.n(t)?t:$CLJS.sj),$CLJS.QM,$CLJS.iN,$CLJS.G([$CLJS.h2,$CLJS.JG.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.j2.m(null,$CLJS.aJ,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.XI);return $CLJS.zG.h(a.o?a.o():a.call(null))});$CLJS.q2.m(null,$CLJS.aJ,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.lI);b=$CLJS.J.g(d,$CLJS.XI);c=$CLJS.J.g(d,$CLJS.WI);d=$CLJS.J.g(d,$CLJS.E0);a=$CLJS.S.l(b.o?b.o():b.call(null),$CLJS.g2,$CLJS.mD(a),$CLJS.G([$CLJS.e2,c]));return null!=d?$CLJS.S.j(a,$CLJS.U1,d):a});
$CLJS.Mma=function(){function a(d,e){var f=function(){var m=$CLJS.wC.h($CLJS.B2($CLJS.H1(d)));return $CLJS.n(m)?m:$CLJS.Ug}(),k=$CLJS.BY(d,e),l=$CLJS.u2.j(d,e,k);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Ek.j($CLJS.af(function(m){m=$CLJS.ZI.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Ll(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.WI),u=$CLJS.J.g(m,$CLJS.UI);if($CLJS.Sa(t))return m;if($CLJS.F.g(u,$CLJS.ei))return $CLJS.S.j(m,$CLJS.yY,l);t=$CLJS.De($CLJS.u3(function(v){return $CLJS.o1(u,
v)},l));return $CLJS.n(t)?$CLJS.S.j(m,$CLJS.yY,t):null}),$CLJS.Ze.h(function(m){return $CLJS.S.j(m,$CLJS.wj,$CLJS.aJ)})),$CLJS.bJ))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Nma=function(){function a(d,e){return $CLJS.lY(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lI.h(d),$CLJS.N,$CLJS.nY.h(e)],null))}function b(d){if($CLJS.Sa($CLJS.WI.h(d)))return $CLJS.lY(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lI.h(d),$CLJS.N],null));var e=$CLJS.lI.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ai(e,new $CLJS.h(null,1,[Ama,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();