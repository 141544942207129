var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var VG,$G,Aca,Bca,Cca,Dca,Eca,Fca;
$CLJS.TG=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.xC([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.UG=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.uG(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)};VG=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.M};
$CLJS.XG=function(a){var b=function(){var c=VG(a);return c?(c=$CLJS.wd($CLJS.hd(a)))?(c=$CLJS.yi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.GD.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.WG.h(a)};
$G=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,2,[$CLJS.tu,"valid MBQL clause",$CLJS.vu,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.vj);return["invalid MBQL clause: ",$CLJS.Eh.l($CLJS.G([c]))].join("")}],null),$CLJS.Ke(VG)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,b],null),function(c){c=$CLJS.XG(c);
return ZG(c,a)}],null)],null)};$CLJS.aH=new $CLJS.M(null,"right-join","right-join",-56349359);Aca=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Bca=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Cca=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.bH=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.cH=new $CLJS.M(null,"expr","expr",745722291);$CLJS.dH=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.eH=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.fH=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.gH=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.hH=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.iH=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.jH=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.kH=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Dca=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.lH=new $CLJS.M(null,"left-join","left-join",-672831855);$CLJS.mH=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.nH=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.oH=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
Eca=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Fca=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.pH=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.qH=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);
$CLJS.YG=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.rH=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.sH=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.tH=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);
$CLJS.uH=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.WG=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.$i,$CLJS.tE],null),$CLJS.$i,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.rE(f);return $CLJS.F.g(k,$CLJS.FD)?$CLJS.Xa(f):k},e,a,b,c,d)}();
$CLJS.Y(Bca,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,$CLJS.iH],null),$CLJS.LG],null));$CLJS.WG.m(null,$CLJS.Wh,function(a){throw $CLJS.ai($CLJS.uG("{0}: Don''t know how to determine the type of {1}",$CLJS.G([Eca,$CLJS.Eh.l($CLJS.G([a]))])),new $CLJS.h(null,1,[$CLJS.cH,a],null));});$CLJS.WG.m(null,$CLJS.nH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.XG(a)});
var ZG=function ZG(a,b){return $CLJS.ud(a)?$CLJS.Je(function(d){return ZG.g?ZG.g(d,b):ZG.call(null,d,b)},a):$CLJS.ud(b)?$CLJS.Je(function(d){return ZG.g?ZG.g(a,d):ZG.call(null,a,d)},b):$CLJS.F.g(a,$CLJS.iH)?!0:$CLJS.FE(a,b)};$CLJS.Y($CLJS.gH,$G($CLJS.Dj,"expression returning a boolean"));$CLJS.Y($CLJS.rH,$G($CLJS.Vj,"expression returning a string"));$CLJS.Y($CLJS.uH,$G($CLJS.oj,"expression returning an integer"));$CLJS.Y(Cca,$G($CLJS.aG,"expression returning a non-integer real number"));
$CLJS.Y($CLJS.tH,$G($CLJS.Hj,"expression returning a number"));$CLJS.Y(Dca,$G($CLJS.fF,"expression returning a date"));$CLJS.Y(Fca,$G($CLJS.dG,"expression returning a time"));$CLJS.Y(Aca,$G($CLJS.EF,"expression returning a date time"));$CLJS.Y($CLJS.eH,$G($CLJS.Oj,"expression returning a date, time, or date time"));$CLJS.vH=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.Hj,null,$CLJS.Vj,null,$CLJS.Oj,null],null),null);$CLJS.Y($CLJS.oH,$G($CLJS.vH,"an expression that can be compared with :\x3e or :\x3c"));
var Gca=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.PE,null,$CLJS.Hj,null,$CLJS.Vj,null,$CLJS.vF,null,$CLJS.Oj,null,$CLJS.Dj,null,$CLJS.ZF,null],null),null);$CLJS.Y($CLJS.qH,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rH],null),$G($CLJS.vF,"expression returning a BSONID")],null));$CLJS.Y($CLJS.mH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$G(Gca,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.Y($CLJS.hH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ft,$G($CLJS.sj,"any type of expression")],null));
$CLJS.Y($CLJS.dH,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rj,new $CLJS.h(null,1,[$CLJS.Mn,1],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hH],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ts,$CLJS.ei,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jH,$CLJS.Rj],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,$CLJS.ei],null)],null)],null)],null));