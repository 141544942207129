var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var EJ,FJ,mda,HJ,nda,oda,pda,IJ,GJ;$CLJS.CJ=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.$d.g($CLJS.J.j(c,e,$CLJS.tf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.DJ=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Rc(d):null},null,b)};
EJ=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.FE(b,$CLJS.fF)?$CLJS.oJ:$CLJS.FE(b,$CLJS.dG)?$CLJS.kJ:$CLJS.FE(b,$CLJS.EF)?$CLJS.sG:null;return $CLJS.n(b)?$CLJS.AH(b,a):!0};
FJ=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.CJ(function(d){return $CLJS.FE($CLJS.XG(d),$CLJS.qF)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Ck.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.XG($CLJS.B(a));return $CLJS.Je(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(EJ(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
mda=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.tu,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Ts,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GJ],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.vj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(FJ(b))].join("")}],null),$CLJS.Ke(FJ)],null)],null)};
HJ=function(a){var b=$CLJS.R,c=mda(a);a=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.tu,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hk,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Mn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)],null)],null);
return new $CLJS.Q(null,3,5,b,[$CLJS.gt,c,a],null)};nda=function(a){return $CLJS.Od($CLJS.DH,$CLJS.Ze.g(function(b){var c=$CLJS.XG(b),d=$CLJS.FE(c,$CLJS.iH);b=d?$CLJS.AH($CLJS.wI,b):d;return $CLJS.n(b)?$CLJS.Hj:c},a))};oda=function(a){a=$CLJS.DJ(function(b){return!$CLJS.FE(b,$CLJS.qF)},$CLJS.Ze.g($CLJS.XG,a));return $CLJS.FE(a,$CLJS.iH)?$CLJS.Oj:a};pda=function(a){return $CLJS.n($CLJS.Je(function(b){return $CLJS.FE($CLJS.XG(b),$CLJS.qF)},a))?oda(a):nda(a)};
IJ=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.JJ=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);GJ=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ws,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eH],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,GJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.tu,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.XG(a);return $CLJS.Ie(function(d){return EJ(d,c)},b)}],null)],null));
$CLJS.Y(IJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rs,new $CLJS.h(null,1,[$CLJS.Mn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)],null));$CLJS.TI.g($CLJS.Js,HJ($CLJS.Js));$CLJS.TI.g($CLJS.ju,HJ($CLJS.ju));$CLJS.KH($CLJS.Ks,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qt,IJ],null)]));$CLJS.KH($CLJS.OI,$CLJS.G([$CLJS.ju,$CLJS.aG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qt,IJ],null)]));
for(var KJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Js,$CLJS.ju,$CLJS.Ks],null)),LJ=null,MJ=0,NJ=0;;)if(NJ<MJ){var qda=LJ.X(null,NJ);$CLJS.BH(qda,$CLJS.JJ);NJ+=1}else{var OJ=$CLJS.z(KJ);if(OJ){var PJ=OJ;if($CLJS.zd(PJ)){var QJ=$CLJS.jc(PJ),rda=$CLJS.lc(PJ),sda=QJ,tda=$CLJS.E(QJ);KJ=rda;LJ=sda;MJ=tda}else{var uda=$CLJS.B(PJ);$CLJS.BH(uda,$CLJS.JJ);KJ=$CLJS.C(PJ);LJ=null;MJ=0}NJ=0}else break}$CLJS.WG.m(null,$CLJS.JJ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return pda(a)});
$CLJS.IH($CLJS.SH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));$CLJS.BH($CLJS.SH,$CLJS.nH);
for(var RJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QI,$CLJS.LH,$CLJS.fI],null)),SJ=null,TJ=0,UJ=0;;)if(UJ<TJ){var vda=SJ.X(null,UJ);$CLJS.IH(vda,$CLJS.G([$CLJS.ju,$CLJS.aG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));UJ+=1}else{var VJ=$CLJS.z(RJ);if(VJ){var WJ=VJ;if($CLJS.zd(WJ)){var XJ=$CLJS.jc(WJ),wda=$CLJS.lc(WJ),xda=XJ,yda=$CLJS.E(XJ);RJ=wda;SJ=xda;TJ=yda}else{var zda=$CLJS.B(WJ);$CLJS.IH(zda,$CLJS.G([$CLJS.ju,$CLJS.aG,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));RJ=$CLJS.C(WJ);SJ=null;TJ=0}UJ=0}else break}
for(var YJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OH,$CLJS.qI,$CLJS.xI],null)),ZJ=null,$J=0,aK=0;;)if(aK<$J){var Ada=ZJ.X(null,aK);$CLJS.IH(Ada,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));aK+=1}else{var bK=$CLJS.z(YJ);if(bK){var cK=bK;if($CLJS.zd(cK)){var dK=$CLJS.jc(cK),Bda=$CLJS.lc(cK),Cda=dK,Dda=$CLJS.E(dK);YJ=Bda;ZJ=Cda;$J=Dda}else{var Eda=$CLJS.B(cK);$CLJS.IH(Eda,$CLJS.G([$CLJS.ju,$CLJS.oj,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));YJ=$CLJS.C(cK);ZJ=null;$J=0}aK=0}else break}$CLJS.IH($CLJS.PH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.el,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.tH],null)],null)]));
$CLJS.WG.m(null,$CLJS.PH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.FE($CLJS.XG(b),$CLJS.oj)&&$CLJS.FE($CLJS.XG(a),$CLJS.oj)?$CLJS.oj:$CLJS.aG});