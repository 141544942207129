var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var j8,k8,l8,bpa,cpa,dpa,q8,r8,t8,u8,epa,w8,x8,fpa,gpa;$CLJS.i8=function(a){return $CLJS.j6.h(a)};j8=function(a){return $CLJS.S.j(a,$CLJS.wj,$CLJS.B5)};k8=function(a,b){return $CLJS.F.g($CLJS.yG.h(a),$CLJS.Tl(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NG,$CLJS.xG,$CLJS.MG],null)))};l8=function(a){var b=new $CLJS.h(null,3,[$CLJS.JG,$CLJS.p.h($CLJS.TG()),$CLJS.GD,$CLJS.GD.h(a),$CLJS.yi,$CLJS.PC($CLJS.yi,$CLJS.GD)(a)],null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DD,b,$CLJS.T.h(a)],null)};
bpa=function(a){return $CLJS.x2(a,new $CLJS.h(null,1,[$CLJS.NG,$CLJS.rh],null))};
cpa=function(a,b){var c=$CLJS.DJ(function(d){return $CLJS.F.g($CLJS.C0.h(d),a)},b);if($CLJS.n(c))return c;c=$CLJS.DJ(function(d){return $CLJS.F.g($CLJS.T.h(d),a)},b);if($CLJS.n(c))return c;c=$CLJS.ZC($CLJS.WA);$CLJS.n($CLJS.YC("metabase.lib.field",c))&&(b=$CLJS.uG("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.Eh.l($CLJS.G([a])),$CLJS.Eh.l($CLJS.G([$CLJS.Gk.g($CLJS.C0,b)]))])),b instanceof Error?$CLJS.XC("metabase.lib.field",c,$CLJS.dy(),b):$CLJS.XC("metabase.lib.field",
c,$CLJS.dy.l($CLJS.G([b])),null));return null};
dpa=function(a,b,c){if($CLJS.n(m8))return null;var d=m8;m8=!0;try{var e=$CLJS.f3(a,b),f=$CLJS.n(e)?$CLJS.BY(a,e):$CLJS.BY(a,b),k=function(){var m=$CLJS.n8.h(f);if($CLJS.n(m))return m;m=$CLJS.QC(f,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jX,$CLJS.yY],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.KX.h(f);if($CLJS.n(u))return u;u=$CLJS.bR.h(f);if($CLJS.n(u))return u;u=$CLJS.WR.h(f);return $CLJS.n(u)?u:$CLJS.bH.h(f)}())?$CLJS.u2.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.ZC($CLJS.WA);if($CLJS.n($CLJS.YC("metabase.lib.field",
m))){var t=$CLJS.uG("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))]));return t instanceof Error?$CLJS.XC("metabase.lib.field",m,$CLJS.dy(),t):$CLJS.XC("metabase.lib.field",m,$CLJS.dy.l($CLJS.G([t])),null)}return null}(),l=function(){var m=$CLJS.z(k);return m?cpa(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.S.j($CLJS.S.j($CLJS.z4($CLJS.wk.l(l,$CLJS.Ri,$CLJS.G([$CLJS.aN,$CLJS.o8,$CLJS.p8])),null),$CLJS.T,function(){var m=$CLJS.C0.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.QM,$CLJS.p0):l:null}finally{m8=d}};
q8=function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.qR);var f=$CLJS.H(c,2,null);c=$CLJS.Wk.l($CLJS.G([function(){var k=$CLJS.GD.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.GD,k],null):null}(),function(){var k=$CLJS.PC($CLJS.yi,$CLJS.GD)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.yi,k],null):null}(),function(){var k=$CLJS.rT.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.o8,k],null):null}(),function(){var k=$CLJS.yI.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.p8,k],null):null}(),$CLJS.Ed(f)?function(){var k=$CLJS.j3(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.wj,$CLJS.OM,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.wj,$CLJS.OM,$CLJS.T,f],null):function(){var k=dpa(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.wj,$CLJS.OM,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.z4(c,d):c};r8=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.p8);return $CLJS.n($CLJS.n(b)?$CLJS.Gd($CLJS.AJ,b):b)?$CLJS.oj:$CLJS.PC($CLJS.yi,$CLJS.GD)(a)};
$CLJS.s8=function(a,b,c,d){$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.JG),l=$CLJS.J.g(f,$CLJS.GD),m=$CLJS.J.g(f,$CLJS.rT),t=$CLJS.J.g(f,$CLJS.yi);e=$CLJS.J.g(f,$CLJS.qR);var u=$CLJS.J.g(f,$CLJS.BP),v=$CLJS.J.g(f,$CLJS.yI),x=$CLJS.Wk.l;k=new $CLJS.h(null,2,[$CLJS.wj,$CLJS.OM,$CLJS.h2,k],null);f=$CLJS.zG.h(f);a=$CLJS.n(f)?f:$CLJS.M1.j(a,b,d);c=x.call($CLJS.Wk,$CLJS.G([k,c,new $CLJS.h(null,1,[$CLJS.zG,a],null)]));c=$CLJS.n(t)?$CLJS.S.j(c,$CLJS.yi,t):c;l=$CLJS.n(l)?$CLJS.S.j(c,
$CLJS.GD,l):c;v=$CLJS.n(v)?$CLJS.S.j(l,$CLJS.p8,v):l;m=$CLJS.n(m)?$CLJS.S.j(v,$CLJS.o8,m):v;u=$CLJS.n(u)?$CLJS.S.j(m,$CLJS.$M,u):m;return $CLJS.n(e)?$CLJS.z4(u,e):u};t8=function(a,b,c){return $CLJS.n($CLJS.Je(function(d){return $CLJS.F.g($CLJS.li.h(d),c)},a))?$CLJS.Gk.g(function(d){var e=$CLJS.wk.g(d,b);return $CLJS.F.g($CLJS.li.h(d),c)?$CLJS.S.j(e,b,!0):e},a):a};
u8=function(a){var b=$CLJS.n($CLJS.S2.h(a))?null:function(){var d=$CLJS.QM.h(a),e=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.G0,null,$CLJS.M0,null,$CLJS.p0,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.Wk.l($CLJS.G([new $CLJS.h(null,3,[$CLJS.JG,$CLJS.p.h($CLJS.TG()),$CLJS.GD,$CLJS.GD.h(a),$CLJS.yi,r8(a)],null),function(){var d=$CLJS.L2(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.qR,d],null):null}(),function(){var d=$CLJS.p8.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.yI,d],
null):null}(),function(){var d=$CLJS.o8.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.rT,d],null):null}(),function(){var d=$CLJS.$M.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.BP,d],null):null}()]));b=($CLJS.n(b)?$CLJS.PC($CLJS.C0,$CLJS.T):$CLJS.PC($CLJS.Ri,$CLJS.T))(a);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,c,b],null)};
epa=function(a,b){return $CLJS.bf(function(c){return $CLJS.F.g($CLJS.QM.h(c),$CLJS.w0)},$CLJS.u2.v(a,b,$CLJS.BY(a,b),new $CLJS.h(null,3,[$CLJS.b2,!1,$CLJS.i2,!0,$CLJS.Z1,!1],null)))};$CLJS.p8=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.v8=new $CLJS.M(null,"stage","stage",1843544772);w8=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.n8=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);
x8=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);fpa=new $CLJS.M(null,"earliest","earliest",-1928154382);$CLJS.o8=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);gpa=new $CLJS.M(null,"latest","latest",24323665);var jpa;$CLJS.y2.m(null,$CLJS.sI,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rh.h(b),$CLJS.x2(c,new $CLJS.h(null,2,[$CLJS.yI,$CLJS.rh,$CLJS.rT,bpa],null)),a],null)});var m8=!1,y8=function y8(a,b){var d=$CLJS.D2(a,w8.h(b));a=$CLJS.n(w8.h(d))?y8.g?y8.g(a,d):y8.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Hk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.n2.m(null,$CLJS.OM,function(a,b,c){return r8(c)});$CLJS.n2.m(null,$CLJS.sI,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.yI);$CLJS.H(c,2,null);c=q8(a,b,c);c=$CLJS.n(d)?$CLJS.S.j(c,$CLJS.p8,d):c;return $CLJS.o2.j(a,b,c)});$CLJS.p2.m(null,$CLJS.OM,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.S.j(a,$CLJS.T,b)});$CLJS.p2.m(null,$CLJS.sI,function(a,b,c){var d=q8(a,b,c);b=$CLJS.s8(a,b,d,c);return $CLJS.n(w8.h(b))?y8(a,b):b});
$CLJS.j2.m(null,$CLJS.OM,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.zG),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.li),l=$CLJS.J.g(c,$CLJS.o8),m=$CLJS.J.g(c,$CLJS.P0),t=$CLJS.J.g(c,$CLJS.$M),u=$CLJS.J.g(c,$CLJS.aN);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.J2.g($CLJS.I2,f):$CLJS.p.h(f);$CLJS.F.g(d,$CLJS.N1)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.D2(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.TC($CLJS.zG.h($CLJS.P1.j(a,b,t)),$CLJS.JY,"")):(u=$CLJS.K2(a,u),a=$CLJS.M1.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.L2(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.pD($CLJS.TC($CLJS.Zg(k),"-"," ")),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.g6(l,c),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):m});
$CLJS.j2.m(null,$CLJS.sI,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.rT);var k=$CLJS.J.g(f,$CLJS.qR),l=$CLJS.J.g(f,$CLJS.yI);f=$CLJS.J.g(f,$CLJS.BP);$CLJS.H(c,2,null);c=q8(a,b,c);c=$CLJS.n(k)?$CLJS.S.j(c,$CLJS.P0,k):c;l=$CLJS.n(l)?$CLJS.S.j(c,$CLJS.li,l):c;e=$CLJS.n(e)?$CLJS.S.j(l,$CLJS.o8,e):l;e=$CLJS.n(f)?$CLJS.S.j(e,$CLJS.$M,f):e;return $CLJS.n(e)?$CLJS.M1.v(a,b,e,d):$CLJS.UG("[Unknown Field]")});
$CLJS.k2.m(null,$CLJS.OM,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.k2.m(null,$CLJS.sI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=q8(a,b,c);return $CLJS.n(c)?$CLJS.l2.j(a,b,c):"unknown_field"});
$CLJS.q2.m(null,$CLJS.OM,function(a,b,c){return $CLJS.Wk.l($CLJS.G([function(){var d=$CLJS.Uh($CLJS.q2,$CLJS.Wh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.g($CLJS.QM.h(c),$CLJS.G0)?function(){var d=$CLJS.O0.h(c);return $CLJS.n(d)?(d=$CLJS.K1(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.HY,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.zG,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.B3.m(null,$CLJS.sI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);$CLJS.H(a,2,null);return $CLJS.yI.h(b)});
$CLJS.B3.m(null,$CLJS.OM,function(a){return $CLJS.p8.h(a)});$CLJS.y3.m(null,$CLJS.sI,function(a,b){$CLJS.H(a,0,null);var c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(b)){var d=$CLJS.Gd($CLJS.AJ,b),e=$CLJS.DE(x8,$CLJS.yi,$CLJS.GD)(c);c=$CLJS.S.l(c,$CLJS.yI,b,$CLJS.G([$CLJS.yi,d?$CLJS.oj:e,x8,e]));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,c,a],null)}b=x8.h(c);c=$CLJS.n(b)?$CLJS.wk.g($CLJS.S.j(c,$CLJS.yi,b),x8):c;c=$CLJS.wk.g(c,$CLJS.yI);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,c,a],null)});
$CLJS.y3.m(null,$CLJS.OM,function(a,b){return $CLJS.n(b)?$CLJS.S.j(a,$CLJS.p8,b):$CLJS.wk.g(a,$CLJS.p8)});$CLJS.D3.m(null,$CLJS.sI,function(a,b,c){return $CLJS.E3.j(a,b,q8(a,b,c))});
$CLJS.D3.m(null,$CLJS.OM,function(a,b,c){if($CLJS.Ck.g($CLJS.QM.h(c),$CLJS.w0)){a=$CLJS.PC($CLJS.yi,$CLJS.GD)(c);b=null==c?null:$CLJS.PP.h(c);if(null==b)var d=null;else try{var e=$CLJS.EF.h($CLJS.ej.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,fpa),l=$CLJS.J.g(f,gpa),m=$CLJS.apa.l($CLJS.G([$CLJS.h8.h(k),$CLJS.h8.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Bs.g?$CLJS.Bs.g(1,m):$CLJS.Bs.call(null,1,m))?$CLJS.lv:$CLJS.n($CLJS.Bs.g?$CLJS.Bs.g(31,m):$CLJS.Bs.call(null,31,m))?$CLJS.nv:$CLJS.n($CLJS.Bs.g?
$CLJS.Bs.g(365,m):$CLJS.Bs.call(null,365,m))?$CLJS.gj:$CLJS.pj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.FE(a,$CLJS.EF)?$CLJS.qla:$CLJS.FE(a,$CLJS.fF)?$CLJS.pla:$CLJS.FE(a,$CLJS.dG)?$CLJS.ola:$CLJS.tf;d=$CLJS.n(d)?t8(e,$CLJS.Wh,d):e;return $CLJS.n($CLJS.p8.h(c))?t8(d,$CLJS.U1,$CLJS.p8.h(c)):d}return $CLJS.tf});
$CLJS.j6.m(null,$CLJS.sI,function(a){var b=null==a?null:$CLJS.QG(a);b=null==b?null:$CLJS.rT.h(b);return null==b?null:$CLJS.S.l(b,$CLJS.wj,$CLJS.i6,$CLJS.G([$CLJS.h6,function(c,d){return q8(c,d,a)}]))});$CLJS.j6.m(null,$CLJS.OM,function(a){var b=null==a?null:$CLJS.o8.h(a);return null==b?null:$CLJS.S.l(b,$CLJS.wj,$CLJS.i6,$CLJS.G([$CLJS.h6,$CLJS.Le(a)]))});$CLJS.d6.m(null,$CLJS.sI,function(a,b){return $CLJS.RG(a,$CLJS.PG,$CLJS.G([$CLJS.rT,b]))});
$CLJS.d6.m(null,$CLJS.OM,function(a,b){return $CLJS.PG(a,$CLJS.o8,b)});$CLJS.k6.m(null,$CLJS.sI,function(a,b,c){return $CLJS.l6.j(a,b,q8(a,b,c))});
$CLJS.k6.m(null,$CLJS.OM,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.yi);var d=$CLJS.J.g(b,$CLJS.PP),e=$CLJS.J.g(b,$CLJS.Nj);if($CLJS.Ck.g($CLJS.QM.h(b),$CLJS.w0)){var f=function(){var m=null==a?null:$CLJS.B2($CLJS.H1(a));m=null==m?null:$CLJS.wC.h(m);return null==m?null:$CLJS.Gd(m,$CLJS.rT)}(),k=$CLJS.QC(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ej,$CLJS.Hj],null)),l=$CLJS.i8(b);return function u(t){return new $CLJS.le(null,function(){for(;;){var v=$CLJS.z(t);if(v){if($CLJS.zd(v)){var x=$CLJS.jc(v),
A=$CLJS.E(x),D=$CLJS.oe(A);return function(){for(var K=0;;)if(K<A){var U=$CLJS.kd(x,K),Z=D,da=U;U=k8(U,l)?$CLJS.S.j(da,$CLJS.U1,!0):da;Z.add(U);K+=1}else return!0}()?$CLJS.re($CLJS.te(D),u($CLJS.lc(v))):$CLJS.re($CLJS.te(D),null)}var I=$CLJS.B(v);return $CLJS.ce(function(){var K=I;return k8(I,l)?$CLJS.S.j(K,$CLJS.U1,!0):K}(),u($CLJS.Kc(v)))}return null}},null,null)}($CLJS.Sa(function(){if($CLJS.n(f)){var t=$CLJS.Mn.h(k);return $CLJS.n(t)?$CLJS.ak.h(k):t}return f}())?null:$CLJS.FE(e,$CLJS.qj)?$CLJS.Gk.g(j8,
new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.f6(),new $CLJS.h(null,2,[$CLJS.zG,$CLJS.UG("Bin every 0.1 degrees"),$CLJS.yG,new $CLJS.h(null,2,[$CLJS.NG,$CLJS.MG,$CLJS.MG,.1],null)],null),new $CLJS.h(null,2,[$CLJS.zG,$CLJS.UG("Bin every 1 degree"),$CLJS.yG,new $CLJS.h(null,2,[$CLJS.NG,$CLJS.MG,$CLJS.MG,1],null)],null),new $CLJS.h(null,2,[$CLJS.zG,$CLJS.UG("Bin every 10 degrees"),$CLJS.yG,new $CLJS.h(null,2,[$CLJS.NG,$CLJS.MG,$CLJS.MG,10],null)],null),new $CLJS.h(null,2,[$CLJS.zG,$CLJS.UG("Bin every 20 degrees"),
$CLJS.yG,new $CLJS.h(null,2,[$CLJS.NG,$CLJS.MG,$CLJS.MG,20],null)],null)],null)):$CLJS.FE(c,$CLJS.Hj)&&!$CLJS.FE(e,$CLJS.gi)?$CLJS.Gk.g(j8,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.f6(),new $CLJS.h(null,2,[$CLJS.zG,$CLJS.UG("10 bins"),$CLJS.yG,new $CLJS.h(null,2,[$CLJS.NG,$CLJS.xG,$CLJS.xG,10],null)],null),new $CLJS.h(null,2,[$CLJS.zG,$CLJS.UG("50 bins"),$CLJS.yG,new $CLJS.h(null,2,[$CLJS.NG,$CLJS.xG,$CLJS.xG,50],null)],null),new $CLJS.h(null,2,[$CLJS.zG,$CLJS.UG("100 bins"),$CLJS.yG,new $CLJS.h(null,2,
[$CLJS.NG,$CLJS.xG,$CLJS.xG,100],null)],null)],null)):null)}return $CLJS.tf});$CLJS.kY.m(null,$CLJS.sI,function(a){return a});
$CLJS.kY.m(null,$CLJS.OM,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.QM);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.JG,$CLJS.p.h($CLJS.TG()),$CLJS.yi,$CLJS.PC($CLJS.yi,$CLJS.GD)(a)],null),a=$CLJS.h2.h(a),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hI,b,a],null);case "source/expressions":return l8(a);case "source/breakouts":return $CLJS.Gd(a,$CLJS.jH)?l8(a):u8(a);default:return u8(a)}});
$CLJS.hpa=function(){function a(e,f,k){k=$CLJS.De($CLJS.Gk.g($CLJS.mY,k));var l=epa(e,f),m=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ll(function(t){return $CLJS.o3.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.tf);m=$CLJS.Fk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.Ze.h($CLJS.mY),m):null;return $CLJS.IY.l(e,f,$CLJS.PG,$CLJS.G([$CLJS.bH,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.z8=function(){function a(d,e){return $CLJS.bH.h($CLJS.BY(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ipa=function(){function a(d,e){var f=$CLJS.u2.v(d,e,$CLJS.BY(d,e),new $CLJS.h(null,3,[$CLJS.b2,!1,$CLJS.i2,!1,$CLJS.Z1,!1],null)),k=$CLJS.z8.g(d,e);return $CLJS.sd(k)?$CLJS.Gk.g(function(l){return $CLJS.S.j(l,$CLJS.E0,!0)},f):$CLJS.w4(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
jpa=function(){function a(d,e,f){var k=$CLJS.BY(d,e),l=$CLJS.F.g($CLJS.rE(f),$CLJS.hI)?$CLJS.s2:$CLJS.u2;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.o3.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.kpa=function(){function a(d,e,f){f=$CLJS.l0.j(d,e,f);return jpa.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();