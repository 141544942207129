var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var p3=function(a,b){a=a instanceof $CLJS.M?a.T:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "equal-to":return $CLJS.UG("Equal to");case "default":return $CLJS.UG("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-equal-to":return $CLJS.UG("Not equal to");case "excludes":return $CLJS.UG("Excludes");case "default":return $CLJS.UG("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.T:null,b){case "after":return $CLJS.UG("After");case "default":return $CLJS.UG("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.T:null,b){case "before":return $CLJS.UG("Before");case "default":return $CLJS.UG("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "is-empty":return $CLJS.UG("Is empty");
case "default":return $CLJS.UG("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-empty":return $CLJS.UG("Not empty");case "default":return $CLJS.UG("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.UG("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},q3=function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return p3(a,b)}},Xka=new $CLJS.M(null,"after","after",594996914),Yka=new $CLJS.M(null,"equal-to","equal-to",608296653),Zka=new $CLJS.M(null,"excludes","excludes",-1791725945),$ka=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),ala=new $CLJS.M(null,"before","before",-1633692388);$CLJS.r3=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.wj,$CLJS.TL,$CLJS.lI,d,$CLJS.UL,e],null)}function b(d){return c.g?c.g(d,$CLJS.Wh):c.call(null,d,$CLJS.Wh)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.s3=new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.r3.h($CLJS.hk),$CLJS.r3.h($CLJS.KI),$CLJS.r3.h($CLJS.As),$CLJS.r3.h($CLJS.Es),$CLJS.r3.h($CLJS.UH),$CLJS.r3.h($CLJS.Cs),$CLJS.r3.h($CLJS.Gs),$CLJS.r3.g($CLJS.TH,$CLJS.VH),$CLJS.r3.g($CLJS.eI,$CLJS.bI)],null);$CLJS.bla=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.r3.h($CLJS.hk),$CLJS.r3.h($CLJS.KI),$CLJS.r3.h($CLJS.VH),$CLJS.r3.h($CLJS.bI),$CLJS.r3.h($CLJS.rI),$CLJS.r3.h($CLJS.kI),$CLJS.r3.h($CLJS.jI),$CLJS.r3.h($CLJS.DI)],null);
$CLJS.cla=new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.r3.g($CLJS.KI,Zka),$CLJS.r3.h($CLJS.hk),$CLJS.r3.g($CLJS.Es,ala),$CLJS.r3.g($CLJS.As,Xka),$CLJS.r3.h($CLJS.UH),$CLJS.r3.g($CLJS.TH,$CLJS.VH),$CLJS.r3.g($CLJS.eI,$CLJS.bI)],null);$CLJS.dla=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.r3.h($CLJS.hk),$CLJS.r3.h($CLJS.KI),$CLJS.r3.h($CLJS.$H),$CLJS.r3.h($CLJS.As),$CLJS.r3.h($CLJS.Es),$CLJS.r3.h($CLJS.UH),$CLJS.r3.h($CLJS.Cs),$CLJS.r3.h($CLJS.Gs)],null);
$CLJS.ela=new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.r3.g($CLJS.hk,Yka),$CLJS.r3.g($CLJS.KI,$ka),$CLJS.r3.h($CLJS.As),$CLJS.r3.h($CLJS.Es),$CLJS.r3.h($CLJS.UH),$CLJS.r3.h($CLJS.Cs),$CLJS.r3.h($CLJS.Gs),$CLJS.r3.g($CLJS.TH,$CLJS.VH),$CLJS.r3.g($CLJS.eI,$CLJS.bI)],null);
$CLJS.fla=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.r3.h($CLJS.hk),$CLJS.r3.h($CLJS.KI),$CLJS.r3.h($CLJS.rI),$CLJS.r3.h($CLJS.kI),$CLJS.r3.h($CLJS.TH),$CLJS.r3.h($CLJS.eI),$CLJS.r3.h($CLJS.VH),$CLJS.r3.h($CLJS.bI),$CLJS.r3.h($CLJS.jI),$CLJS.r3.h($CLJS.DI)],null);$CLJS.gla=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.r3.h($CLJS.hk),$CLJS.r3.h($CLJS.KI),$CLJS.r3.h($CLJS.TH),$CLJS.r3.h($CLJS.eI),$CLJS.r3.h($CLJS.VH),$CLJS.r3.h($CLJS.bI)],null);
$CLJS.hla=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.r3.h($CLJS.hk),$CLJS.r3.g($CLJS.TH,$CLJS.VH),$CLJS.r3.g($CLJS.eI,$CLJS.bI)],null);$CLJS.ila=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.r3.h($CLJS.hk),$CLJS.r3.h($CLJS.KI),$CLJS.r3.h($CLJS.TH),$CLJS.r3.h($CLJS.eI)],null);$CLJS.t3=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.S.j($CLJS.r3.h($CLJS.hk),$CLJS.Wh,!0),$CLJS.r3.h($CLJS.As),$CLJS.r3.h($CLJS.Es),$CLJS.r3.h($CLJS.Cs),$CLJS.r3.h($CLJS.Gs),$CLJS.r3.h($CLJS.KI)],null);
$CLJS.j2.m(null,$CLJS.TL,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.lI);b=$CLJS.J.g(b,$CLJS.UL);d=d instanceof $CLJS.M?d.T:null;switch(d){case "default":return q3(a,b);case "long":return p3(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.q2.m(null,$CLJS.TL,function(a,b,c){c=$CLJS.O(c);a=$CLJS.J.g(c,$CLJS.lI);b=$CLJS.J.g(c,$CLJS.UL);c=$CLJS.J.g(c,$CLJS.Wh);a=new $CLJS.h(null,3,[$CLJS.g2,$CLJS.mD(a),$CLJS.zG,q3(a,b),$CLJS.O1,p3(a,b)],null);return $CLJS.n(c)?$CLJS.S.j(a,$CLJS.Wh,!0):a});